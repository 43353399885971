import React from 'react';

import Tippy from '@tippyjs/react';
import { Loader, Message as MessageIcon, Send, X } from 'tabler-icons-react';

import { ButtonWithIcon, Group, Input, Text } from 'components';

import { AppStore, ChatThread, Message } from 'domain/models';
import { useMessageThread } from 'domain/swr';
import { useInputState } from 'domain/hooks';
import { useSelector } from 'react-redux';
import { format, getDate } from 'date-fns';

import './Chat.scss';

type MessageRendererType =
  | {
      type: 'message';
      message: Message;
    }
  | {
      type: 'date';
      date: string;
    }
  | {
      type: 'multiMessage';
      messages: Message[];
    };

interface ChatContainerProps {
  executionId: string;
  threadKey: string;
}
const ChatContainer = (props: ChatContainerProps) => {
  const [messages, pushMessage] = useMessageThread(
    props.executionId,
    props.threadKey
  );

  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onPushMessage = async (message: string) => {
    setLoading(true);
    try {
      await pushMessage(message);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tippy
      className='chat-container'
      interactive
      visible={show}
      animation='scale'
      placement='top-end'
      content={
        <Chat
          show={show}
          loading={loading}
          messages={messages}
          onPushMessage={onPushMessage}
        />
      }
    >
      <ChatButton show={show} onClick={() => setShow(!show)} />
    </Tippy>
  );
};

interface ChatButtonProps {
  show: boolean;
  loading?: boolean;
  onClick: VoidFunction;
}
const ChatButton = React.forwardRef((props: ChatButtonProps, ref: any) => {
  return (
    <ButtonWithIcon
      ref={ref}
      icon={
        props.loading ? (
          <Loader size={48} className='loader-icon' />
        ) : props.show ? (
          <X size={48} />
        ) : (
          <MessageIcon size={48} />
        )
      }
      className={`chat-button ${props.show ? 'show' : ''}`}
      onClick={props.onClick}
    />
  );
});

interface ChatProps {
  messages?: ChatThread;
  loading: boolean;
  show: boolean;
  onPushMessage(message: string): void;
}
const Chat = (props: ChatProps) => {
  const [input, setInput] = useInputState('');

  const messagesContainer = React.useRef<HTMLDivElement>(null);

  const grouped = useGroupMessages(props.messages?.comments ?? []);

  React.useEffect(() => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTo({
        top: messagesContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [props.messages, props.show]);

  const Renderer = ({ value }: { value: MessageRendererType }) => {
    switch (value.type) {
      case 'date': {
        let v = value as { type: 'date'; date: string };
        return (
          <Group className='chat-date-indicator'>
            <Text className='date'>
              {format(new Date(v.date), 'yyyy-MM-dd')}
            </Text>
          </Group>
        );
      }
      case 'message': {
        let v = value as { type: 'message'; message: Message };
        return <MessageRenderer message={v.message} />;
      }
      default:
        return null;
    }
  };
  const messageCount = props.messages?.comments.length ?? 0;

  return (
    <Group className='chat' orientation='column' spacing='0px'>
      <Group className='chat-title' placement='space-between'>
        <Text className='chat-title-text'>QAR Chat</Text>
        <Text className='chat-title-message'>{messageCount} messages</Text>
      </Group>
      <Group
        className={`messages-container ${
          messageCount === 0 ? 'no-messages' : ''
        }`}
        orientation='column'
        placement='start'
        spacing='0.5em'
        ref={messagesContainer}
      >
        {messageCount === 0 && (
          <Text className='no-messages-text'>
            There are no messages in this thread yet. <br /> Be the first to
            post!
          </Text>
        )}
        {grouped.map((m, i) => (
          <Renderer value={m} key={i} />
        ))}
      </Group>
      <ChatInput
        loading={props.loading}
        onChange={setInput}
        text={input}
        onSend={() => {
          props.onPushMessage(input);
          setInput('');
        }}
      />
    </Group>
  );
};

interface ChatInputProps {
  text: string;
  loading: boolean;
  onSend: VoidFunction;
  onChange(message: string): void;
}
const ChatInput = (props: ChatInputProps) => {
  return (
    <form
      className='chat-input-form'
      onSubmit={(evt) => {
        props.onSend();
        evt.preventDefault();
        evt.stopPropagation();
      }}
    >
      <Input
        required
        value={props.text}
        onChange={({ target }) => props.onChange(target.value)}
      />
      <ButtonWithIcon
        icon={<Send />}
        disabled={!props.text || props.loading}
        className='chat-input-form-button'
        type='submit'
      />
    </form>
  );
};

interface MessageRendererProps {
  message: Message;
}
export const MessageRenderer = (props: MessageRendererProps) => {
  const user = useSelector((store: AppStore) => store.auth.user);

  const itsYours = props.message.author.username === user?.user.username;
  const timeFormatted = format(new Date(props.message.publishDate), 'HH:mm');

  return (
    <Group
      orientation='column'
      className={`chat-message ${itsYours ? 'owned' : ''}`}
      spacing='4px'
    >
      <Group className='chat-message-header' placement='space-between'>
        <Text className='chat-message-user'>
          {props.message.author.username}
        </Text>
        <Text className='chat-message-role'>
          {
            props.message.workflowRole ?? props.message.author.role
          }
        </Text>
      </Group>
      <Text className='chat-message-content'>{props.message.content}</Text>
      <Text className='chat-message-date'>{timeFormatted}</Text>
    </Group>
  );
};

export const MessageThread = (props: ChatContainerProps) => {
  return (
    <React.Suspense
      fallback={<ChatButton onClick={() => {}} show={false} loading={true} />}
    >
      <ChatContainer
        executionId={props.executionId}
        threadKey={props.threadKey}
      />
      ;
    </React.Suspense>
  );
};

const useGroupMessages = (messages: Message[]): MessageRendererType[] => {
  return React.useMemo(() => {
    let result: MessageRendererType[] = [];

    const sorted = [...messages].sort(
      (m1, m2) =>
        new Date(m1.publishDate).valueOf() - new Date(m2.publishDate).valueOf()
    );

    let sameDay = false;
    for (let i = 0; i < sorted.length; i++) {
      const message = sorted[i];
      const prevMessage = sorted[i - 1];

      if (!prevMessage) {
        sameDay = true;
        result.push({
          type: 'date',
          date: message.publishDate,
        });
        result.push({ type: 'message', message });
        continue;
      }

      const prevDay = getDate(new Date(prevMessage.publishDate));
      const currDay = getDate(new Date(message.publishDate));

      if (prevDay === currDay) {
        if (!sameDay) {
          result.push({ type: 'date', date: message.publishDate });
          sameDay = true;
        }
      } else {
        sameDay = true;
        result.push({ type: 'date', date: message.publishDate });
      }
      result.push({ type: 'message', message });
    }

    return result;
  }, [messages]);
};
