import React from 'react';

import {
  Brackets,
  LetterCase,
  Markdown,
  Numbers,
  QuestionMark,
  ToggleLeft,
} from 'tabler-icons-react';
import { useDispatch } from 'react-redux';

import { Group, Row, Spoiler, Text, Tooltip } from 'components';

import {
  AssessmentImageModal,
  ImageSummary,
  ReviewComment,
  CatalogProvidedInfo,
  CommentThread,
  AssessmentImageFilter,
} from '../rjsf';
import { MarkdownRenderer } from '../MarkdownRenderer';

import {
  AssessmentImage,
  ImageFormDataI,
  QAUseCase,
  QAUseCaseAttachment,
  QualityCheck,
} from 'domain/models/rjsf-fields.model';
import {
  DataCollectionEntry,
  getImagesWithUrl,
  getLabelsForArrayField,
  RenderType,
  useConcreteRenderType,
} from './utils';
import { popModal, pushModal } from 'domain/store/root/root.actions';

import './FormDataFields.scss';
import { useQarContext } from 'pages/app/qar/qar-context';
import { downloadUseCaseAttachment, useUseCaseAttachments } from 'domain/swr';

export const FormDataGroup = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => {
  return (
    <Group orientation='column' placement='start' className='form-data-group'>
      <Group>
        <Row className='form-data-decoration-line' />
        <Text className='form-data-group-title'>{title}</Text>
        <Row className='form-data-decoration-line' />
      </Group>
      {children}
    </Group>
  );
};

interface FormDataFieldProps {
  title: string;
  description?: string;
  type: RenderType;
  className?: string;
}
export const FormDataField = ({
  title,
  description,
  type,
  className,
  children,
}: React.PropsWithChildren<FormDataFieldProps>) => {
  return (
    <Group
      orientation='column'
      placement='start'
      className={`form-data-field ${className}`}
      spacing='0.25em'
    >
      <Group placement='space-between'>
        <Text className='form-data-field-title'>
          {title}
          {description && (
            <Tooltip content={description}>
              <sup>
                <QuestionMark size={14} />
              </sup>
            </Tooltip>
          )}
        </Text>
        {type && <FormDataTypeIcon type={type} />}
      </Group>

      {children}
    </Group>
  );
};

interface FormDataStringTypeProps {
  value: string;
}
export const FormDataStringType = (props: FormDataStringTypeProps) => {

  // Check if the type is object or array, in order to stringify it
  let value = props.value as any;
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  return (
    <Text className='form-data-string-type-value'>{value ?? '-'}</Text>
  );
};

interface FormDataBoolTypeProps {
  value: boolean;
}
export const FormDataBoolType = (props: FormDataBoolTypeProps) => {
  return (
    <Text className='form-data-bool-type-value'>
      {props.value === true ? 'Yes' : 'No'}
    </Text>
  );
};

interface FormDataArrayTypeProps {
  values: string[];
}
export const FormDataArrayType = (props: FormDataArrayTypeProps) => {
  return (
    <Group placement='start' className='form-data-array-wrapper'>
      {props.values.map((value, i) => (
        <Row className='form-data-array-type-value' key={i}>
          {value}
        </Row>
      ))}
    </Group>
  );
};

interface FormDataMDTypeProps {
  value: string;
}
export const FormDataMDType = (props: FormDataMDTypeProps) => {
  return (
    <Group placement='start' className='form-data-md-wrapper'>
      {props.value && (
        <MarkdownRenderer
          children={props.value}
          className='form-data-md-renderer'
        />
      )}
    </Group>
  );
};

interface FormDataImageTypeProps {
  images: ImageFormDataI[];
}
export const FormDataImageType = (props: FormDataImageTypeProps) => {
  return (
    <Group placement='start' className='form-data-images-wrapper'>
      {props.images.map((img: ImageFormDataI) => (
        <Group
          orientation='column'
          key={img.id}
          className='qar-view-image-wrapper'
          spacing='4px'
        >
          <img
            src={img.url}
            alt={`${img.id} image`}
            className='qar-view-image'
          />
          <Text className='qar-view-image-text'>{img.title}</Text>
        </Group>
      ))}
    </Group>
  );
};

interface FormDataAssessmentImageTypeProps {
  images: AssessmentImage[];
}
export const FormDataAssessmentImageType = (
  props: FormDataAssessmentImageTypeProps
) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = React.useState<{ prop?: string; value?: string }>(
    {}
  );

  const onImageClick = (image: AssessmentImage) => {
    const close = () => dispatch(popModal());
    dispatch(
      pushModal(<AssessmentImageModal editable image={image} onClose={close} />)
    );
  };

  const filteredImages = React.useMemo(() => {
    if (filter.prop && filter.value) {
      return (props.images ?? []).filter((img: AssessmentImage) => {
        return img.metadata[filter.prop!] === filter.value;
      });
    }
    return props.images ?? [];
  }, [filter, props.images]);

  return (
    <Group orientation='column' className='assessment-images-wrapper'>
      <AssessmentImageFilter
        images={props.images ?? []}
        filterProp={filter.prop}
        filterValue={filter.value}
        onChange={setFilter}
      />
      <Row className='form-data-assessment-image-wrapper'>
        {filteredImages.map((img) => (
          <ImageSummary
            image={img}
            key={img.key}
            onClick={() => onImageClick(img)}
          />
        ))}
      </Row>
    </Group>
  );
};

interface FormDataUseCaseTypeProps {
  useCases: QAUseCase[];
}
const FormDataUseCaseType = ({ useCases }: FormDataUseCaseTypeProps) => {
  return (
    <Group placement='start' className='form-data-use-cases'>
      {useCases.map((uc) => (
        <UseCase useCase={uc} key={uc.uc_key} />
      ))}
    </Group>
  );
};

// USE_CASE_IMAGE
const UseCase = ({ useCase }: { useCase: QAUseCase }) => {
  const { qar } = useQarContext();

  const [attachments] = useUseCaseAttachments(
    useCase.uc_attachments as string[],
    qar
  );

  const donwloadAttachment = (attachments: QAUseCaseAttachment) => {
    downloadUseCaseAttachment(attachments);
  };

  return (
    <Group
      className='use-case'
      orientation='column'
      placement='start'
      spacing='0.5em'
      key={useCase.uc_key}
    >
      <Text className='use-case-title'>{useCase.uc_title}</Text>
      <Spoiler maxHeight={115} className='use-case-comment'>
        {useCase.uc_comment}
      </Spoiler>
      <Group
        orientation='column'
        placement='start'
        className='use-case-attachments'
        spacing='0.5em'
      >
        <Text className='use-case-attachment-title'>Attachments</Text>
        <Group orientation='column' placement='start' spacing='0.25em'>
          {attachments.map((att) => {
            return (
              <Text
                className='use-case-attachment'
                key={att.attachmentId}
                onClick={() => donwloadAttachment(att)}
              >
                {att.name}
              </Text>
            );
          })}
        </Group>
      </Group>
    </Group>
  );
};

export const FormDataTypeIcon = ({ type }: { type: RenderType }) => {
  const props = {
    className: 'form-data-type-icon',
    strokeWidth: 1,
    size: 18,
  };
  switch (type) {
    case RenderType.Array:
      return <Brackets {...props} />;
    case RenderType.String:
      return <LetterCase {...props} />;
    case RenderType.Boolean:
      return <ToggleLeft {...props} />;
    case RenderType.Number:
      return <Numbers {...props} />;
    case RenderType.MD:
      return <Markdown {...props} />;
    default:
      return null;
  }
};

export const FormDataFieldValueRenderer = (props: DataCollectionEntry) => {
  const type = useConcreteRenderType(props.schema, props.uiSchema);

  switch (type) {
    case RenderType.String:
    case RenderType.Number:
      return <FormDataStringType value={props.formData} />;
    case RenderType.Boolean:
      return <FormDataBoolType value={props.formData} />;
    case RenderType.Array: {
      const labels = getLabelsForArrayField(props.formData, props.schema);

      return <FormDataArrayType values={labels} />;
    }
    case RenderType.MD:
      return <FormDataMDType value={props.formData} />;
    case RenderType.Image: {
      const images = getImagesWithUrl(props.formData, props.schema);

      return <FormDataImageType images={images} />;
    }
    case RenderType.AssessmentImage: {
      return <FormDataAssessmentImageType images={props.formData} />;
    }
    case RenderType.UseCase: {
      return <FormDataUseCaseType useCases={props.formData} />;
    }
    default:
      return null;
  }
};

interface CatalogProvidedInfoProxyProps {
  catalogInfo: string;
  dataProviderInput?: any;
  qualityCheck?: QualityCheck;
}
export const CatalogProvidedInfoProxy = (
  props: CatalogProvidedInfoProxyProps
) => {
  return (
    <CatalogProvidedInfo
      editable={false}
      input={props.catalogInfo}
      qualityCheck={props.qualityCheck}
      onQualityCheckChange={() => {}}
    />
  );
};

export const FormDataReviewField = (props: DataCollectionEntry) => {
  const type = useConcreteRenderType(
    props.schema.data_provider_input,
    props.uiSchema
  );

  const ValueComponent = React.useCallback(() => {
    const dataSchema = props.schema.data_provider_input;

    return (
      <FormDataFieldValueRenderer
        {...props}
        schema={dataSchema}
        formData={props.formData.data_provider_input}
      />
    );
  }, [props]);

  const [title, description] = React.useMemo(() => {
    const dataSchema = props.schema.data_provider_input;
    return [dataSchema.title, dataSchema.description];
  }, [props.schema]);

  return (
    <FormDataField title={title} description={description} type={type}>
      <CatalogProvidedInfoProxy
        catalogInfo={props.formData?.catalogue_provided_input}
        dataProviderInput={props.formData?.data_provider_input}
        qualityCheck={props.formData?.quality_check}
      />
      <ValueComponent />
      <ReviewComment score={props.formData.color_score} />
    </FormDataField>
  );
};

export const FormDataCommentThreadField = (props: DataCollectionEntry) => {
  return (
    <CommentThread
      oldKey='-'
      messageKey={props.formData ?? ''}
      executionId=''
      onChange={() => {}}
      editable={false}
    />
  );
};
