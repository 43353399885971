import { useMarkdownDataPreview } from 'domain/swr';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';
import { MarkdownRenderer } from '../MarkdownRenderer';
import { useMemo } from 'react';
import { Column } from 'components';

import './MarkdownDataPreview.scss';

export const MarkdownDataPreview = (props: any) => {
  const context = useTaskSchemaContext();

  const id = context.task?.id ?? null;

  const markdown = useMarkdownDataPreview(id);

  const tier: string | null = markdown.tier;
  const rawMarkdown: string | null = JSON.parse(markdown.content ?? '{}');

  if (tier === 'TIER_1') {
    return <Tier1MarkdownDataPreview markdown={rawMarkdown} />;
  }

  if (tier === 'TIER_2') {
    return <Tier2MarkdownDataPreview markdown={rawMarkdown} />;
  }

  if (tier === 'TIER_3') {
    return <Tier3MarkdownDataPreview markdown={rawMarkdown} />;
  }

  return <div>No data for previewing found.</div>;
};

type MarkdownDataProps = {
  markdown: any;
};

const extractMarkdownContent = (blocks: any[]) => {
  const result: any = [];
  const allowed_types = ['thumb-markdown', 'markdown', 'checkitem'];

  const traverse = (block: any) => {
    if (block.content && allowed_types.includes(block.type)) {
      // If has title, add it to the result
      const title = block.title;
      const content = block.content;
      let contentWithTitle = content; // Default value
      if (title) {
        contentWithTitle = `## ${title}\n${content}`;
      }
      result.push(contentWithTitle);
    } else if (block.blocks) {
      if (block.blocks.length === 0) {
        return;
      }
      block.blocks.forEach(traverse);
    }
  };

  blocks.forEach(traverse);
  return result;
};

export const Tier1MarkdownDataPreview = ({ markdown }: MarkdownDataProps) => {
  const generated = useMemo(() => {
    try {
      const blocks = markdown?.quality_assurance_tab?.blocks.filter(
        (block: any) => block.id === 'fitness-for-purpose'
      );
      return extractMarkdownContent(blocks).map((content: string) => {
        return <MarkdownRenderer>{content}</MarkdownRenderer>;
      });
    } catch (e) {
      return (
        <div>
          Error generating content. Please, click into the "Preview this QAR"
          button
        </div>
      );
    }
  }, [markdown]);
  return (
    <div className='flex justify-start items-start text-[1rem] flex-col gap-1 my-8'>
      {generated}
    </div>
  );
};

export const Tier2MarkdownDataPreview = ({ markdown }: MarkdownDataProps) => {
  const generated = useMemo(() => {
    try {
      const blocks = markdown?.quality_assurance_aside?.blocks;
      //.filter(
      //);
      return extractMarkdownContent(blocks).map((content: string) => {
        return <MarkdownRenderer>{content}</MarkdownRenderer>;
      });
    } catch (e) {
      return (
        <div>
          Error generating content. Please, click into the "Preview this QAR"
          button
        </div>
      );
    }
  }, [markdown]);
  return (
    <div className='w-[100%] min-w-[100%]'>
      <div className='flex justify-start items-start w-full text-[1rem] flex-col gap-1 my-8 tier-2-preview-renderer'>
        {generated}
      </div>
    </div>
  );
};

export const Tier3MarkdownDataPreview = ({ markdown }: MarkdownDataProps) => {

  const generated = useMemo(() => {
    try {
      const blocks = markdown?.quality_assurance_tab?.blocks.filter(
        (block: any) => block.id === 'quality_assessment_section'
      );
      return extractMarkdownContent(blocks).map((content: string) => {
        return <MarkdownRenderer>{content}</MarkdownRenderer>;
      });
    } catch (e) {
      return (
        <div>
          Error generating content. Please, click into the "Preview this QAR"
          button
        </div>
      );
    }
  }, [markdown]);
  return (
    <div className='flex justify-start items-start text-[1rem] flex-col gap-1 my-8 tier-3-preview-renderer'>
      {generated}
    </div>
  );
};
