import React from 'react';

import { Text } from 'components';

import { Misc } from 'domain/core/constants';

import './ReviewComment.scss';

interface ReviewCommentProps {
  score?: number;
}
export const ReviewComment = (props: ReviewCommentProps) => {
  const scoreEntry = Misc.ReviewColors[props.score! - 1 ?? -1];

  return scoreEntry !== undefined ? (
    <fieldset className='rjsf-fix-review-comment'>
      <legend className='review-comment-label'>Reviewer score</legend>
      <Text
        className='review-score'
        style={{ backgroundColor: scoreEntry.color }}
      >
        {scoreEntry.text}
      </Text>
    </fieldset>
  ) : null;
};
