export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER',
  DataProvider = 'DATA_PROVIDER',
  EqcEvaluator = 'EQC_EVALUATOR',
  EqcManager = 'EQC_MANAGER',
  QarManager = 'QAR_MANAGER',
  QarReviewer = 'QAR_REVIEWER',
  EXT_DATA_PROVIDER = "EXT_DATA_PROVIDER",
}

export enum Entity {
  All = 'ALL',
  QAT = 'QAT',
  QAR = 'QAR',
  Execution = 'EXECUTION',
  Task = 'TASK',
  Workflow = 'WORKFLOW',
}

export interface RoleAccess {
  entity: Entity;
  name: Entity;
  read: boolean;
  update: boolean;
  create: boolean;
  delete: boolean;
}

export type RoleMap = {
  [key in Entity]: RoleAccess;
};

export type EntityAction = keyof Pick<
  RoleAccess,
  'read' | 'update' | 'create' | 'delete'
>;
