import React from "react";

import ReactSelect, { Props, components } from "react-select";

import { ButtonLike } from "./ButtonLike";
import cn from "classnames";
import "./Select.scss";

export const ButtonsSelect = React.forwardRef<any, Props>(
  ({ className = "", ...props }: Props, ref: any) => {
    // Same as default option but with support for title. Label by default
    const ButtonOption = (props: any) => {

      const picked = props.isSelected ? "active" : "";
      return (
        <ButtonLike
          className={cn(`qa-button-${props.data.value}`, picked)}
          onClick={props.onClick}
          title={props.data.title}
        >
          {props.data.label}
        </ButtonLike>
      );
    };

    return (
      <div className={`select-wrapper ${className} flex flex-row`}>
        {props.options?.map((option: any) => {
          return (
            <ButtonOption
              onClick={() => props.onChange?.(option, null as any)}
              key={option.value}
              data={option}
              isSelected={props.value === option}
            />
          );
        })}
      </div>
    );
  }
);
