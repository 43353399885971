import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { useDispatch } from 'react-redux';

import { Button, Group, Modal, Row, Select, Text } from 'components';

import { CommentThread } from './CommentThreadControl';

import { AssessmentImage } from 'domain/models';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';
import { popModal, pushModal } from 'domain/store/root/root.actions';

import './AssessmentImageField.scss';
import { Api } from 'domain/core';
import { fetcher, rawFetcher } from 'domain/swr/fetcher';

export const AssessmentImageField: Field = (props: FieldProps) => {
  const dispatch = useDispatch();

  const { task } = useTaskSchemaContext();

  const [filter, setFilter] = React.useState<{ prop?: string; value?: string }>(
    {}
  );

  const onImageClick = (image: AssessmentImage) => {
    const close = () => dispatch(popModal());
    const imageKey = `${props.idSchema.$id}-${image.key}`;
    dispatch(
      pushModal(
        <AssessmentImageModal
          editable
          image={image}
          executionId={task?.process ?? ''}
          messageKey={imageKey}
          onClose={close}
        />
      )
    );
  };

  const filteredImages = React.useMemo(() => {
    if (filter.prop && filter.value) {
      return (props.formData ?? []).filter((img: AssessmentImage) => {
        return img.metadata[filter.prop!] === filter.value;
      });
    }
    return props.formData ?? [];
  }, [filter, props.formData]);

  return (
    <Group orientation='column' className='assessment-images-wrapper'>
      <AssessmentImageFilter
        images={props.formData ?? []}
        filterProp={filter.prop}
        filterValue={filter.value}
        onChange={setFilter}
      />
      <Row className='assessment-images'>
        {filteredImages.map((image: AssessmentImage) => (
          <ImageSummary
            image={image}
            key={image.key}
            onClick={() => onImageClick(image)}
          />
        ))}
      </Row>
    </Group>
  );
};

export const ImageSummary = ({
  image,
  onClick,
}: {
  image: AssessmentImage;
  onClick: VoidFunction;
}) => {
  const src = `${Api.baseUrl}${Api.uploads.images}/${image.imageId}`;

  return (
    <Group
      orientation='column'
      placement='start'
      className='assessment-image-summary'
      spacing='0.5em'
      title='Click to review image'
      onClick={onClick}
    >
      <SecureImage
        loading='lazy'
        src={src}
        className='assessment-image-thumb'
      />
      <ImageMetadata metadata={image.metadata} maxEntries={3} />
      <ImageCaption caption={image.caption} maxLength={250} />
    </Group>
  );
};

export const ImageMetadata = ({
  metadata,
  maxEntries,
}: {
  metadata: Record<string, any>;
  maxEntries?: number;
}) => {
  const entries = maxEntries
    ? Object.entries(metadata).slice(0, maxEntries)
    : Object.entries(metadata);
  const showMore = maxEntries
    ? Object.keys(metadata).length > maxEntries
    : false;

  return (
    <Group
      orientation='column'
      placement='start'
      spacing='0.5em'
      className='assessment-image-metadata'
    >
      {entries.map(([key, value]) => (
        <Text className='metadata-row' key={key}>
          <Text className='metadata-label'>{key}:</Text>
          <Text className='metadata-value'>{value}</Text>
        </Text>
      ))}
      {showMore && (
        <Text className='metadata-show-more'>
          There are {Object.keys(metadata).length - (maxEntries ?? 0)} more
          metadata entries
        </Text>
      )}
    </Group>
  );
};

export const ImageCaption = ({
  caption,
  maxLength,
}: {
  caption: string;
  maxLength?: number;
}) => {
  const text = maxLength
    ? caption.substring(0, Math.min(maxLength, caption.length)).concat('...')
    : caption;

  return <Text className='assessment-image-caption'>{text}</Text>;
};

interface AssessmentImageModalProps {
  editable?: boolean;
  image: AssessmentImage;
  messageKey?: string;
  executionId?: string;
  onClose: VoidFunction;
}
export const AssessmentImageModal = (props: AssessmentImageModalProps) => {
  const src = `${Api.baseUrl}${Api.uploads.images}/${props.image.imageId}`;
  return (
    <Modal
      className='assessment-image-modal'
      onClose={props.onClose}
      dismissable
      header={'Review assessment image'}
      footer={
        <Group placement='end'>
          <Button onClick={props.onClose}>Close</Button>
        </Group>
      }
    >
      <Group orientation='column' className='assessment-image'>
        <SecureImage
          src={src}
          className='assessment-image-img'
          alt='Assessment image'
        />
        <Group orientation='column' className='assessment-modal-section'>
          <Text className='assessment-modal-section-title'>Caption</Text>
          <ImageCaption caption={props.image.caption} />
        </Group>

        <Group orientation='column' className='assessment-modal-section'>
          <Text className='assessment-modal-section-title'>Metadata</Text>
          <ImageMetadata metadata={props.image.metadata} maxEntries={100} />
        </Group>

        {props.executionId && props.messageKey && (
          <React.Suspense fallback={'Loading messages...'}>
            <CommentThread
              oldKey='-'
              executionId={props.executionId}
              messageKey={props.messageKey}
              onChange={() => {}}
              editable
            />
          </React.Suspense>
        )}
      </Group>
    </Modal>
  );
};

interface AssessmentImageFilterProps {
  images: AssessmentImage[];
  filterProp?: string;
  filterValue?: string;
  onChange({ prop, value }: { prop?: string; value?: string }): void;
}
export const AssessmentImageFilter = (props: AssessmentImageFilterProps) => {
  const selectProps = useFilterProps(props.images);
  const selectValues = useFilterValuesForProp(props.images, props.filterProp);

  const onChange = (value: string, mode: 'prop' | 'value') => {
    if (mode === 'prop') {
      props.onChange({ prop: value, value: undefined });
    } else {
      props.onChange({ prop: props.filterProp, value });
    }
  };

  const reset = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();
    props.onChange({});
  };

  return (
    <Group className='assessment-image-filter' placement='start'>
      <label className='assessment-image-filter-label'>
        <Text className='label-text'>Metadata property</Text>
        <Select
          className='assessment-image-filter-select'
          options={selectProps.map((p) => ({ label: p, value: p }))}
          key={props.filterProp}
          value={{ value: props.filterProp, label: props.filterProp }}
          onChange={({ value }: any) => onChange(value, 'prop')}
        />
      </label>
      <label className='assessment-image-filter-label'>
        <Text className='label-text'>Metadata value</Text>
        <Select
          className='assessment-image-filter-select'
          options={selectValues.map((p) => ({ label: p, value: p }))}
          key={props.filterValue}
          value={{ value: props.filterValue, label: props.filterValue }}
          onChange={({ value }: any) => onChange(value, 'value')}
        />
      </label>
      <Button onClick={reset}>Reset</Button>
    </Group>
  );
};

const useFilterProps = (images: AssessmentImage[]) => {
  return React.useMemo(() => {
    const allLabels = images.reduce((acc: string[], curr: AssessmentImage) => {
      const keys = Object.keys(curr.metadata);
      return acc.concat(keys);
    }, []);

    return Array.from(new Set(allLabels));
  }, [images]);
};

const useFilterValuesForProp = (images: AssessmentImage[], prop?: string) => {
  return React.useMemo(() => {
    if (prop) {
      let values: string[] = [];
      for (let image of images) {
        const val = image.metadata[prop];
        if (val && !values.includes(val)) {
          values.push(image.metadata[prop]);
        }
      }

      return values;
    }
    return [];
  }, [images, prop]);
};

export const SecureImage = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const { src, ...rest } = props;

  const [blob, setBlob] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (src) {
      rawFetcher(src).then((r) =>
        r.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          setBlob(url);
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(url);
        })
      );
    }
  }, [src]);

  return blob ? <img src={blob} {...rest} /> : null;
};
