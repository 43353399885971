import React, { useMemo, useState } from 'react';

import {
  Check,
  DeviceFloppy,
  Eye,
  EyeOff,
  Minus,
  Plus,
  Send,
} from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  BaseLayout,
  Button,
  ButtonWithIcon,
  ErrorBoundary,
  Group,
  Row,
} from 'components';

import { RJSForm } from 'pages/shared/rjsf/RJSForm';
import { CatalogEntryViewer } from './CatalogEntryViewer';
import { RJSFValidationError } from '@rjsf/utils';

import { FormErrorsModal } from './FormErrorsModal';
import { TaskDebugInfo } from './TaskDebugInfo';

import {
  persistComment,
  useMessageThread,
  useQARPreviewId,
  useTask,
  useTaskAssigned,
  useTaskData,
  useTaskSchema,
  useUpdateTask,
} from 'domain/swr';
import { merge } from 'domain/utils/json.utils';
import { Entity, Task } from 'domain/models';
import { pushNotification } from 'domain/store/root';

import { RoleGuard, useIsExternalUser } from 'pages/shared';
import { Tier2Index } from './Tier2Index';

import './TaskSchema.scss';
import { useDefaulFormData } from 'pages/shared/rjsf/utils';
import { useTranslation } from 'react-i18next';
import { HelpText } from 'components/HelpText';

type ITaskSchemaContext = {
  task?: Task;
};
const TaskSchemaContext = React.createContext<ITaskSchemaContext>(
  {} as ITaskSchemaContext
);

export const useTaskSchemaContext = () => React.useContext(TaskSchemaContext);

interface TaskSchemaProps {
  taskId: string;
}
const IntTaskSchema = (props: TaskSchemaProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , mutate] = useMessageThread();

  const taskSchema = useTaskSchema(props.taskId);
  const { assigned, editable, assign, unassign } = useTaskAssigned(
    props.taskId
  );
  const initFormData = useTaskData(props.taskId);
  const { task } = useTask(props.taskId);

  const isTier2 = React.useMemo(() => {
    return task && task.variables.TIER === 'TIER_2';
  }, [task]);

  const defaultFormData = useDefaulFormData(initFormData, taskSchema.schema);

  const [dirtyFormData, setDirtyFormData] = useState(false);
  const [formData, setFormData] = React.useState({ ...defaultFormData });

  const [modal, setModal] = React.useState<{
    show: boolean;
    errors?: RJSFValidationError[];
  }>({ show: false });

  React.useEffect(() => {
    if (dirtyFormData) {
      return;
    }
    setFormData({ ...defaultFormData });
  }, [defaultFormData, dirtyFormData]);

  const updateTask = useUpdateTask();

  const onChange = (formData: any) => {
    setDirtyFormData(true);
    setFormData(formData);
  };

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formData: any) => {
    setLoading(true);
    try {
      await savePendingComments();
      await updateTask(
        props.taskId,
        false,
        merge(
          initFormData, // This is needed in order to not skip non form fields
          formData
        )
      );
      dispatch(
        pushNotification({
          variant: 'success',
          title: 'Task completed',
          message:
            'The task has been successfully completed and will move to the next step of the workflow.',
        })
      );
      setLoading(false);
      navigate('/app/task/list');
    } catch (err) {
      console.error(err);
      dispatch(
        pushNotification({
          variant: 'error',
          title: 'Failed to complete task',
          message: 'A problem occurred while completing the task.',
        })
      );
      setLoading(false);
    }
  };

  const saveAsDraft = async () => {
    try {
      await savePendingComments();
      await updateTask(
        props.taskId,
        true,
        merge(
          initFormData, // This is needed in order to not skip non form fields
          formData
        )
      );
      // navigate('/app/task/list');
      dispatch(
        pushNotification({
          variant: 'success',
          title: 'Saved',
          message:
            'QAR saved as draft. It will be available next time you work on it',
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const savePendingComments = async () => {
    try {
      console.log('savePendingComments');
      await persistComment({ executionId: task.process_id });
      mutate();
    } catch (err) {
      throw new Error('Failed to save pending comments');
    }
  };

  const SubmitButton = () => {

    const { t } = useTranslation('help');

    return (
      <Group placement='space-between' style={{ paddingTop: '1em' }}>
        <Group placement='start'>
          <Row>
            <HelpText>{t('help:task.buttons')}</HelpText>
          </Row>
        </Group>
        <Group placement='end' style={{ paddingTop: '1em' }}>
          {/* 
        Usamos un row en lugar de un boton por que dentro del formulario
        todos los botones son tomados como submit y completarían la tarea
      */}
          <Row
            className='save-draft-button button'
            tabIndex={0}
            role='button'
            onClick={saveAsDraft}
          >
            Save as draft <DeviceFloppy />
          </Row>
          <ButtonWithIcon
            icon={<Send />}
            type='submit'
            disabled={loading}
            title='Submit the task, this will move the task to the next step of the workflow'
          >
            Submit
          </ButtonWithIcon>
        </Group>
      </Group>
    );
  };

  const closeErrorModal = React.useCallback(() => {
    setModal({ show: false, errors: undefined });
  }, []);

  const catalogueEntries = React.useMemo(() => {
    return (task.variables as any).catalogue_entries;
  }, [task]);

  const [preview, setPreview] = React.useState(false);

  const hidden = useMemo(() => {
    if (preview) {
      return false;
    }
    return !editable;
  }, [editable, preview]);

  return (
    <TaskSchemaContext.Provider value={{ task }}>
      <BaseLayout.Title title={task.name} />
      {catalogueEntries && <CatalogEntryViewer entries={catalogueEntries} />}
      <AssignControl
        assigned={assigned}
        editable={editable}
        assign={assign}
        assignee={task.assignee}
        unassign={unassign}
        preview={preview}
        setPreview={setPreview}
      />

      {!hidden && (
        <Row className={`${loading ? 'loading' : ''} task-form-wrapper`}>
          {isTier2 && (
            <Tier2Index schema={taskSchema.schema} formData={formData} />
          )}
          <RJSForm
            schema={taskSchema.schema}
            uiSchema={taskSchema.ui_schema}
            formData={formData}
            onChange={onChange}
            onSubmit={onSubmit}
            onError={(e) => {
              console.error(e);
              setModal({ show: true, errors: e });
            }}
            submitButton={<SubmitButton />}
          />
          {modal.show && (
            <FormErrorsModal
              errors={modal.errors ?? []}
              schema={taskSchema.schema}
              onClose={closeErrorModal}
              tier2={isTier2}
            />
          )}
        </Row>
      )}
    </TaskSchemaContext.Provider>
  );
};

const Skeleton = () => <div />;
const ErrorSkeleton = () => <div />;

const PreviewButtons = () => {
  // Check if is an external user
  const isExternalUser = useIsExternalUser();

  const getPreviewId = useQARPreviewId();
  const taskSchema = useTaskSchemaContext();

  const openPreviewPage = async (previewIdPostfix: string = '') => {
    // Go to /datasets/:id?tab=quality_assurance_tab
    // Open a new tab with the preview page
    try {
      if (!taskSchema.task?.variables?.qar) {
        return console.error('No QAR id found'); // FIXME: Show a message to the user
      }
      const previewId = await getPreviewId(
        `${taskSchema.task?.variables?.qar}${previewIdPostfix}`
      );
      window.open(`/datasets/${previewId}?tab=quality_assurance_tab`, '_blank');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Row className='pt-2 w-full flex-end justify-end gap-0'>
      {!isExternalUser && (
        <>
          <Button
            className='btn btn-primary border-r-1 text-sm rounded-none rounded-s-sm px-2 m-0 h-[2rem]'
            onClick={() => openPreviewPage('')}
          >
            Preview latest QAR
          </Button>
          <Button
            className='btn btn-primary border-r-1 text-sm rounded-none rounded-s-sm px-2 m-0 h-[2rem]'
            onClick={() => openPreviewPage('-p')}
          >
            Preview published QAR
          </Button>
        </>
      )}

      <Button
        className='btn btn-primary border-r-1 text-sm rounded-none rounded-s-sm px-2 m-0 h-[2rem]'
        onClick={() => openPreviewPage('-o')}
      >
        Preview this QAR
      </Button>
    </Row>
  );
};

const AssignControl = ({
  assigned,
  editable,
  assignee,
  preview,
  assign,
  unassign,
  setPreview,
}: {
  assigned: boolean;
  editable: boolean;
  assignee?: string;
  preview: boolean;
  assign?: () => void;
  unassign?: () => void;
  setPreview: (preview: boolean) => void;
}) => {
  if (!assigned) {
    // Show the assign button (assign to me)
    return (
      <ROMessage message='This task is not assigned. You can assign it to yourself.'>
        <RoleGuard entity={Entity.Task} action='read'>
          <Row className='already-assigned-task-buttons w-full flex justify-between'>
            <div>
              <ButtonWithIcon icon={<Plus />} onClick={assign}>
                Assign to me
              </ButtonWithIcon>
            </div>

            <div>
              <TaskDebugInfo />
              <PreviewButtons />
            </div>
          </Row>
        </RoleGuard>
      </ROMessage>
    );
  }

  return (
    <ROMessage
      message={
        editable
          ? 'This task is already assigned to you.'
          : 'This task is not assigned to you.'
      }
    >
      <RoleGuard entity={Entity.Task} action='read'>
        {!editable && (
          <RoleGuard entity={Entity.Task} action='delete'>
            <Row className='assigned-to'>Assigned to {assignee}.</Row>
          </RoleGuard>
        )}
        <Row className='already-assigned-task-buttons w-full flex justify-between'>
          <div>
            <div>
              <RoleGuard entity={Entity.Task} action='delete'>
                {editable && (
                  <ButtonWithIcon icon={<Minus />} onClick={unassign}>
                    Unassign
                  </ButtonWithIcon>
                )}
              </RoleGuard>
            </div>

            {!editable && (
              <RoleGuard entity={Entity.Task} action='delete'>
                <Row className='already-assigned-task-buttons'>
                  <ButtonWithIcon icon={<Minus />} onClick={unassign}>
                    Unassign
                  </ButtonWithIcon>

                  <ButtonWithIcon
                    icon={preview ? <EyeOff /> : <Eye />}
                    onClick={() => setPreview(!preview)}
                  >
                    {preview ? 'Hide preview' : 'Preview'}
                  </ButtonWithIcon>
                </Row>
              </RoleGuard>
            )}
          </div>
          <div>
            <TaskDebugInfo />
            <PreviewButtons />
          </div>
        </Row>
      </RoleGuard>
    </ROMessage>
  );
};

export const ROMessage = ({
  children,
  message,
}: {
  children?: React.ReactNode;
  message?: string;
}) => {
  return (
    <div className='read-only-message'>
      <h3>{message}</h3>
      <div>{children}</div>
    </div>
  );
};

export const AllowUnassign = ({ unassign }: { unassign?: () => void }) => {
  return <></>;
};

export const ReadOnlyMessage = ({
  assignedTo,
  unassign,
  preview,
  onPreview,
}: {
  assignedTo?: string;
  preview?: boolean;
  unassign?: () => void;
  onPreview?: () => void;
}) => {
  return (
    <div className='read-only-message'>
      <h3>This task is already assigned to someone else.</h3>
      <div>
        <RoleGuard entity={Entity.Task} action='delete'>
          <p>Assigned to {assignedTo}</p>
          <Row className='already-assigned-task-buttons'>
            <ButtonWithIcon icon={<Minus />} onClick={unassign}>
              Unassign
            </ButtonWithIcon>

            <ButtonWithIcon
              icon={preview ? <EyeOff /> : <Eye />}
              onClick={onPreview}
            >
              {preview ? 'Hide preview' : 'Preview'}
            </ButtonWithIcon>
          </Row>
        </RoleGuard>
      </div>
    </div>
  );
};

export const TaskSchema = (props: TaskSchemaProps) => {
  return (
    <React.Suspense fallback={<Skeleton />}>
      <ErrorBoundary fallback={<ErrorSkeleton />}>
        <IntTaskSchema taskId={props.taskId} />
      </ErrorBoundary>
    </React.Suspense>
  );
};
