import React from 'react';

import { Field } from '@rjsf/utils';
import { Eye, Plus, Trash } from 'tabler-icons-react';

import {
  ActionIcon,
  ButtonWithIcon,
  FileInput,
  FormControl,
  Group,
  Input,
  Text,
  Textarea,
} from 'components';

import { QAUseCase, QAUseCaseAttachment } from 'domain/models';
import { createId } from 'domain/utils';
import { CommentThread } from './CommentThreadControl';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';

import './QAUseCasesField.scss';
import {
  downloadUseCaseAttachment,
  useCaseAttachmentUploader,
  useUseCaseAttachments,
} from 'domain/swr';
import { useDispatch } from 'react-redux';
import { pushNotification } from 'domain/store/root';

export const QAUseCasesField: Field = (props) => {
  const useCases = (props.formData ?? []) as Partial<QAUseCase>[];

  const addUseCase = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      evt.preventDefault();

      props.onChange(
        useCases.concat({
          uc_key: createId(),
          uc_title: '',
          uc_comment: '',
          uc_attachments: [],
        })
      );
    },
    [props.formData, props.onChange, useCases]
  );

  const saveUseCase = React.useCallback(
    (useCase: Partial<QAUseCase>) => {
      props.onChange(
        useCases.map((uc) => (uc.uc_key === useCase.uc_key ? useCase : uc))
      );
    },
    [props.formData, props.onChange, useCases]
  );

  const deleteUseCase = React.useCallback(
    (useCase: Partial<QAUseCase>) => {
      props.onChange(useCases.filter((uc) => uc.uc_key !== useCase.uc_key));
    },
    [props.formData, useCases, props.onChange]
  );

  return (
    <Group orientation='column' placement='start'>
      {useCases.map((uc, i) => {
        const messageKey = `${props.idSchema.$id}-${uc.uc_key}`;
        return (
          <QAUseCaseField
            key={uc.uc_key}
            useCase={uc}
            messageKey={messageKey}
            useCaseSchema={(props.schema as any)?.items.properties}
            disabled={
              props.disabled || props.uiSchema?.['ui:readOnly'] === true
            }
            onDelete={deleteUseCase}
            onSave={saveUseCase}
          />
        );
      })}
      {!props.disabled && (
        <Group placement='end'>
          <ButtonWithIcon onClick={addUseCase} icon={<Plus />}>
            Add new use case
          </ButtonWithIcon>
        </Group>
      )}
    </Group>
  );
};

interface QAUseCaseFieldProps {
  useCase: Partial<QAUseCase>;
  useCaseSchema: any;
  messageKey: string;
  disabled?: boolean;
  onSave(useCase: Partial<QAUseCase>): void;
  onDelete(useCase: Partial<QAUseCase>): void;
}
const QAUseCaseField = (props: QAUseCaseFieldProps) => {
  const dispatch = useDispatch();

  const { task } = useTaskSchemaContext();

  const [loading, setLoading] = React.useState(false);

  const disabled = props.disabled || loading;

  const getAttachmentsParams = React.useMemo(() => {
    const ids = (props.useCase.uc_attachments ?? []) as unknown as string[];
    const qar = task?.variables.qar;

    return { ids, qar };
  }, [task, props.useCase]);

  const [attachments, deleteAttachment] = useUseCaseAttachments(
    getAttachmentsParams.ids,
    getAttachmentsParams.qar
  );

  const attachmentUploader = useCaseAttachmentUploader(
    getAttachmentsParams.qar
  );

  const update = <K extends keyof QAUseCase>(key: K, value: QAUseCase[K]) => {
    const newCase = {
      ...props.useCase,
      [key]: value,
    };
    props.onSave(newCase);
  };

  // USE_CASE_IMAGE - Upload attachment
  const uploadAttachment = async (files: File[]) => {
    try {
      setLoading(true);
      const newAttachments: QAUseCaseAttachment[] = await Promise.all(
        files.map((f) => attachmentUploader(f))
      );
      const allAttachments = attachments
        .concat(newAttachments)
        .map((a) => a.attachmentId);
      update('uc_attachments', allAttachments);
    } catch (err) {
      dispatch(
        pushNotification({
          variant: 'error',
          title: 'Error',
          message: 'An error occurred while trying to load the file.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  // USE_CASE_IMAGE - Delete attachment
  const removeFile = async (file: QAUseCaseAttachment) => {
    try {
      setLoading(true);
      await deleteAttachment(file.attachmentId);
      const newAttachments = attachments
        .filter((a) => a.attachmentId !== file.attachmentId)
        .map((f) => f.attachmentId);
      update('uc_attachments', newAttachments);
    } catch (err) {
      dispatch(
        pushNotification({
          variant: 'error',
          title: 'Error',
          message: 'An error occurred while trying to delete the file.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  // USE_CASE_IMAGE - Download attachment
  const downloadAttachment = (attachment: QAUseCaseAttachment) => {
    downloadUseCaseAttachment(attachment);
  };

  return (
    <Group orientation='column' placement='start' className='use-case'>
      <FormControl
        label={props.useCaseSchema.uc_title.title}
        description={props.useCaseSchema.uc_title.description}
      >
        <Input
          disabled={disabled}
          className='use-case-input'
          value={props.useCase.uc_title}
          onChange={({ target }) => update('uc_title', target.value)}
        />
      </FormControl>
      <FormControl
        label={props.useCaseSchema.uc_comment.title}
        description={props.useCaseSchema.uc_comment.description}
      >
        <Textarea
          disabled={disabled}
          className='use-case-input'
          value={props.useCase.uc_comment}
          onChange={({ target }) => update('uc_comment', target.value)}
        />
      </FormControl>
      <FormControl
        label={props.useCaseSchema.uc_attachments.title}
        description={props.useCaseSchema.uc_attachments.description}
      >
        <FileInput
          disabled={disabled}
          className='use-case-file-input'
          files={(attachments ?? []).map((d) => d.name)}
          multiple
          onFileDrop={(files) => {
            uploadAttachment(files);
          }}
        >
          <Group
            orientation='column'
            placement='start'
            spacing='0.5em'
            className='use-case-file-list'
          >
            {(attachments ?? []).map((att) => {
              return (
                <Group
                  className='use-case-file'
                  placement='start'
                  key={att.attachmentId}
                  spacing='0.5em'
                >
                  {!disabled && (
                    <ActionIcon
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        removeFile(att);
                      }}
                      className='use-case-file-button'
                    >
                      <Trash className='use-case-file-icon delete' />
                    </ActionIcon>
                  )}
                  <ActionIcon
                    className='use-case-file-button'
                    onClick={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      downloadAttachment(att);
                    }}
                  >
                    <Eye className='use-case-file-icon download' />
                  </ActionIcon>
                  <Text>{att.name}</Text>
                </Group>
              );
            })}
            {attachments?.length === 0 && (
              <Text>Click here or drag and drop files</Text>
            )}
          </Group>
        </FileInput>
      </FormControl>

      {!disabled && (
        <Group placement='end'>
          <ButtonWithIcon
            icon={<Trash />}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              props.onDelete(props.useCase);
            }}
          >
            Delete
          </ButtonWithIcon>
        </Group>
      )}
      {task && (
        <CommentThread
          oldKey='-'
          executionId={task?.process}
          editable
          messageKey={props.messageKey}
          onChange={() => {}}
        />
      )}
    </Group>
  );
};
