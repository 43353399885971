import React, { Suspense } from 'react';

import { Route, Navigate, Routes } from 'react-router-dom';
import { Loader } from 'tabler-icons-react';

import { FullPage, Group, ProtectedRoute, Text } from 'components';

import './Router.scss';

const RootApp = React.lazy(
  () => import(/* webpackChunkName: "app.root" */ 'pages/app/root/RootApp')
);

const AuthPage = React.lazy(
  () => import(/* webpackChunkName: "auth.page" */ 'pages/app/auth/AuthPage')
);

const PostLogin = React.lazy(
  () => import(/* webpackChunkName: "auth-post" */ 'pages/app/auth/PostLogin')
);

const MagicLink = React.lazy(
  () => import(/* webpackChunkName: "auth-magic-link" */ 'pages/app/auth/MagicLink')
);


const LogoutPage = React.lazy(
  () => import(/* webpackChunkName: "logout" */ 'pages/app/auth/LogoutPage')
);

export const Router = React.memo(() => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route path='/magic-link' element={<MagicLink />} />
        <Route path='/auth' element={<AuthPage />} />
        <Route path='/auth/post-login/*' element={<PostLogin />} />
        <Route path='/auth/logout' element={<LogoutPage />} />
        <Route
          path='/app/*'
          element={
            <ProtectedRoute>
              <RootApp />
            </ProtectedRoute>
          }
        ></Route>
        <Route path='/' element={<Navigate to='/app' />} />
      </Routes>
    </Suspense>
  );
});

const RouterSkeleton = () => {
  return (
    <FullPage>
      <Group orientation='column' className='router-skeleton'>
        <Text className='router-skeleton-title'>Welcome to CopDS - CIM</Text>

        <Text className='router-skeleton-hint'>
          Please, wait for the application load
        </Text>

        <Loader className='loader-icon' strokeWidth={1} size={96} />
      </Group>
    </FullPage>
  );
};
