import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { Group, Row, Text, Textarea } from 'components';
import { Check, X } from 'tabler-icons-react';
import { readOnlyFromProps } from './utils';

import './ApproveRejectField.scss';

export const ApproveRejectField: Field = (props: FieldProps) => {
  const readOnly = readOnlyFromProps(props);

  return (
    <Group
      orientation='column'
      placement='start'
      className='rjsf-approve-reject-field'
    >
      <ApproveRejectControl
        readonly={readOnly}
        approved={props.formData?.approved}
        onChange={(val) => props.onChange({ ...props.formData, approved: val })}
      />
    </Group>
  );
};

interface ApproveRejectControlProps {
  readonly?: boolean;
  approved?: boolean;
  onChange(value: boolean): void;
}
const ApproveRejectControl = (props: ApproveRejectControlProps) => {
  return (
    <Group orientation='row' className='approve-reject-control' placement='end'>
      {props.readonly ? (
        <Text
          className={`aprove-text ${props.approved ? 'approved' : 'rejected'}`}
        >
          {props.approved ? 'Approved' : 'Rejected'}
          {props.approved ? <Check size={18} /> : <X size={18} />}
        </Text>
      ) : (
        <>
          <Row
            onClick={() => props.onChange(true)}
            className={`approve-reject-icon-wrapper approve ${
              props.approved ? 'active' : ''
            }`}
          >
            Approve <Check size={24} strokeWidth={1} />
          </Row>
          <Row
            onClick={() => props.onChange(false)}
            className={`approve-reject-icon-wrapper reject ${
              props.approved === false ? 'active' : ''
            }`}
          >
            Reject <X size={24} strokeWidth={1} />
          </Row>
        </>
      )}
    </Group>
  );
};
