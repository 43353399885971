import React from "react";

import { AppStore, Entity, EntityAction, Role, User } from "domain/models";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

interface RoleGuardProps {
  entity: Entity;
  action: EntityAction;
}

export const LinkRoleGuard = (
  props: React.PropsWithChildren<RoleGuardProps & { to: string; }>,
) => {
  const pass = useRoleGuard(props.entity, props.action);

  if (pass) {
    return (
      <Link className="link" to={props.to}>
        {props.children}
      </Link>
    );
  }

  return <>{props.children}</>;
};

export const useHasAdminRights = (user?: Pick<User, 'role'>) => {
  return useHasRole(Role.Admin, Role.Manager, Role.EqcManager);
};

export const useIsExternalUser = (user?: Pick<User, 'role'>) => {
  return useHasRole(Role.EXT_DATA_PROVIDER);
}

export const RoleGuard = (props: React.PropsWithChildren<RoleGuardProps>) => {
  const pass = useRoleGuard(props.entity, props.action);

  if (pass) {
    return <>{props.children}</>;
  }

  return null;
};

export const useRoleGuard = (entity: Entity, action: EntityAction): boolean => {
  const roleMap = useSelector((store: AppStore) => store.auth.user?.roleMap);

  return React.useMemo(() => {
    if (!roleMap) {
      return false;
    }

    const entities = Object.keys(roleMap);

    if (entities.includes(Entity.All)) {
      return true;
    }

    const reqEntity = roleMap[entity];

    if (reqEntity) {
      const reqAction = reqEntity[action];
      return reqAction && reqAction === true;
    }

    return false;
  }, [roleMap, entity, action]);
};

/**
 *
 * @param roles
 * @returns True when the logged user has any of the roles
 */
export const useHasRole = (...roles: Role[]) => {
  const user = useSelector((store: AppStore) => store.auth.user?.user);

  return React.useMemo(() => {
    return user ? roles.includes(user?.role) : false;
  }, [user, roles]);
};
