import React, { useMemo } from "react";

import { DeviceFloppy, Pencil, Plus, Search, Trash } from "tabler-icons-react";

import {
  ActionIcon,
  ButtonWithIcon,
  FormControl,
  Group,
  Input,
  Modal,
  Select,
  Text,
  Tooltip,
} from "components";

import { References } from "domain/models";
import { useFormData, useInputState } from "domain/hooks";
import { inOptions } from "domain/utils";

import "./ReferencesField.scss";
import { useSearchReferences } from "domain/swr/useReferences";
import { readOnlyFromProps } from "./utils";

interface ReferencesFieldProps {
  disabled?: boolean;
  readOnly?: boolean;
  schema: any;
  references: References.Reference[];
  onChange(references: References.Reference[]): void;
}
export const ReferencesList = (props: ReferencesFieldProps) => {
  const [editingRef, setEditingRef] = React.useState<References.Reference>();
  const [currentIndex, setCurrentIndex] = React.useState<number>();
  const { parseFn } = useSearchReferences();

  const modalClose = async (
    intIndex: number,
    reference?: References.Reference
  ) => {
    // Update references
    if (reference && currentIndex !== undefined) {
      try {
        reference = await parseFn(reference);
        if (!reference) {
          return;
        }
      } catch (e) {
        console.error("error", e);
      }

      const newReferences = props.references.map((ref, index) =>
        index === intIndex ? reference! : ref
      );
      props.onChange(newReferences);
    }
    setEditingRef(undefined);
  };

  return (
    <Group
      orientation="column"
      className="md-editor-references"
      spacing="0.25em"
      placement="start"
    >
      {props.references && props.references.length !== 0 && (
        <Text className="title">References</Text>
      )}
      {Object.entries(props.references).map(([index, reference]) => {
        const intIndex = parseInt(index);

        return (
          <ReferenceItem
          {...props}
            key={intIndex}
            refIndex={intIndex}
            reference={reference}
            selectReference={(v) => {
              setEditingRef(v);
              setCurrentIndex(intIndex);
            }}
          />
        );
      })}
      {currentIndex !== undefined && editingRef && (
        <ReferenceModal
          reference={editingRef}
          onClose={(ref) => modalClose(currentIndex!, ref)}
          schema={props.schema}
          readOnly={Boolean(editingRef.Identifier)}
        />
      )}
    </Group>
  );
};

interface ReferenceItemProps {
  refIndex: number;
  reference: References.Reference;
  selectReference(reference: References.Reference): void;
  disabled?: boolean;
  readOnly?: boolean;
}
const ReferenceItem = (props: ReferenceItemProps) => {
  const [idValue, setIdValue] = useInputState(
    props.reference?.Identifier ?? ""
  );

  const {
    data: reference,
    fetchFn,
    isError,
    isLoading,
  } = useSearchReferences();

  React.useEffect(() => {
    if (!reference) {
      return;
    }
    props.selectReference(reference);
  }, [reference]);

  React.useEffect(() => {
    setIdValue("");
  }, [isError]);

  const formatted = useMemo(() => {
    return props.reference?.Formatted ?? props.reference?.Identifier;
  }, [props.reference]);

  const disabled = readOnlyFromProps(props);

  return (
    <Group placement="start" key={props.refIndex} className="reference">
      <Text className="reference-index">[ {props.refIndex + 1} ]</Text>
      {formatted ? (
        <Text className="reference-content">
          <span dangerouslySetInnerHTML={{ __html: formatted }} />
        </Text>
      ) : (
        <Input
          value={idValue}
          onChange={setIdValue}
          className="reference-input"
          placeholder={
            isError
              ? "There is no reference to this identifier. Please define the reference"
              : "Enter the identifier (DOI) of the reference. If you don't have an identifier, create a reference by clicking on the button"
          }
        />
      )}
      {!disabled && (
        <>
          {idValue && !reference ? (
            <Tooltip content="Search reference">
              <ActionIcon
                disabled={isLoading}
                className="add-reference-button"
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  fetchFn(idValue);
                }}
              >
                <Search strokeWidth={1} />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip content="Create reference">
              <ActionIcon
                className="add-reference-button"
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  props.selectReference(props.reference);
                }}
              >
                <Pencil strokeWidth={1} />
              </ActionIcon>
            </Tooltip>
          )}
        </>
      )}
    </Group>
  );
};

interface ReferenceModalProps {
  schema: any;
  reference: References.Reference;
  readOnly: boolean;
  onClose(reference?: References.Reference): void;
}
const ReferenceModal = ({
  schema,
  reference,
  readOnly,
  onClose,
}: ReferenceModalProps) => {
  const [formData, setFormData, resetFormData] = useFormData(reference);

  const { errors, isValid } = useValidate(formData);

  const submit = () => {
    if (isValid) {
      onClose(formData);
    } else {
      onClose();
    }
  };

  return (
    <Modal.Portal>
      <Modal
        dismissable
        onClose={() => onClose()}
        className="reference-modal"
        header={<Text>Define reference</Text>}
        footer={
          <Group placement="end">
            <ButtonWithIcon
              onClick={submit}
              icon={<DeviceFloppy strokeWidth={1} />}
              disabled={!isValid}
            >
              Confirm
            </ButtonWithIcon>
          </Group>
        }
      >
        <Group orientation="column" className="reference-form">
          <CreatorFormField
            readOnly={readOnly}
            errors={errors}
            reference={formData}
            onChange={resetFormData}
            schema={schema.items.properties.Creator.items.properties}
          />
          <TitleFormField
            readOnly={readOnly}
            errors={errors}
            reference={formData}
            onChange={resetFormData}
            schema={schema.items.properties.Title.items.properties}
          />
          <Fieldset title="Publisher">
            <FormControl label="Publisher" error={errors.Publisher}>
              <Input
                disabled={readOnly}
                name="publisher"
                value={formData.Publisher ?? ""}
                onChange={setFormData("Publisher")}
              />
            </FormControl>
          </Fieldset>
          <Fieldset title="Publication year">
            <FormControl
              label="Publication year"
              error={errors.PublicationYear}
            >
              <Input
                disabled={readOnly}
                name="publicationYear"
                value={formData.PublicationYear ?? ""}
                onChange={setFormData("PublicationYear")}
              />
            </FormControl>
          </Fieldset>
          <ResourceTypeFormField
            readOnly={readOnly}
            errors={errors}
            reference={formData}
            onChange={resetFormData}
            schema={schema.items.properties.ResourceType.properties}
          />
        </Group>
      </Modal>
    </Modal.Portal>
  );
};

interface ArrayFieldProps extends React.PropsWithChildren {
  onItemAdd: VoidFunction;
  readOnly: boolean;
}
const ArrayField = ({ onItemAdd, readOnly, children }: ArrayFieldProps) => {
  return (
    <Group orientation="column" className="reference-array-field">
      {children}
      {!readOnly && (
        <Group placement="end">
          <ButtonWithIcon icon={<Plus strokeWidth={1} />} onClick={onItemAdd} />
        </Group>
      )}
    </Group>
  );
};

interface FieldsetProps extends React.PropsWithChildren {
  title: string;
}
const Fieldset = ({ title, children }: FieldsetProps) => {
  return (
    <fieldset className="references-fieldset">
      <legend className="references-fieldset-legend">{title}</legend>
      {children}
    </fieldset>
  );
};

interface RemoveItemButtonProps {
  onClick: VoidFunction;
  textSuffix: string;
  readOnly: boolean;
}
const RemoveItemButton = ({
  textSuffix = "item",
  readOnly,
  onClick,
}: RemoveItemButtonProps) => {
  return readOnly ? null : (
    <Group placement="end">
      <ButtonWithIcon icon={<Trash strokeWidth={1} />} onClick={onClick}>
        Remove {textSuffix}
      </ButtonWithIcon>
    </Group>
  );
};

interface FieldsetErrorProps extends React.PropsWithChildren {}
const FieldsetError = ({ children }: FieldsetErrorProps) => (
  <Text className="fieldset-error">{children}</Text>
);

interface CreatorFormFieldProps {
  readOnly: boolean;
  errors: any;
  schema: any;
  reference: References.Reference;
  onChange(reference: References.Reference): void;
}
const CreatorFormField = ({
  errors,
  schema,
  reference,
  readOnly,
  onChange,
}: CreatorFormFieldProps) => {
  const nameTypeOptions = useOptionsFromSchema(schema.nameType);
  const nameIdentifierSchemeOptions = useOptionsFromSchema(
    schema.nameIdentifier.items.properties.nameIdentifierScheme
  );

  const addCreator = () => {
    onChange({
      ...reference,
      Creator: reference.Creator.concat(EMPTY_CREATOR),
    });
  };

  const addNameIdentifier = (creatorIndex: number) => {
    onChange({
      ...reference,
      Creator: reference.Creator.map((creator, index) =>
        index === creatorIndex
          ? {
              ...creator,
              nameIdentifier: creator.nameIdentifier?.concat(
                EMPTY_NAME_IDENTIFIER
              ),
            }
          : creator
      ),
    });
  };

  const removeCreator = (index: number) => {
    onChange({
      ...reference,
      Creator: reference.Creator.filter((c, i) => i !== index),
    });
  };

  const removeNameIdentifier = (creatorIndex: number, nameIndex: number) => {
    onChange({
      ...reference,
      Creator: reference.Creator.map((creator, index) =>
        index === creatorIndex
          ? {
              ...creator,
              nameIdentifier: creator.nameIdentifier?.filter(
                (id, i) => i !== nameIndex
              ),
            }
          : creator
      ),
    });
  };

  const intOnChange = <Key extends keyof References.Creator>(
    key: Key,
    value: References.Creator[Key],
    index: number
  ) => {
    onChange({
      ...reference,
      Creator: reference.Creator.map((c, i) =>
        i === index
          ? {
              ...c,
              [key]: value,
            }
          : c
      ),
    });
  };

  const updateNameIdentifier = <
    Key extends keyof References.CreatorNameIdentifier
  >(
    key: Key,
    value: References.CreatorNameIdentifier[Key],
    creatorIndex: number,
    nameIndex: number
  ) => {
    const creator = reference.Creator[creatorIndex];
    const newNameIdentifiers = creator.nameIdentifier?.map((id, index) =>
      index === nameIndex
        ? {
            ...id,
            [key]: value,
          }
        : id
    );
    intOnChange("nameIdentifier", newNameIdentifiers, creatorIndex);
  };

  return (
    <Fieldset title="Creators">
      <Group orientation="column" className="creator-form-field">
        <ArrayField onItemAdd={addCreator} readOnly={readOnly}>
          {reference.Creator.map(
            (
              {
                creatorName,
                nameType,
                givenName,
                familyName,
                nameIdentifier,
                affiliation,
              },
              index
            ) => {
              const creatorErrors = errors.Creator?.[index];
              return (
                <Group
                  key={index}
                  orientation="column"
                  className="array-child-item"
                >
                  <Group>
                    <FormControl
                      required
                      label="Creator name"
                      error={creatorErrors?.creatorName}
                    >
                      <Input
                        disabled={readOnly}
                        name="creatorName"
                        value={creatorName ?? ""}
                        onChange={({ target }) =>
                          intOnChange("creatorName", target.value, index)
                        }
                      />
                    </FormControl>
                    <FormControl
                      required
                      label="Name type"
                      error={creatorErrors?.nameType}
                    >
                      <Select
                        isDisabled={readOnly}
                        name="nameType"
                        value={inOptions(nameTypeOptions, nameType)}
                        options={nameTypeOptions}
                        onChange={({ value }: any) =>
                          intOnChange("nameType", value, index)
                        }
                      />
                    </FormControl>
                    <FormControl
                      label="Given name"
                      error={creatorErrors?.givenName}
                    >
                      <Input
                        disabled={readOnly}
                        name="givenName"
                        value={givenName ?? ""}
                        onChange={({ target }) =>
                          intOnChange("givenName", target.value, index)
                        }
                      />
                    </FormControl>
                  </Group>

                  <Group>
                    <FormControl
                      label="Family name"
                      error={creatorErrors?.familyName}
                    >
                      <Input
                        disabled={readOnly}
                        name="familyName"
                        value={familyName ?? ""}
                        onChange={({ target }) =>
                          intOnChange("familyName", target.value, index)
                        }
                      />
                    </FormControl>

                    <FormControl
                      label="Affiliation identifier"
                      error={creatorErrors?.affiliation?.affiliationIdentifier}
                    >
                      <Input
                        disabled={readOnly}
                        name="affiliationIdentifier"
                        value={affiliation?.affiliationIdentifier ?? ""}
                        onChange={({ target }) =>
                          intOnChange(
                            "affiliation",
                            {
                              ...affiliation,
                              affiliationIdentifier: target.value,
                            },
                            index
                          )
                        }
                      />
                    </FormControl>
                    <FormControl
                      label="Affiliation scheme URI"
                      error={creatorErrors?.affiliation?.schemeURI}
                    >
                      <Input
                        disabled={readOnly}
                        name="schemeURI"
                        value={affiliation?.schemeURI ?? ""}
                        onChange={({ target }) =>
                          intOnChange(
                            "affiliation",
                            {
                              ...affiliation,
                              schemeURI: target.value,
                            },
                            index
                          )
                        }
                      />
                    </FormControl>
                  </Group>

                  <Fieldset title="Name identifier">
                    <ArrayField
                      onItemAdd={() => addNameIdentifier(index)}
                      readOnly={readOnly}
                    >
                      {nameIdentifier?.map(
                        ({ nameIdentifierScheme, schemeURI }, nameIndex) => {
                          const identifierErrors =
                            creatorErrors?.nameIdentifier?.[nameIndex];
                          return (
                            <Group
                              orientation="column"
                              key={nameIndex}
                              className="array-child-item"
                            >
                              <Group>
                                <FormControl
                                  required
                                  label="Name identifier"
                                  error={identifierErrors?.nameIdentifierScheme}
                                >
                                  <Select
                                    isMulti
                                    isDisabled={readOnly}
                                    name="nameIdentifierScheme"
                                    value={nameIdentifierScheme}
                                    options={nameIdentifierSchemeOptions}
                                    onChange={({ value }: any) =>
                                      updateNameIdentifier(
                                        "nameIdentifierScheme",
                                        value,
                                        index,
                                        nameIndex
                                      )
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  required
                                  label="Schema URI"
                                  error={
                                    identifierErrors?.nameIdentifierSchemeURI
                                  }
                                >
                                  <Input
                                    disabled={readOnly}
                                    name="nameIdentifierSchemeURI"
                                    value={schemeURI ?? ""}
                                    onChange={({ target }) =>
                                      updateNameIdentifier(
                                        "schemeURI",
                                        target.value,
                                        index,
                                        nameIndex
                                      )
                                    }
                                  />
                                </FormControl>
                              </Group>
                              <RemoveItemButton
                                readOnly={readOnly}
                                onClick={() =>
                                  removeNameIdentifier(index, nameIndex)
                                }
                                textSuffix="name identifier"
                              />
                            </Group>
                          );
                        }
                      )}
                    </ArrayField>
                  </Fieldset>
                  <RemoveItemButton
                    readOnly={readOnly}
                    onClick={() => removeCreator(index)}
                    textSuffix="creator"
                  />
                </Group>
              );
            }
          )}
        </ArrayField>
      </Group>
      {reference.Creator.length === 0 && (
        <FieldsetError>Reference must have at least one creator</FieldsetError>
      )}
    </Fieldset>
  );
};

interface TitleFormFieldProps {
  readOnly: boolean;
  errors: any;
  schema: any;
  reference: References.Reference;
  onChange(reference: References.Reference): void;
}
const TitleFormField = ({
  readOnly,
  errors,
  schema,
  reference,
  onChange,
}: TitleFormFieldProps) => {
  const titleTypeOptions = useOptionsFromSchema(schema.titleType);

  const onTitleAdd = () => {
    onChange({
      ...reference,
      Title: reference.Title.concat(EMPTY_TITLE),
    });
  };

  const removeTitle = (index: number) => {
    onChange({
      ...reference,
      Title: reference.Title.filter((t, i) => i !== index),
    });
  };

  const intOnChange = <Key extends keyof References.Title>(
    key: Key,
    value: References.Title[Key],
    index: number
  ) => {
    onChange({
      ...reference,
      Title: reference.Title.map((c, i) =>
        i === index
          ? {
              ...c,
              [key]: value,
            }
          : c
      ),
    });
  };

  return (
    <Fieldset title="Titles">
      <Group orientation="column">
        <ArrayField onItemAdd={onTitleAdd} readOnly={readOnly}>
          {reference.Title.map(({ titleType, value }, index) => {
            const titleError = errors.Title?.[index];
            return (
              <Group orientation="column">
                <Group key={index}>
                  <FormControl required label="Value" error={titleError?.value}>
                    <Input
                      disabled={readOnly}
                      value={value ?? ""}
                      name="titleValue"
                      onChange={({ target }) =>
                        intOnChange("value", target.value, index)
                      }
                    />
                  </FormControl>
                  <FormControl label="Type" error={titleError?.titleType}>
                    <Select
                      isDisabled={readOnly}
                      name="titleValue"
                      options={titleTypeOptions}
                      value={inOptions(titleTypeOptions, titleType)}
                      onChange={({ value }: any) =>
                        intOnChange("titleType", value, index)
                      }
                    />
                  </FormControl>
                </Group>
                <RemoveItemButton
                  readOnly={readOnly}
                  onClick={() => removeTitle(index)}
                  textSuffix="title"
                />
              </Group>
            );
          })}
        </ArrayField>
      </Group>
      {reference.Title.length === 0 && (
        <FieldsetError>At least one title is required</FieldsetError>
      )}
    </Fieldset>
  );
};

interface ResourceTypeFormFieldProps {
  readOnly: boolean;
  errors: any;
  schema: any;
  reference: References.Reference;
  onChange(reference: References.Reference): void;
}
const ResourceTypeFormField = ({
  readOnly,
  errors,
  schema,
  reference,
  onChange,
}: ResourceTypeFormFieldProps) => {
  const resourceTypeGeneralOptions = useOptionsFromSchema(
    schema.resourceTypeGeneral
  ).map((option) => ({
    ...option,
    value: option.value.toUpperCase(),
  }));

  const intOnChange = <Key extends keyof References.ResourceType>(
    key: Key,
    value: References.ResourceType[Key]
  ) => {
    onChange({
      ...reference,
      ResourceType: {
        ...reference.ResourceType,
        [key]: value,
      },
    });
  };

  return (
    <Fieldset title="Resource type">
      <Group>
        <FormControl required label="Value" error={errors?.ResourceType?.value}>
          <Input
            disabled={readOnly}
            name="resourceTypeValue"
            value={reference.ResourceType?.value ?? ""}
            onChange={({ target }) => intOnChange("value", target.value)}
          />
        </FormControl>
        <FormControl
          required
          label="Resource type general"
          error={errors?.ResourceType?.resourceTypeGeneral}
        >
          <Select
            isDisabled={readOnly}
            name="resourceTypeGeneral"
            value={inOptions(
              resourceTypeGeneralOptions,
              reference.ResourceType?.resourceTypeGeneral
            )}
            menuPosition="fixed"
            options={resourceTypeGeneralOptions}
            onChange={({ value }: any) =>
              intOnChange("resourceTypeGeneral", value)
            }
          />
        </FormControl>
      </Group>
    </Fieldset>
  );
};

const EMPTY_NAME_IDENTIFIER: References.CreatorNameIdentifier = {
  nameIdentifierScheme: References.CreatorNameIdentifierScheme.GRID,
  schemeURI: "",
};
const EMPTY_TITLE: References.Title = {
  value: "",
  titleType: null,
};
const EMPTY_CREATOR: References.Creator = {
  creatorName: "",
  nameType: References.CreatorNameType.Personal,
  givenName: "",
  familyName: "",
  nameIdentifier: [EMPTY_NAME_IDENTIFIER],
  affiliation: {
    affiliationIdentifier: "",
    schemeURI: "",
  },
};
const EMPTY_REFERENCE: References.Reference = {
  Creator: [EMPTY_CREATOR],
  Identifier: "",
  PublicationYear: "",
  Publisher: "",
  ResourceType: {
    resourceTypeGeneral: References.ResourceTypeGeneral.Other,
    value: "",
  },
  Title: [EMPTY_TITLE],
};
export const extractReferences = (content: string) => {
  const refPattern = new RegExp(/\[\d+\]/g);
  const digitPattern = new RegExp(/\d+/g);

  const matches = content.matchAll(refPattern);

  let refs = [];

  let lastRef = 0; // Ensure monotonically increasing references
  let maxRef = 0; // Ensure no references are skipped
  for (let match of matches) {
    const ref = match[0];
    if (ref) {
      const digit = ref.match(digitPattern);
      if (digit && digit.length === 1) {
        const digNum = parseInt(digit[0]);
        if (digNum < 1) {
          continue;
        }

        if (lastRef + 1 !== digNum || digNum < maxRef) {
          continue;
        }
        if (lastRef + 1 === digNum) {
          lastRef = digNum;
        }
        maxRef = Math.max(maxRef, digNum);

        refs[digNum - 1] = EMPTY_REFERENCE; // Arrays starts at 0, but references starts at 1
      }
    }
  }

  return refs;
};

const useOptionsFromSchema = (schema: {
  enum: string[];
  enumNames: string[];
}) => {
  return React.useMemo(() => {
    let result = [];

    for (let i = 0; i < schema.enum.length; i++) {
      result.push({
        value: schema.enum[i],
        label: schema.enumNames[i],
      });
    }

    return result;
  }, [schema]);
};

const getCreatorErrors = (reference: References.Reference) => {
  let creatorsAreValid = true;
  const creatorsErrors = reference.Creator.map((creator) => {
    let pass = true,
      creatorErrors: any = {};

    if (!creator.creatorName) {
      creatorErrors.creatorName = "Creator name is required";
      pass = false;
      creatorsAreValid = false;
    }

    if (!pass) {
      return creatorErrors;
    }
  });

  return {
    creatorsErrors,
    creatorsAreValid,
  };
};

const getTitleErrors = (references: References.Reference) => {
  let titlesAreValid = true;
  const titlesErrors = references.Title.map((title) => {
    let pass = true,
      titleErrors: any = {};

    if (!title.value) {
      titleErrors.value = "Title is required";
      titlesAreValid = false;
      pass = false;
    }

    if (!pass) {
      return titleErrors;
    }
  });

  return {
    titlesAreValid,
    titlesErrors,
  };
};

const useValidate = (reference: References.Reference) => {
  return React.useMemo(() => {
    let errors: any = {},
      finalIsValid = true;

    const { creatorsAreValid, creatorsErrors } = getCreatorErrors(reference);
    const { titlesAreValid, titlesErrors } = getTitleErrors(reference);

    errors.Creator = creatorsErrors;
    errors.Title = titlesErrors;

    if (reference.Creator.length === 0) {
      finalIsValid = false;
    }

    if (reference.Title.length === 0) {
      finalIsValid = false;
    }

    errors.ResourceType = {};
    if (!reference.ResourceType.value) {
      errors.ResourceType.value = "Resource type is required";
      finalIsValid = false;
    }

    if (!reference.ResourceType.resourceTypeGeneral) {
      errors.ResourceType.resourceTypeGeneral =
        "Resource type general is required";
      finalIsValid = false;
    }

    return {
      errors,
      isValid: finalIsValid && creatorsAreValid && titlesAreValid,
    };
  }, [reference]);
};
