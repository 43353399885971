import React, { useEffect, useMemo } from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { Group, Text } from 'components';
import { useRJSFormContext } from './RJSForm';
import { useExecutionId } from 'pages/app/task/hooks/useExecutionId';
import { useGeneratePublicUrl } from 'domain/swr/useUserAccess';
import cn from 'classnames';

import './ForwardControl.scss';

const extractKey = (id: string) => {
  const parts = id.split('|');
  return parts.slice(1, parts.length - 1);
};

const goDeep = (obj: any, path: string[], key: string) => {
  let current = obj;
  for (const p of path) {
    if (!current[p]) {
      return null;
    }
    current = current[p];
  }
  return current[key];
};

export const PublicUrlProvider: Field = (props: FieldProps) => {

  const [ publicUrl, loading, generatePublicUrl, ] = useGeneratePublicUrl();
  
  // Use form context for checking if email and name are present,
  // once they are present, we will call the API to get the public URL
  // and show it to the user
  
  const { formData } = useRJSFormContext();
  const { executionId } = useExecutionId();

  // Split in |, and we can remove first one (root), and the last one (current)
  // So we can go straight into the formData.fields.(...path).email and formData.fields.(...path).name

  const theKey = useMemo(() => extractKey(props.idSchema.$id), [props.idSchema.$id]);
  const email = useMemo(() => goDeep(formData, theKey, 'email'), [formData, theKey]);
  const name = useMemo(() => goDeep(formData, theKey, 'name'), [formData, theKey]);
  useEffect(() => {

    generatePublicUrl({ email, name, executionId });

  }, [email, name, executionId]);    
  
  return (
    <Group className={cn('rjsf-forward-control', { 'opacity-50': loading }) } orientation='column'>
      <Group className='forward-control-options' placement='start'>
        <Text className='forward-control-title bg-gray-200 w-full p-2 my-2'>
          {publicUrl}
        </Text>
      </Group>
    </Group>
  );
};
