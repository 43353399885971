import React, { useMemo } from 'react';

import { ArrowForward, Pencil, Send, Trash } from 'tabler-icons-react';
import { Field, FieldProps } from '@rjsf/utils';
import { format } from 'date-fns';
import set from 'lodash.set';

import {
  ButtonWithIcon,
  Column,
  Group,
  Paragraph,
  Row,
  Text,
  Textarea,
  Tooltip,
} from 'components';
import { useInputState } from 'domain/hooks';
import { ChatThread, Message, Role } from 'domain/models';
import { useMessageThread, useQAR, useUser } from 'domain/swr';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';
import { useRJSFormContext } from './RJSForm';
import { MultiLineRenderer } from 'components/MultiLineRenderer';

import './CommentThreadControl.scss';
import { useTranslation } from 'react-i18next';
import { HelpText } from 'components/HelpText';

export const useQARIdFromUrl = () => {
  const url = window.location.href;
  if (!url.includes('/app/qar')) {
    return undefined;
  }

  const splitted = url.split('/');

  let qarId: string | undefined = undefined;
  if (splitted[splitted.length - 1] === 'printable') {
    qarId = splitted[splitted.length - 2];
  } else {
    qarId = splitted[splitted.length - 1];
  }

  if (qarId !== undefined && qarId !== null) {
    try {
      return parseInt(qarId);
    } catch (e) {
      console.error('Error parsing QAR ID', e);
    }
  }
  return undefined;
};

export const CommentThreadControl: Field = (props: FieldProps) => {
  const { task } = useTaskSchemaContext();
  const qarId = useQARIdFromUrl();
  const executionIdFromQar = useQAR(qarId);

  const executionId = useMemo(() => {
    return task?.process_id ?? executionIdFromQar?.execution_id ?? '';
  }, [task, executionIdFromQar]);

  const qarProcessId = useMemo(
    () => executionIdFromQar?.execution_id?.split('-')[0],
    [executionIdFromQar]
  );
  const processIdentifier = useMemo(
    () => task?.process ?? qarProcessId,
    [task, qarProcessId]
  );

  const messageId = useMessageThreadId(
    props.idSchema.$id,
    true,
    processIdentifier
  );

  const memoizedCommentThread = useMemo(() => {
    if (!messageId) {
      return null;
    }
    return (
      <CommentThread
        oldKey={props.idSchema.$id}
        messageKey={messageId}
        executionId={executionId}
        onChange={props.onChange}
        processIdentifier={processIdentifier}
        editable
      />
    );
  }, [messageId]);

  return memoizedCommentThread;
};

interface CommentThreadProps {
  oldKey: string;
  messageKey: string;
  executionId: string;
  editable?: boolean;
  hideTitle?: boolean;
  small?: boolean;
  processIdentifier?: string;
  onChange(messages: Message[]): void;
}
export const CommentThread = (props: CommentThreadProps) => {
  const [showMessages, setShowMessages] = React.useState(true);

  // Check the key. If it is a root key, we will need to retrieve
  // the old version of the message thread (without UUID, but with root or thread_root).
  const [currentMessages, pushMessage, , deleteMessage] = useMessageThread(
    props.executionId,
    props.messageKey
  );

  const tec = useTaskSchemaContext();

  const tier = useMemo(() => {
    if (tec.task?.process_id) {
      // Extract first part that is eqctierX, where X is the tier
      return tec.task.process_id.split('-')[0]?.substring(3);
    }

    if (props.processIdentifier) {
      if (props.processIdentifier.endsWith('tier1')) {
        return 'tier1';
      }
      if (props.processIdentifier.endsWith('tier2')) {
        return 'tier2';
      }
      if (props.processIdentifier.endsWith('tier3')) {
        return 'tier3';
      }
    }

    return undefined;
  }, [tec.task, props.processIdentifier]);

  const oldMessageKey = useMemo(() => {
    if (tier === 'tier3') {
      if (props.oldKey?.includes('root')) {
        // Only get the root if is root|xxx|0|, so we filter for |0|
        if (props.oldKey.includes('root|ucs|0|')) {
          return 'root';
        }
        if (props.oldKey === 'root|ext_comment_thread') {
          return 'ext_comment_thread'; // There is no old key
        }
        if (props.oldKey.includes('comment_thread')) {
          return 'root_comment_thread';
        }
      }

      // We return some very strange key that never will exist -i hope- in the
      // message thread.
      return 'never_ever_should_exist';
    }

    // If is tier 2, we have to change root|key_4|comment_thread to root_key_4_comment_thread

    if (tier === 'tier2') {
      // Check if we start with root| and end with |comment_thread
      if (
        props.oldKey.startsWith('root|') &&
        props.oldKey.endsWith('|comment_thread')
      ) {
        const generated = props.oldKey.replaceAll('|', '_');
        return generated;
      }
    }
    if (tier === 'tier1') {
      // Check if we start with root| and end with |comment_thread
      if (
        props.oldKey.startsWith('root|') &&
        props.oldKey.endsWith('|comment_thread')
      ) {
        const generated = props.oldKey.replaceAll('|', '_');
        return generated;
      }
    }
    console.warn(`Unknown tier: ${tier}`);
    // We return some very strange key that never will exist -i hope- in the
    // message thread.
    return 'never_ever_should_exist';
  }, [props.oldKey, tier]);

  const [oldMessages] = useMessageThread(props.executionId, oldMessageKey);

  const messages = useMemo(() => {
    console.log('R4');
    if (!oldMessages || oldMessages.comments.length === 0) {
      return currentMessages; // No old messages, end here
    }
    if (!currentMessages) {
      console.warn('This should not happen, but just in case');
      return undefined; // This should not happen, but just in case
    }
    // Merge old messages with new messages
    const merged: ChatThread | undefined = {
      ...currentMessages,
      comments: [...oldMessages.comments, ...currentMessages.comments].sort(
        (m1, m2) =>
          new Date(m1.publishDate).valueOf() -
          new Date(m2.publishDate).valueOf()
      ),
    };
    return merged;
  }, [oldMessages, currentMessages]);

  const [localMessages, setLocalMessages] = React.useState<
    ChatThread | undefined
  >(messages);

  React.useEffect(() => {
    setLocalMessages(messages);
  }, [JSON.stringify(messages)]);

  const onPush = async (message: string, parentId?: number, id?: number) => {
    await pushMessage(message, parentId, true, id); // Draft message
  };

  const showTitle = !props.hideTitle;
  const [addComment, setAddComment] = React.useState(true);

  const showAddComment = useMemo(
    () => !props.small || (props.small && addComment),
    [props.small, addComment]
  );

  const title = useMemo(() => {
    // If id contains 'ext_comment_thread', we will show the title
    // "Data Provider Feedback"
    if (props?.messageKey?.includes('ext_comment_thread')) {
      return 'Data provider feedback discussion';
    }

    return 'Discussion thread';
  }, [props?.messageKey]);

  return (
    <Group
      className={`rjsf-comment-thread ${props.small ? 'small' : ''}`}
      orientation='column'
    >
      {showTitle && (
        <Group className='comment-thread-title' placement='space-between'>
          <Text className='title-text ellipsis'>{title}</Text>
          <ShowMessageCount
            onClick={() => setShowMessages(!showMessages)}
            show={showMessages}
            messages={localMessages}
          />
        </Group>
      )}
      {showAddComment && (
        <>
          <Group
            className='comment-thread-messages'
            orientation='column'
            spacing='0.25em'
          >
            {props.small && (
              <ShowMessageCount
                onClick={() => setShowMessages(!showMessages)}
                show={showMessages}
                messages={localMessages}
              />
            )}
            {showMessages &&
              localMessages?.comments.map((m, i) => (
                <CommentRenderer
                  messages={localMessages.comments}
                  onPushMessage={onPush}
                  onDeleteMessage={deleteMessage}
                  message={m}
                  editable={props.editable}
                  key={m.id}
                />
              ))}
          </Group>
          {props.editable && <CommentEditor onSubmit={onPush} />}
        </>
      )}
      {props.small && (
        <ShowMessageForm
          onClick={() => setAddComment(!addComment)}
          show={addComment}
        />
      )}
    </Group>
  );
};

const ShowMessageCount = ({
  messages,
  show,
  onClick,
}: {
  messages: ChatThread | undefined;
  show: boolean;
  onClick: () => void;
}) => {
  return (
    <Text
      className='title-message-count interactive'
      onClick={onClick}
      role='button'
    >
      {show ? 'Hide' : 'Show'} {messages?.comments.length} messages
    </Text>
  );
};

const ShowMessageForm = ({
  show,
  onClick,
}: {
  show: boolean;
  onClick: () => void;
}) => {
  return (
    <Text
      className='title-message-form-show interactive'
      onClick={onClick}
      role='button'
    >
      {show ? 'Hide' : 'Show'} message form
    </Text>
  );
};

interface CommentRendererProps {
  message: Message;
  messages: Message[];
  fromParent?: number;
  editable?: boolean;
  onDeleteMessage(id: string): void;
  onPushMessage(message: string, parentId?: number, id?: any): void;
}
const CommentRenderer = (props: CommentRendererProps) => {
  const onDeleteClick = () => {
    props.onDeleteMessage(props.message.id);
  };

  const children = React.useMemo(() => {
    return props.messages.filter(
      (m) => m.parentId && m.parentId === props.message.id
    );
  }, [props.message, props.messages]);

  if (
    children.length > 0 &&
    (!props.message.parentId || props.fromParent === props.message.parentId)
  ) {
    return (
      <ParentComment
        message={props.message}
        childrenMessages={children}
        messages={props.messages}
        editable={props.editable}
        onDeleteMessage={props.onDeleteMessage}
        onPushMessage={props.onPushMessage}
      />
    );
  } else if (
    (!props.fromParent && !props.message.parentId) ||
    props.message.parentId === props.fromParent
  ) {
    return (
      <Comment
        message={props.message}
        onPushMessage={props.onPushMessage}
        onDeleteMessage={onDeleteClick}
        editable={props.editable}
      />
    );
  } else {
    return null;
  }
};

interface CommentProps {
  message: Message;
  editable?: boolean;
  onPushMessage(message: string, parentId?: number, id?: any): void;
  onDeleteMessage: VoidFunction;
}
const Comment = ({
  message,
  editable,
  onPushMessage,
  onDeleteMessage,
}: CommentProps) => {
  const [showEditor, setShowEditor] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [initialText, setInitialText] = React.useState('');

  const pushMessage = (messageContent: string) => {
    onPushMessage(
      messageContent,
      message.id,
      editMode ? message.id : undefined
    );
    if (editMode) {
      setShowEditor(false);
      setEditMode(false);
      setInitialText('');
    }
  };

  const onEditClick = () => {
    setShowEditor(true);
    setInitialText(message.content);
    setEditMode(true);
  };

  const { data: user } = useUser();

  const isMe = React.useMemo(
    () => message.author.username === user?.user?.username,
    [user, message]
  );

  const draftMessage = React.useMemo(
    () => isMe && message.isDraft === true,
    [isMe, message]
  );

  return (
    <>
      <Group
        orientation='column'
        className='rjsf-comment-thread-message'
        spacing='0px'
      >
        <Group placement='space-between' className='message-header'>
          <Group placement='start' className='message-author'>
            <>
              <Text className='author-name'>
                {message.author.username}{' '}
                {isMe && <Text className='author-name'>(You)</Text>}
              </Text>
              <Text className='author-role'>
                {message.workflowRole ?? message.author.role}
              </Text>
            </>
          </Group>
          <Group className='message-actions' placement='end'>
            <Text className='message-date'>
              {format(new Date(message.publishDate), 'yyyy-MM-dd HH:mm')}
            </Text>
            <MessageToolbar
              editable={editable}
              showEditor={showEditor}
              draftMessage={draftMessage}
              onDeleteClick={onDeleteMessage}
              onEditClick={onEditClick}
              toggleShowEditor={setShowEditor}
            />
          </Group>
        </Group>
        <Paragraph className='message-content'>
          <MultiLineRenderer>{message.content}</MultiLineRenderer>
        </Paragraph>
      </Group>
      {showEditor && editable && (
        <CommentEditor initialText={initialText} onSubmit={pushMessage} />
      )}
    </>
  );
};

interface MessageToolbarProps {
  editable?: boolean;
  showEditor: boolean;
  draftMessage: boolean;
  onDeleteClick: VoidFunction;
  onEditClick: VoidFunction;
  toggleShowEditor(show: boolean): void;
}
const MessageToolbar = (props: MessageToolbarProps) => {
  const ReplyIcon = React.useCallback(() => {
    return (
      <Tooltip content={props.showEditor ? 'Discard' : 'Reply to this message'}>
        <span>
          {props.showEditor ? (
            <Trash
              onClick={() => props.toggleShowEditor(false)}
              className='reply-icon'
              size={18}
            />
          ) : (
            <ArrowForward
              onClick={() => props.toggleShowEditor(true)}
              className='reply-icon'
              size={18}
            />
          )}
        </span>
      </Tooltip>
    );
  }, [props.showEditor, props.toggleShowEditor]);

  const EditMessageIcon = React.useCallback(() => {
    return (
      <Tooltip content='Edit message'>
        <span>
          <Pencil
            onClick={props.onEditClick}
            className='reply-icon'
            size={18}
          />
        </span>
      </Tooltip>
    );
  }, [props.onEditClick]);

  const DeleteIcon = React.useCallback(() => {
    return (
      <Tooltip content='Delete message'>
        <span>
          <Trash
            onClick={props.onDeleteClick}
            className='reply-icon'
            size={18}
          />
        </span>
      </Tooltip>
    );
  }, [props.onDeleteClick]);

  if (props.editable) {
    return !props.draftMessage ? (
      <ReplyIcon />
    ) : (
      <>
        <EditMessageIcon />
        <DeleteIcon />
      </>
    );
  }

  return null;
};

interface ParentCommentProps {
  message: Message;
  childrenMessages: Message[];
  messages: Message[];
  editable?: boolean;
  onPushMessage(message: string): void;
  onDeleteMessage(id?: any): void;
}
const ParentComment = (props: ParentCommentProps) => {
  return (
    <Group
      className='parent-message-thread-wrapper'
      orientation='column'
      spacing='0.25em'
    >
      <Comment
        message={props.message}
        onPushMessage={props.onPushMessage}
        onDeleteMessage={props.onDeleteMessage}
        editable={props.editable}
      />
      <Group
        className='parent-message-children'
        orientation='column'
        spacing='0.25em'
      >
        {props.childrenMessages.map((m, i) => (
          <CommentRenderer
            message={m}
            messages={props.messages}
            fromParent={props.message.id}
            editable={props.editable}
            onDeleteMessage={props.onDeleteMessage}
            onPushMessage={props.onPushMessage}
            key={m.id}
          />
        ))}
      </Group>
    </Group>
  );
};

interface CommentEditorProps {
  onSubmit(text: string): void;
  initialText?: string;
}
const CommentEditor = (props: CommentEditorProps) => {
  const { t } = useTranslation(['actions']);
  const [text, onChange] = useInputState(props.initialText ?? '');

  return (
    <Group
      className='comment-thread-editor'
      orientation='column'
      spacing='0.5em'
    >
      <Textarea
        className='comment-input'
        value={text}
        onChange={onChange}
        placeholder='Comment'
        rows={4}
      />
      <Row className='comment-editor-actions justify-between w-full'>
        <Column>
          <HelpText>
            {t('help:comments.save_draft')}
          </HelpText>
        </Column>
        <ButtonWithIcon
          icon={<Send />}
          onClick={(evt: React.MouseEvent) => {
            props.onSubmit(text);
            onChange('');
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          Comment
        </ButtonWithIcon>
      </Row>
    </Group>
  );
};

// root_ucs_1_comment_thread
const useMessageThreadId = (
  messageKey: string,
  editable: boolean,
  tier?: string
) => {
  const { formData, setFormData } = useRJSFormContext();
  const [id, setId] = React.useState<string>();

  React.useEffect(() => {
    if (!editable || !tier || tier !== 'eqctier3') {
      setId(messageKey);
    } else {
      // If we are in tier 3 and the key has ext_comment_thread, we will
      // use the same as in the first level.
      if (messageKey.includes('ext_comment_thread')) {
        setId(messageKey);
        return;
      }
      const path = messageKey.replace('root|', '').split('|').slice(0, -1);

      let fdId,
        obj = { ...formData };
      for (let p of path) {
        obj = obj?.[p];
      }
      if (path.length > 0) {
        if (obj && 'message_thread_id' in obj) {
          fdId = obj.message_thread_id;
        }
      } else {
        fdId = formData.message_thread_id;
      }

      if (!fdId) {
        fdId = crypto.randomUUID();
      }

      let newFormData = { ...formData };
      if (path.length > 0) {
        set(newFormData, path.concat('message_thread_id').join('.'), fdId);
      } else {
        newFormData = {
          ...newFormData,
          message_thread_id: fdId,
        };
      }

      setFormData(newFormData);
      setId(fdId);
    }
  }, [JSON.stringify(formData)]);

  return id;
};
