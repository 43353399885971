import React, { useRef } from 'react';
import Typo from 'typo-js';

const typo = new Typo('en-gb', undefined, undefined, {
  dictionaryPath: '/locales/dictionaries',
});

/**
 * Only for misspelled words
 */
type CheckFullTextRT = {
  word: string;
  suggestions: string[];
};

type CheckerCache = {
  [word: string]: {
    suggestions: string[];
  };
};

export const useSpellChecker = () => {
  const [loading, setLoading] = React.useState(false);
  const cache = useRef<CheckerCache>({});

  const hasCache = React.useCallback((word: string) => {
    return !!cache.current[word];
  }, []);

  const check = React.useCallback((word: string) => {
    if (hasCache(word)) {
      return cache.current[word].suggestions.length === 0;
    }
    if (typo.loaded) {
      return typo.check(word);
    }
    return true;
  }, []);

  const suggest = React.useCallback((word: string) => {
    if (hasCache(word)) {
      return cache.current[word].suggestions;
    }
    if (typo.loaded) {
      return typo.suggest(word);
    }
    return [];
  }, []);

  const checkFullText = React.useCallback((text: string) => {
    const start = performance.now();
    setLoading(true);

    const punctuationless = text.replaceAll(
      /[.,\/#!$%\^&\*;:{}=\-_`~()<>]/g,
      ''
    );
    const finalString = punctuationless.replaceAll(/\s{2,}/g, ' ');
    const words = finalString.split(' ');
    const result = words
      .map((word) => {
        let result: any = {
          word,
          suggestions: [],
        };
        if (hasCache(word)) {
          result = {
            word,
            suggestions: cache.current[word].suggestions,
          };
        } else {
          if (word && !check(word)) {
            result = {
              word,
              suggestions: Array.from(
                new Set(suggest(word).map((s) => s.toLocaleLowerCase()))
              ),
            };
          }

          cache.current[word] = {
            suggestions: result.suggestions,
          };
        }

        return result.suggestions.length > 0 ? result : null;
      })
      .filter((r) => !!r);

    setLoading(false);

    return result as CheckFullTextRT[];
  }, []);

  return {
    check,
    suggest,
    checkFullText,
  };
};

export const replaceSpellErrorsOnMD = (
  html: string,
  errors: CheckFullTextRT[]
) => {
  let newHtml = html;

  for (let { word } of errors) {
    newHtml = newHtml.replaceAll(
      word,
      `<span class='spell-error'>${word}</span>`
    );
  }
  return newHtml;
};

export const getWordIndexes = (searchStr: string, str: string) => {
  const searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  let startIndex = 0,
    index,
    indices = [];

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

export const replaceSpellErrorsOnWYSIWYG = (
  html: string,
  schema: any,
  transaction: any,
  dispatch: any,
  errors: CheckFullTextRT[]
) => {
  for (let { word } of errors) {
    let occs = getWordIndexes(word, html);
    for (let startIndex of occs) {
      const endIndex = startIndex + word.length;
      transaction.addMark(
        startIndex,
        endIndex + 1,
        schema.marks.customInline.create({
          htmlAttrs: { type: 'spellError', word },
        })
      );
    }
  }
  dispatch(transaction);
};
// Some text with meybe some erros or meybe no, how knosw
