import React from 'react';

import Form, { IChangeEvent } from '@rjsf/core';
import { RJSFValidationError } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { RJSF_FIELDS } from 'pages/shared/rjsf';
import { filterError } from 'pages/shared/rjsf/validateForm';
import {
  ArrayFieldTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
} from './BasicFields';

import './RJSForm.scss';
import './RJSFormPdf.scss';

interface RJSFormProps {
  schema: any;
  uiSchema: any;
  formData: any;
  disabled?: boolean;
  pdf?: boolean;
  submitButton?: React.ReactNode;
  onSubmit(formData: any): void;
  onChange(formData: any): void;
  onError?(errors: RJSFValidationError[]): void;
}

const RJSFormContext = React.createContext<{
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}>({
  formData: {},
  setFormData: () => {},
});

export const useRJSFormContext = () => React.useContext(RJSFormContext);

export const RJSForm = ({
  schema,
  uiSchema,
  formData,
  disabled = false,
  pdf = false,
  submitButton,
  onChange,
  onSubmit,
  onError,
}: RJSFormProps) => {
  const intOnSubmit: (
    data: IChangeEvent<any, any, any>,
    event: React.FormEvent<any>
  ) => void = (data, event) => {
    onSubmit(data.formData);
  };

  const transformErrors = (errors: RJSFValidationError[]) => {
    return errors.filter((err) => {
      return filterError(err, errors, schema, uiSchema, formData);
    });
  };
  
  return schema ? (
    <RJSFormContext.Provider value={{ formData, setFormData: onChange }}>
      <Form
        className={pdf ? 'rjsf-form-pdf' : ''}
        formData={formData}
        idSeparator='|'
        schema={schema}
        uiSchema={uiSchema}
        disabled={disabled}
        validator={validator}
        templates={{
          FieldTemplate,
          ObjectFieldTemplate,
          ArrayFieldTemplate,
          ErrorListTemplate: () => null,
        }}
        transformErrors={transformErrors}
        fields={RJSF_FIELDS}
        onError={onError}
        onSubmit={intOnSubmit}
        onChange={(e) => {
          if (disabled) {
            return;
          }
          onChange(e.formData);
        }}
      >
        {submitButton}
      </Form>
    </RJSFormContext.Provider>
  ) : null;
};
