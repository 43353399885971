import React from 'react';

import { Check, Circle } from 'tabler-icons-react';

import { DashoffsetAnimator, DivProps, Group, Row, Text } from 'components';

import './FancyCheckbox.scss';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';

type FancyCheckboxProps = DivProps & {
  label: string;
  description?: string;
  value: boolean;
  onChange(nextValue: boolean): void;
  className?: string;
};
export const FancyCheckbox = React.forwardRef(
  (props: FancyCheckboxProps, ref: any) => {
    return (
      <Group
        className={`fancy-checkbox ${props.className ?? ''} ${
          props.value ? 'checked' : ''
        }`}
        orientation='row'
        placement='space-between'
        tabIndex={0}
        role='checkbox'
        onClick={() => props.onChange(!props.value)}
      >
        <Group
          className='fancy-checkbox-left'
          placement='space-evenly'
          orientation='column'
          grow
        >
          <Text className='label'>{props.label}</Text>
          <Text className='description'>{props.description ?? ''}</Text>
        </Group>
        <Group className='fancy-checkbox-right'>
          <Row className='icons-wrapper'>
            <Circle className='circle-icon' size={38} strokeWidth={1} />
            <DashoffsetAnimator
              strokeDashoffset={props.value ? 0 : 24}
              duration={300}
            >
              <Check className='check-icon' />
            </DashoffsetAnimator>
          </Row>
        </Group>
      </Group>
    );
  }
);

interface FormFancyCheckboxProps
  extends Omit<FancyCheckboxProps, 'onChange' | 'value'> {
  control: Control<any>;
  name: string;
}
export const FormFancyCheckbox = React.forwardRef(
  ({ control, name, ...props }: FormFancyCheckboxProps, ref: any) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FancyCheckbox
            {...props}
            ref={ref}
            value={value as boolean}
            onChange={onChange}
          />
        )}
      />
    );
  }
);
