import { Group, Row, Spoiler, Text, TextButton } from 'components';

import { Misc } from 'domain/core/constants';

import { IFlatChange, Operation } from 'json-diff-ts';
import React from 'react';
import { Equal, EqualNot, Eye, Minus, Plus } from 'tabler-icons-react';
import {
  AssessmentImage,
  ImageFormDataI,
  QAUseCase,
  QAUseCaseAttachment,
} from 'domain/models/rjsf-fields.model';
import { MarkdownRenderer } from '../MarkdownRenderer';
import { AssessmentImageModal, CommentThread, ImageSummary } from '../rjsf';

import { CatalogProvidedInfoProxy, FormDataField } from './FormDataFields';

import './HistoryFormDataFields.scss';
import {
  DiffDataCollectionEntry,
  mergeArrayFormData,
  RenderType,
  useConcreteRenderType,
} from './utils';
import { useDispatch } from 'react-redux';
import { popModal, pushModal } from 'domain/store/root/root.actions';
import { useUseCaseAttachments, downloadUseCaseAttachment } from 'domain/swr';
import { useQarContext } from 'pages/app/qar/qar-context';

const ChangeIcon = ({ op }: { op?: Operation }) => {
  const props = {
    className: `change-icon ${op}`,
    strokeWidth: 2,
    size: 18,
  };

  switch (op) {
    case Operation.ADD:
      return <Plus {...props} />;
    case Operation.REMOVE:
      return <Minus {...props} />;
    case Operation.UPDATE:
      return <EqualNot {...props} />;
    default:
      return <Equal {...props} />;
  }
};

interface HistoryFormDataStringFieldProps {
  value: string;
  changes?: IFlatChange[];
}
export const HistoryFormDataStringField = (
  props: HistoryFormDataStringFieldProps
) => {
  const change = props.changes ? props.changes[0] : undefined;

  if (!change) {
    return (
      <Text className={`history-form-data-string-type-value EQUALS`}>
        <ChangeIcon />
        {props.value ?? '-'}
      </Text>
    );
  }

  if (change.type === Operation.ADD || change.type === Operation.REMOVE) {
    return (
      <Text className={`history-form-data-string-type-value ${change?.type}`}>
        <ChangeIcon op={change.type} />
        {props.value ?? '-'}
      </Text>
    );
  }

  if (change.type === Operation.UPDATE) {
    return (
      <Group
        orientation='row'
        placement='start'
        className='history-update-string-row'
        spacing='0.5em'
      >
        <Text className='history-form-data-string-type-value REMOVE'>
          <ChangeIcon op={Operation.REMOVE} />
          {change.oldValue}
        </Text>
        <Text className='history-form-data-string-type-value ADD'>
          <ChangeIcon op={Operation.ADD} />
          {change.value}
        </Text>
      </Group>
    );
  }

  return (
    <Text
      className={`history-form-data-string-type-value ${
        change?.type ?? 'EQUALS'
      }`}
    >
      <ChangeIcon op={change?.type} />
      {props.value ?? '-'}
    </Text>
  );
};

interface HistoryFormDataArrayFieldProps {
  values: string[];
  schema: any;
  changes?: IFlatChange[];
}
export const HistoryFormDataArrayField = (
  props: HistoryFormDataArrayFieldProps
) => {
  const valuesWithNames = React.useMemo(() => {
    if (props.schema.items?.enum && props.schema.items?.enumNames) {
      let arr: any[] = [];

      for (let val of props.values) {
        const idx = props.schema.items?.enum.findIndex((e: any) => e === val);
        const label = props.schema.items?.enumNames[idx];

        arr.push({
          value: val,
          label: label ?? val,
        });
      }

      return arr;
    }

    return props.values.map((v) => ({ value: v, label: v }));
  }, [props.schema, props.values]);

  return (
    <Group
      placement='start'
      spacing='0.5em'
      className='history-form-data-array'
    >
      {props.values.map((val, i) => {
        const change = props.changes?.find((c) => c.value === val);
        const changeAsOldValue = props.changes?.find((c) => c.oldValue === val);
        const label = valuesWithNames.find((v: any) => v.value === val);

        if (!label) {
          return null;
        }

        if (!change && !changeAsOldValue) {
          return (
            <Text
              className='history-form-data-array-value EQUALS'
              key={`${val}-${i}`}
            >
              <ChangeIcon />
              {label?.label}
            </Text>
          );
        }

        if (change) {
          const op = change.type === 'UPDATE' ? Operation.ADD : change.type;
          return (
            <Text
              className={`history-form-data-array-value ${op}`}
              key={`${val}-${i}`}
            >
              <ChangeIcon op={op} />
              {label?.label}
            </Text>
          );
        }

        if (changeAsOldValue) {
          return (
            <Text
              className={`history-form-data-array-value ${Operation.REMOVE}`}
              key={`${val}-${i}`}
            >
              <ChangeIcon op={Operation.REMOVE} />
              {label?.label}
            </Text>
          );
        }
      })}
    </Group>
  );
};

interface HistoryFormDataBoolFieldProps {
  value: boolean;
  changes?: IFlatChange[];
}
export const HistoryFormDataBoolField = (
  props: HistoryFormDataBoolFieldProps
) => {
  const change = props.changes ? props.changes[0] : undefined;

  if (!change) {
    return (
      <Text className='history-form-data-bool-value EQUALS'>
        <ChangeIcon />
        {props.value === true ? 'Yes' : 'No'}
      </Text>
    );
  }

  return (
    <Group spacing='0.5em' placement='start' className='history-form-data-bool'>
      <Text className='history-form-data-bool-value REMOVE'>
        <ChangeIcon op={Operation.REMOVE} />
        {props.value === true ? 'Yes' : 'No'}
      </Text>
      <Text className='history-form-data-bool-value ADD'>
        <ChangeIcon op={Operation.ADD} />
        {props.value === true ? 'Yes' : 'No'}
      </Text>
    </Group>
  );
};

interface HistoryFormDataMDFieldProps {
  value: string;
  changes?: IFlatChange[];
}
const HistoryFormDataMDField = (props: HistoryFormDataMDFieldProps) => {
  const [showPreview, setShowPreview] = React.useState(false);

  const [oldValue, value] = React.useMemo(() => {
    const change = props.changes ? props.changes[0] : undefined;
    if (change) {
      return [change.oldValue, change.value];
    } else {
      return [undefined, props.value];
    }
  }, [props]);

  return (
    <Group
      orientation='column'
      placement='start'
      className='history-form-data-md-field'
    >
      {showPreview ? (
        <Group placement='space-between' grow className='md-preview-group'>
          {oldValue && (
            <Group orientation='column' placement='start'>
              <Text className='md-preview-group-title'>Old value</Text>
              <MarkdownRenderer children={oldValue} />
            </Group>
          )}
          <Group orientation='column' placement='start'>
            <Text className='md-preview-group-title'>Current value</Text>
            <MarkdownRenderer children={value} />
          </Group>
        </Group>
      ) : (
        <HistoryFormDataStringField {...props} />
      )}
      <Group placement='end'>
        <TextButton onClick={() => setShowPreview(!showPreview)}>
          {showPreview ? 'Show raw value' : 'Show preview'}
          <Eye strokeWidth={1} />
        </TextButton>
      </Group>
    </Group>
  );
};

interface HistoryFormDataImagesFieldProps {
  images: string[] | string;
  schema: any;
  uiSchema: any;
  changes?: IFlatChange[];
}
export const HistoryFormDataImagesField = (
  props: HistoryFormDataImagesFieldProps
) => {
  const isMulti = props.uiSchema['ui:multi'];
  const allImages = isMulti
    ? props.schema.items['anyOf']
    : props.schema.items['oneOf'];

  const images = Array.isArray(props.images) ? props.images : [props.images];

  const Image = React.useCallback(
    ({ img, op }: { img: ImageFormDataI; op?: Operation }) => {
      return (
        <Group
          orientation='column'
          className={`qar-view-image-wrapper ${op ?? 'EQUALS'}`}
          spacing='4px'
        >
          <img
            src={img.url}
            alt={`${img.title} image`}
            className='qar-view-image'
          />
          <Group className='qar-view-image-text-wrapper'>
            <ChangeIcon op={op} />
            <Text className={`qar-view-image-text`}>{img.title}</Text>
          </Group>
        </Group>
      );
    },
    []
  );

  return (
    <Group placement='start' className='history-form-data-images-wrapper'>
      {allImages.map((img: ImageFormDataI) => {
        const change = props.changes?.find(
          (c) => c.value === (img as any).const
        );
        const op = change?.type;
        const inImages = images.find((i) => i === (img as any).const);
        const presentInChange = props.changes?.find(
          (c) =>
            c.value === (img as any).const || c.oldValue === (img as any).const
        );

        if (!inImages && !change) {
          return null;
        }

        if (change) {
          if (op === Operation.ADD || op === Operation.REMOVE) {
            const changeImg = allImages.find(
              (i: any) => i.const === change.value
            );
            return <Image img={changeImg} op={op} key={img.url} />;
          } else if (op === Operation.UPDATE) {
            const newImg = allImages.find((i: any) => i.const === change.value);
            const oldImg = allImages.find(
              (i: any) => i.const === change.oldValue
            );
            return (
              <React.Fragment key={img.url}>
                <Image img={newImg} op={Operation.ADD} />
                <Image img={oldImg} op={Operation.REMOVE} />
              </React.Fragment>
            );
          }
        } else if (inImages && !presentInChange) {
          return <Image img={img} key={img.url} />;
        }
      })}
    </Group>
  );
};

interface HistoryFormDataAssessmentImageFieldProps {
  images: AssessmentImage[];
}
const HistoryFormDataAssessmentImageField = (
  props: HistoryFormDataAssessmentImageFieldProps
) => {
  const dispatch = useDispatch();

  const onImageClick = (image: AssessmentImage) => {
    const close = () => dispatch(popModal());
    dispatch(
      pushModal(<AssessmentImageModal editable image={image} onClose={close} />)
    );
  };

  return (
    <Row className='form-data-assessment-image-wrapper'>
      {props.images.map((img) => (
        <ImageSummary
          image={img}
          key={img.key}
          onClick={() => onImageClick(img)}
        />
      ))}
    </Row>
  );
};

interface HistoryFormDataUseCaseFieldProps {
  useCases: QAUseCase[];
}
const HistoryFormDataUseCaseField = (
  props: HistoryFormDataUseCaseFieldProps
) => {
  return (
    <Group placement='start' className='form-data-use-cases'>
      {props.useCases.map((uc) => (
        <UseCase useCase={uc} key={uc.uc_key} />
      ))}
    </Group>
  );
};

// USE_CASE_IMAGE
const UseCase = ({ useCase }: { useCase: QAUseCase }) => {
  const { qar } = useQarContext();

  const [attachments] = useUseCaseAttachments(
    useCase.uc_attachments as string[],
    qar
  );

  const donwloadAttachment = (attachments: QAUseCaseAttachment) => {
    downloadUseCaseAttachment(attachments);
  };

  return (
    <Group
      className='use-case'
      orientation='column'
      placement='start'
      spacing='0.5em'
      key={useCase.uc_key}
    >
      <Text className='use-case-title'>{useCase.uc_title}</Text>
      <Spoiler maxHeight={115} className='use-case-comment'>
        {useCase.uc_comment}
      </Spoiler>
      <Group
        orientation='column'
        placement='start'
        className='use-case-attachments'
        spacing='0.5em'
      >
        <Text className='use-case-attachment-title'>Attachments</Text>
        <Group orientation='column' placement='start' spacing='0.25em'>
          {attachments.map((att) => {
            return (
              <Text
                className='use-case-attachment'
                key={att.attachmentId}
                onClick={() => donwloadAttachment(att)}
              >
                {att.name}
              </Text>
            );
          })}
        </Group>
      </Group>
    </Group>
  );
};

export const HistoryFormDataReviewField = (props: DiffDataCollectionEntry) => {
  const type = useConcreteRenderType(
    props.schema.data_provider_input,
    props.uiSchema
  );

  const ValueComponent = React.useCallback(() => {
    const dataSchema = props.schema.data_provider_input;
    const changes = (props.changes ?? []).filter(
      (c) => c.key === 'data_provider_input'
    );

    return (
      <HistoryFormDataFieldValueRenderer
        schema={dataSchema}
        formData={props.formData.data_provider_input}
        newFormData={props.newFormData.data_provider_input}
        path={props.path}
        schemaPath={props.schemaPath}
        uiSchema={props.uiSchema}
        changes={changes}
      />
    );
  }, [props]);

  const { color_score, scoreChanges } = React.useMemo(() => {
    const { color_score } = props.formData;

    const scoreChanges = props.changes?.filter((c) => c.key === 'color_score');

    return {
      color_score,
      scoreChanges,
    };
  }, [props]);

  return (
    <FormDataField
      type={type}
      className='history-form-data-review-field'
      title={props.schema.data_provider_input.title}
      description={props.schema.data_provider_input.description}
    >
      <CatalogProvidedInfoProxy
        catalogInfo={props.formData?.catalogue_provided_input}
        dataProviderInput={props.formData?.data_provider_input}
        qualityCheck={props.formData?.quality_check}
      />
      <ValueComponent />
      <HistoryReviewComment score={color_score} scoreChanges={scoreChanges} />
    </FormDataField>
  );
};

interface HistoryReviewCommentProps {
  scoreChanges?: IFlatChange[];
  score?: number;
}
const HistoryReviewComment = (props: HistoryReviewCommentProps) => {
  const Score = React.useCallback(() => {
    const scoreEntry = Misc.ReviewColors[(props.score ?? 0) - 1];

    const change = props.scoreChanges ? props.scoreChanges[0] : undefined;
    if (!change) {
      return (
        <Text
          className='review-score'
          style={{ backgroundColor: scoreEntry?.color }}
        >
          {scoreEntry?.text}
        </Text>
      );
    } else {
      if (change.type === Operation.UPDATE) {
        const { value, oldValue } = change;

        const entry = Misc.ReviewColors[value - 1],
          oldEntry = Misc.ReviewColors[oldValue - 1];

        return (
          <Text className='review-score update'>
            Score from{' '}
            <Text
              className='review-score-text'
              style={{ backgroundColor: oldEntry?.color }}
            >
              {oldEntry?.text ?? '-'}
            </Text>{' '}
            to{' '}
            <Text
              className='review-score-text'
              style={{ backgroundColor: entry?.color }}
            >
              {entry?.text ?? '-'}
            </Text>
          </Text>
        );
      } else if (
        change.type === Operation.ADD ||
        change.type === Operation.REMOVE
      ) {
        const entry = Misc.ReviewColors[change.value - 1];
        const opCls = change.type === Operation.ADD ? 'add' : 'remove';
        return (
          <Text
            className={`review-score ${opCls}`}
            style={{ backgroundColor: entry?.color }}
          >
            <ChangeIcon op={change.type} />
            <Text className='review-score-text'>{entry?.text ?? '-'}</Text>
          </Text>
        );
      }
    }

    return null;
  }, [props.score, props.scoreChanges]);

  if (!props.score && !((props.scoreChanges?.length ?? 0) > 0)) {
    return null;
  }

  return (
    <fieldset className='rjsf-fix-review-comment'>
      <legend className='review-comment-label'>Reviewer said:</legend>
      <Score />
    </fieldset>
  );
};

export const HistoryFormDataCommentThreadField = (
  props: DiffDataCollectionEntry
) => {
  return (
    <CommentThread
      oldKey='-'
      messageKey={props.formData}
      executionId=''
      onChange={() => {}}
      editable={false}
    />
  );
};

export const HistoryFormDataFieldValueRenderer = (
  props: DiffDataCollectionEntry
) => {
  const type = useConcreteRenderType(props.schema, props.uiSchema);

  switch (type) {
    case RenderType.String:
    case RenderType.Number:
      return (
        <HistoryFormDataStringField
          changes={props.changes}
          value={props.formData}
        />
      );
    case RenderType.Array: {
      let fd1 = Array.isArray(props.formData)
        ? props.formData
        : [props.formData];
      let fd2 = Array.isArray(props.newFormData)
        ? props.newFormData
        : [props.newFormData];
      const values = mergeArrayFormData(fd1, fd2);

      return (
        <HistoryFormDataArrayField
          values={values}
          schema={props.schema}
          changes={props.changes}
        />
      );
    }
    case RenderType.Boolean: {
      return (
        <HistoryFormDataBoolField
          value={props.formData}
          changes={props.changes}
        />
      );
    }
    case RenderType.MD:
      return (
        <HistoryFormDataMDField
          value={props.formData}
          changes={props.changes}
        />
      );

    case RenderType.Image:
      return (
        <HistoryFormDataImagesField
          images={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          changes={props.changes}
        />
      );

    case RenderType.AssessmentImage:
      return <HistoryFormDataAssessmentImageField images={props.formData} />;
    case RenderType.UseCase:
      return <HistoryFormDataUseCaseField useCases={props.formData} />;
    default:
      return null;
  }
};
