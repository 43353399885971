import { RJSFValidationError } from '@rjsf/utils';

type GenFilterFn = (
  error: RJSFValidationError,
  errors: RJSFValidationError[],
  schema: any,
  uiSchema: any,
  formData: any
) => boolean;

type FilterFn = (
  error: RJSFValidationError,
  errors: RJSFValidationError[],
  schema: any,
  uiSchema: any,
  formData: any,
  schemaObj: any,
  uiSchemaObj: any
) => boolean;

export const filterError: GenFilterFn = (
  error,
  errors,
  schema,
  uiSchema,
  formData
): boolean => {
  const schemaObj = getSchemaObject(error, schema);
  const uiSchemaObj = getUISchemaObject(error, uiSchema);

  switch (error.message) {
    case 'should be equal to one of the allowed values': {
      return filterAdditionalItemsError(
        error,
        errors,
        schema,
        uiSchema,
        formData,
        schemaObj,
        uiSchemaObj
      );
    }
  }

  if (error.property?.includes('comment_thread')) {
    return false;
  }

  return true;
};

const getSchemaObject = (error: RJSFValidationError, schema: any) => {
  if (!schema) {
    return undefined;
  }

  let schemaPath: string[] = (error as any).schemaPath?.split('/') ?? [];
  schemaPath = schemaPath.slice(1, schemaPath.length - 1);

  let schemaObj = schema;

  for (let prop of schemaPath) {
    schemaObj = schemaObj[prop];
    if (!schemaObj) {
      break;
    }
  }

  return schemaObj;
};

const getUISchemaObject = (error: RJSFValidationError, uiSchema: any) => {
  if (!uiSchema) {
    return undefined;
  }

  let schemaPath: string[] = (error as any).schemaPath?.split('/') ?? [];
  schemaPath = schemaPath.slice(1, schemaPath.length - 1);
  const uiSchemaPath = schemaPath.filter((p) => p !== 'properties');

  let uiSchemaObj = uiSchema;
  for (let prop of uiSchemaPath) {
    uiSchemaObj = uiSchemaObj[prop];
    if (!uiSchemaObj) {
      break;
    }
  }

  return uiSchemaObj;
};

const filterAdditionalItemsError: FilterFn = (
  _,
  __,
  ___,
  ____,
  _____,
  ______,
  uiSchemaObj
) => {
  if (uiSchemaObj && uiSchemaObj.additionalItems) {
    return false;
  }
  return true;
};
