import React, { useState } from 'react';

import cn from 'classnames';
import './ButtonLike.scss';

export const ButtonLike = (props: any) => {
  const { children, className, ...rest } = props;
  return (
    <div className={cn('button-like text-sm py-0 mx-1', className)} { ...rest }>
      {children}
    </div>
  );
};
