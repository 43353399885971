export const merge = (target_in: any, source_in: any): any => {
  const isObject = (item: any) =>
    item && typeof item === 'object' && !Array.isArray(item);

  let target = isObject(target_in) ? target_in : {};
  let source = isObject(source_in) ? source_in : {};

  const output: any = { ...target };

  Object.keys(source).forEach((key) => {
    if (isObject(source[key])) {
      if (!(key in target)) {
        Object.assign(output, { [key]: source[key] });
      } else {
        output[key] = merge(target[key], source[key]);
      }
    } else {
      Object.assign(output, { [key]: source[key] });
    }
  });
  return output;
};
