import React from 'react';

import { Link } from 'react-router-dom';
import { AlertCircle, Refresh } from 'tabler-icons-react';

import { Group, Text, TextButton } from 'components';

import './CommonErrors.scss';

interface DetailLoadErrorProps {
  entity: string;
  listPath: string;
}
export const DetailLoadError = (props: DetailLoadErrorProps) => {
  return (
    <Group>
      <Group orientation='column' className='detail-load-error'>
        <AlertCircle size={48} className='alert-icon' strokeWidth={1} />
        <Text className='detail-load-error-message'>
          An error has occurred loading{' '}
          <Text className='entity'>{props.entity}</Text> details.
        </Text>
        <Link className='link' to={props.listPath}>
          Go back to the list
        </Link>
      </Group>
    </Group>
  );
};

interface ListLoadErrorProps {
  entity: string;
  onRetry?: VoidFunction;
}
export const ListLoadError = (props: ListLoadErrorProps) => {
  return (
    <Group>
      <Group orientation='column' className='list-load-error'>
        <AlertCircle size={48} className='alert-icon' strokeWidth={1} />
        <Text className='list-load-error-message'>
          An error has occurred loading{' '}
          <Text className='entity'>{props.entity}</Text>.
        </Text>
        <Text className='hint'>
          If this error persists, please contact the system administrators.
        </Text>
        {props.onRetry && (
          <TextButton onClick={props.onRetry}>
            Retry <Refresh size={18} />
          </TextButton>
        )}
      </Group>
    </Group>
  );
};
