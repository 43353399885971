import { Api } from "domain/core";
import { useEffect, useState } from "react";

export const useNotebookData = (
  notebookUrl: string,
  page: number,
  size: number,
): {
  notebook: any;
  isLoading: boolean;
} => {

  const url = `${Api.baseUrl}/notebooks?notebook=${notebookUrl}&size=${size}&page=${page}`;

  const [loading, setLoading] = useState(true);
  
  const [notebook, setNotebook] = useState<any>({});
  
  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setNotebook(data);
      }).catch((err) => {
        setLoading(false);
      });
  }, [url]);
  
  return {
    notebook: notebook,
    isLoading: loading,
  };
};
