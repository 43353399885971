import React from 'react';

import useSWR, { mutate } from 'swr';
import { useDispatch } from 'react-redux';

import { Api } from 'domain/core';
import { UserInfo } from 'domain/models';
import { AuthorizationService } from 'domain/services';
import { authorizedService } from 'domain/services/authorized-service.base';
import { loginSuccess } from 'domain/store/auth';
import { rawFetcher } from './fetcher';

const useRedirectURI = () => {
  // Get continue query param from url
  const urlParams = new URLSearchParams(window.location.search);
  const continueParam = urlParams.get('continue');

  return `${window.location.origin}/auth/post-login${encodeURIComponent(
    `?continue=${continueParam || '/app'}`
  )}`;
};

export const useLogin = () => {
  const redirectURI = useRedirectURI();
  const providerURL = `${Api.baseUrl}/auth?no_redirect=true&redirect_uri=${redirectURI}`;
  const [loginURL, setLoginURL] = React.useState<string | null>(null);
  React.useEffect(() => {
    fetch(providerURL)
      .then((r) => r.json())
      .then((r) => setLoginURL(r.login_url));
  }, []);
  return loginURL;
};

export const useAuthentication = (
  code: string | null,
  session_state: string | null
) => {
  const dispatch = useDispatch();

  const redirectURI = useRedirectURI();
  const [error, setError] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!code || !session_state) {
      return;
    }
    const providerURL = `${Api.baseUrl}/post-login?code=${code}&session_state=${session_state}&redirect_uri=${redirectURI}`;
    fetch(providerURL)
      .then((r) => (r.status === 200 ? r.json() : null))
      .then((r) => {
        if (r) {
          // Authorize application
          dispatch(loginSuccess(r));
          AuthorizationService.saveAuthorization(r);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [code]);
  return {
    error,
  };
};

export const useMagicAuthentication = (
  executionId: string | null,
  key: string | null
) => {
  const dispatch = useDispatch();

  const [error, setError] = React.useState<boolean>(false);
  const [continuationUrl, setContinuationUrl] = React.useState<string>();
  React.useEffect(() => {
    if (!executionId || !key) {
      return;
    }
    const providerURL = `${Api.baseUrl}/magic-auth?executionId=${executionId}&key=${key}`;
    fetch(providerURL)
      .then((r) => (r.status === 200 ? r.json() : null))
      .then((r) => {
        if (r) {
          // Authorize application
          dispatch(loginSuccess(r));
          AuthorizationService.saveAuthorization(r);
          setContinuationUrl(r.continuationUrl);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [executionId, key]);

  return {
    error,
    continuationUrl,
  };
};


export const useLogout = () => {
  const { refresh_token } = AuthorizationService.safeGetAuthorization() || {};
  const providerURL = `${Api.baseUrl}/auth/logout?refresh_token=${refresh_token}`;
  const [isLoggedOut, setIsLoggedOut] = React.useState<boolean>(false);
  React.useEffect(() => {
    try {
      authorizedService
        .fetch(providerURL)
        .then(() => AuthorizationService.removeAuthorization())
        .then(() => setIsLoggedOut(true))
        .catch(() => setIsLoggedOut(true));
    } catch (e) {
      setIsLoggedOut(true);
    }
  }, []);
  return isLoggedOut;
};

export const useUser = () => {
  const { data, error } = useSWR<UserInfo>(`${Api.baseUrl}${Api.auth.me}`);
  return {
    data,
    error,
  };
};

export const useLoggedIn = () => {
  /* Check if useUser reports error */
  const { data, error } = useUser();
  return !error;
};

export const updateProfile = (profile: Partial<UserInfo>): Promise<any> => {
  return rawFetcher(`${Api.baseUrl}${Api.auth.me}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  }).then((r) => {
    mutate(`${Api.baseUrl}${Api.auth.me}`);
    return r.json();
  });
};
