import React, { useMemo } from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { Button, Column, FileInput, Group, Row, Text } from 'components';
import { MarkdownRenderer } from '../MarkdownRenderer';
import { triggerLinkClick } from 'domain/utils';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';
import { readOnlyFromProps } from './utils';

import './JupyterEditorControl.scss';

export const JupyterEditorControl: Field = (props: FieldProps) => {
  const { formData = '', uiSchema = {} } = props;

  const isEmpty = useMemo(
    () => Object.keys(formData).length === 0 && !formData.nb,
    [formData]
  );

  const readOnly = readOnlyFromProps(props);

  const onDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (readOnly) {
      return;
    }
    props.onChange({});
  };

  return (
    <Column className='jupyter-renderer-wrapper'>
      {!isEmpty && (
        <ControlJupyter
          formData={formData}
          readOnly={readOnly}
          onDelete={onDelete}
        />
      )}
      {isEmpty && (
        <Row className='jupyter-renderer__no-notebook'>
          No jupyter notebook has been uploaded. Please, add one.
        </Row>
      )}
      {isEmpty ? (
        <JupyterUpload
          onChange={(value) => {
            props.onChange({
              ...formData,
              nb: value,
            });
          }}
        />
      ) : (
        <JupyterCommentRenderer
          data={formData.nb}
          comments={formData.comments}
        />
      )}
    </Column>
  );
};

export const ControlJupyter = ({
  formData,
  readOnly,
  onDelete,
}: {
  formData: any;
  readOnly: boolean;
  onDelete(e: any): void;
}) => {
  const download = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // Create a blob in order to download the file
    const blob = new Blob([JSON.stringify(formData.nb || {})], {
      type: 'application/json',
    });
    const asUrl = URL.createObjectURL(blob);
    triggerLinkClick(asUrl, 'notebook-cim.ipynb');
    // Revoke the url
    URL.revokeObjectURL(asUrl);
  };

  return (
    <Row className='jupyter-renderer__download'>
      {readOnly ? (
        <span />
      ) : (
        <Button onClick={onDelete}>Delete notebook &times;</Button>
      )}
      <Button onClick={download}>Download notebook &darr;</Button>
    </Row>
  );
};

export const JupyterCommentRenderer = ({
  data,
  comments,
}: {
  data: any;
  comments: any[];
}) => {
  const cols = useMemo(() => jupyterToCells(data), [data]);

  return (
    <Column className='jupyter-renderer'>
      {cols.map((col, i) => (
        <CellRenderer col={col} i={i} key={`jupyter-renderer-${i}`} />
      ))}
    </Column>
  );
};

const CellRenderer = ({ col, i }: { col: JupyterCell; i: number }) => {
  const { task } = useTaskSchemaContext();

  const { outputs } = col;

  const outputsChild = useMemo(() => {
    return (outputs || []).map((output, i) => {
      if (output.type === 'text') {
        return (
          <MarkdownCellRenderer
            data={output.text!}
            key={`output-renderer-${i}`}
          />
        );
      } else if (output.type === 'code') {
        return (
          <CodeRenderer data={output.text!} key={`output-renderer-${i}`} />
        );
      } else if (output.type === 'image') {
        return (
          <ImageRenderer data={output.image!} key={`output-renderer-${i}`} />
        );
      } else if (output.type === 'image-svg') {
        return (
          <SVGRenderer data={output.image!} key={`output-renderer-${i}`} />
        );
      } else if (output.type === 'error') {
        return (
          <ErrorCellRenderer data={output.text!} key={`output-renderer-${i}`} />
        );
      } else if (output.type === 'empty') {
        return (
          <Text key={`output-renderer-${i}`} className='empty-text-render'>
            No output
          </Text>
        );
      }
    });
  }, [outputs]);

  return (
    <Row className='cell-renderer'>
      <Column className='cell-renderer-label'>
        <Text>[{i}]</Text>
      </Column>
      <Column className='cell-renderer-content'>
        <Row className='cell-renderer-content-wrapper'>{outputsChild}</Row>
      </Column>
    </Row>
  );
};

const ErrorCellRenderer = ({ data }: { data: string }) => {
  return <MarkdownRenderer className='error-md' children={data} />;
};

const MarkdownCellRenderer = ({ data }: { data: string }) => {
  return <MarkdownRenderer children={data} />;
};

const CodeRenderer = ({ data }: { data: string }) => {
  return (
    <div className='code-entry'>
      <pre>{data}</pre>
    </div>
  );
};

const ImageRenderer = ({ data }: { data: string }) => {
  // Data is base64 encoded png, so we can just use it as the src
  const src = `data:image/png;base64,${data}`;
  return (
    <div className='image-entry'>
      <img src={src} width={500} />
    </div>
  );
};

const SVGRenderer = ({ data }: { data: string }) => {
  // Data is base64 encoded png, so we can just use it as the src
  return (
    <div className='image-entry'>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export const JupyterUpload = ({
  onChange,
}: {
  onChange: (value: any) => void;
}) => {
  const readJson = (files: File[]) => {
    // Check if the file is a Jupyter notebook
    const file = files[0];
    // Read as json
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target?.result as string);
        onChange(json);
      } catch (e) {
        console.error(e);
      }
    };

    reader.readAsText(file);
  };

  return (
    <Column className='jupyter-upload'>
      <FileInput
        className='jupyter-upload-file-input'
        multiple
        onFileDrop={(files) => {
          readJson(files);
        }}
      >
        <Group
          orientation='column'
          placement='start'
          spacing='0.5em'
          className='jupyter-upload-file-list'
        >
          <Text>Click here or drag and drop a Jupyter notebook</Text>
        </Group>
      </FileInput>
    </Column>
  );
};

type JupyterOutput = {
  type: 'image' | 'text' | 'empty' | 'error' | 'code' | 'image-svg';
  text?: string;
  image?: string;
};

type JupyterCell = {
  cell_type: 'markdown' | 'code';
  outputs: JupyterOutput[];
};

const jupyterToCells = (jupyter: any): JupyterCell[] => {
  // This depends on nbformat
  const version = jupyter.nbformat;
  if (version === 4) {
    return extractCellsV4(jupyter);
  }
  return [
    {
      cell_type: 'markdown',
      outputs: [
        {
          type: 'text',
          text: `Unsupported notebook version ${version}`,
        },
      ],
    },
  ];
};

/**
 * V4 notebook has .cells
 */
const extractCellsV4 = (jupyter: any): JupyterCell[] => {
  return jupyter.cells.map((cell: any) => {
    return {
      cell_type: cell.cell_type,
      outputs:
        (cell.cell_type === 'code'
          ? map_cell_code(cell)
          : [
              map_outputs({
                output_type: 'stream',
                text: cell.source,
              }),
            ]) ?? [],
    };
  });
};

const map_cell_code = (cell: any): JupyterOutput[] => {
  const outputs = cell.outputs?.map((ou: any) => map_outputs(ou)) ?? [];

  // Show the code
  return [
    {
      type: 'code',
      text: cell.source.join('\n'),
    },
    ...outputs,
  ];
};

function map_outputs(output: any): JupyterOutput {
  if (output.output_type === 'stream') {
    return {
      type: 'text',
      text: output.text.join('\n'),
    };
  }

  if (output.output_type === 'error') {
    return {
      type: 'error',
      text: extract_traceback(output),
    };
  }

  if (output.output_type === 'text') {
    return {
      type: 'error',
      text: extract_traceback(output),
    };
  }

  if (output.output_type === 'execute_result') {
    return resolve_display_data(output);
  }

  if (output.output_type === 'display_data') {
    return resolve_display_data(output);
  }

  return {
    type: 'text',
    text: JSON.stringify(output, null, 2),
  };
}

/**
 * Tracebacks are formatted with color codes, so we need to strip them out to get the raw text
 * @param output
 */
function extract_traceback(output: any): string | undefined {
  if (!output || !output.traceback) {
    return '[No traceback]';
  }

  return output.traceback.join('\n').replace(/\x1b\[[0-9;]*m/g, '');
}
function resolve_display_data(output: any): JupyterOutput {
  // Check if we have an image
  if (output.data && output.data['image/png']) {
    return {
      type: 'image',
      image: output.data['image/png'],
    };
  }

  if (output.data && output.data['image/svg+xml']) {
    return {
      type: 'image-svg',
      image: output.data['image/svg+xml'],
    };
  }

  // Check if we have text
  if (output.data && output.data['text/plain']) {
    return {
      type: 'text',
      text: output.data['text/plain'].join('\n'),
    };
  }

  // Check if we have html
  if (output.data && output.data['text/html']) {
    return {
      type: 'text',
      text: 'HTML',
    };
  }

  // Return a warning
  return {
    type: 'text',
    text: '[Unsupported output]',
  };
}
