import React from 'react';
import { useDispatch } from "react-redux";


import { ButtonWithIcon, Group } from 'components';

import {
  FormDataCommentThreadField,
  FormDataField,
  FormDataFieldValueRenderer,
  FormDataGroup,
  FormDataReviewField,
} from './FormDataFields';

import { useConfirmationModal } from "components/ConfirmationModal";
import { DataCollectionEntry, followPath, getDataCollection, getUiField, useConcreteRenderType, } from './utils';
import { RjsfCustomField } from '../rjsf';
import { usePullRequestQAR } from "domain/swr";
import { pushNotification } from "domain/store/root";
import { GitPullRequest } from "tabler-icons-react";
import { useQarContext } from "../../app/qar/qar-context";
import './FormDataViewer.scss';
import { RoleGuard } from '../RoleGuard';
import { Entity } from 'domain/models';

interface FormDataViewerProps {
  formData: any;
  schema: any;
  uiSchema: any;
}
export const FormDataViewer = (props: FormDataViewerProps) => {
  const dispatch = useDispatch()
  const { qar } = useQarContext();
  const promisePullRequest = usePullRequestQAR();
  const [dataColl, groups] = React.useMemo(
    () => getDataCollection(props),
    [props]
  );

  const getGroupLabel = React.useCallback(
    (group: string) => {
      const schema = followPath(`properties.${group}`, props.schema);
      return schema ? schema.title : '';
    },
    [props]
  );

  const getGroupChildren = React.useCallback(
    (group: string) => {
      return dataColl.filter((dc) => dc.path.startsWith(group));
    },
    [dataColl]
  );

  return (
    <Group
      className='form-data-viewer'
      orientation='column'
      placement='start'
      spacing='5em'
    >
      {groups && groups.length > 1
        ? groups.map((group, i) => {
          const name = getGroupLabel(group);
          const children = getGroupChildren(group);

          return (
            <FormDataGroup title={name} key={`${name}-${i}`}>
              {children.map((dc) => (
                <FormDataFieldProxy {...dc} key={dc.path} />
              ))}
            </FormDataGroup>
          );
        })
        : dataColl.map((dc) => <FormDataFieldProxy {...dc} key={dc.path} />)}
      <Group placement='end'>

      </Group>
    </Group>
  );
};

const FormDataFieldProxy = (props: DataCollectionEntry) => {
  const parentField = React.useMemo(() => {
    return getUiField(props.uiSchema);
  }, [props.uiSchema]);

  switch (parentField) {
    case RjsfCustomField.FixReviewField:
    case RjsfCustomField.CatalogueProvided:
    case RjsfCustomField.ReviewField: {
      return <FormDataReviewField {...props} />;
    }
    case RjsfCustomField.CommentThread: {
      return <FormDataCommentThreadField {...props} />;
    }
    default:
      return <FormDataFieldRenderer {...props} />;
  }
};

export const FormDataFieldRenderer = ({
  formData,
  path,
  schema,
  schemaPath,
  uiSchema,
}: DataCollectionEntry) => {
  const type = useConcreteRenderType(schema, uiSchema);

  const coerceToString = (prop: any) => {
    if (typeof prop === 'string') {
      return prop;
    }
    return '';
  };

  return (
    <FormDataField
      title={coerceToString(schema.title)}
      description={coerceToString(schema.description)}
      type={type}
    >
      <FormDataFieldValueRenderer
        schema={schema}
        formData={formData}
        uiSchema={uiSchema}
        path={path}
        schemaPath={schemaPath}
      />
    </FormDataField>
  );
};
