import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { Group, Input, Text } from 'components';
import { Config } from 'domain/core/constants';
import { ReferencesList, extractReferences } from './ReferencesField';
import { References } from 'domain/models';

import './MarkdownEditorControl.scss';
import { readOnlyFromProps } from './utils';
import { MDEditor2 } from 'components/md-editor-2/MDEditor2';

export const MarkdownEditorControl: Field = (props: FieldProps) => {
  const { formData = '', uiSchema = {} } = props;

  return (
    <MDEditor2
      data={formData}
      maxLength={Config.MAX_WORDS_TEXTAREA}
      onChange={(data) => props.onChange(data)}
      readOnly={uiSchema['ui:readOnly']}
    />
  );
};

export const MarkdownEditorWithReferencesControl: Field = (
  props: FieldProps
) => {
  const {
    formData = {
      content: '',
      references: [],
    },
    uiSchema = {},
  } = props;

  const intOnChange = (content: string) => {
    const refs = extractReferences(content);
    const merged = mergeRefs(refs, formData.references ?? []);
    props.onChange({ references: merged, content });
  };

  const onRefsChange = (refs: References.Reference[]) => {
    props.onChange({
      ...formData,
      references: refs,
    });
  };

  return (
    <Group orientation='column' className='md-editor-with-references'>
      <MDEditor2
        data={formData.content ?? ''}
        maxLength={Config.MAX_WORDS_TEXTAREA}
        onChange={intOnChange}
        readOnly={readOnlyFromProps(props)}
      />
      <ReferencesList
        schema={props.schema?.properties?.references}
        onChange={onRefsChange}
        references={formData.references ?? []}
        disabled={readOnlyFromProps(props)}
      />
    </Group>
  );
};

interface ReferencesListProps {
  references: string[];
  disabled?: boolean;
  onChange(references: string[]): void;
}
const _ReferencesList = (props: ReferencesListProps) => {
  const onRefChange = (content: string, index: number) => {
    const newRefs = props.references.map((ref, i) => {
      if (index === i) {
        return content;
      }
      return ref;
    });
    props.onChange(newRefs);
  };

  return (
    <Group
      orientation='column'
      className='md-editor-references'
      spacing='0.25em'
      placement='start'
    >
      {props.references && props.references.length !== 0 && (
        <Text className='title'>References</Text>
      )}
      {Object.keys(props.references).map((index) => {
        const numIndex = parseInt(index);
        const ref = props.references[numIndex];

        return (
          <Group placement='start' key={index} className='reference'>
            <Text className='reference-index'>[ {numIndex + 1} ]</Text>
            {props.disabled ? (
              <Text className='reference-content'>{ref}</Text>
            ) : (
              <Input
                value={ref ?? ''}
                className='reference-input'
                onChange={({ target }) => onRefChange(target.value, numIndex)}
              />
            )}
          </Group>
        );
      })}
    </Group>
  );
};

const _extractReferences = (content: string) => {
  const refPattern = new RegExp(/\[\d+\]/g);
  const digitPattern = new RegExp(/\d+/g);

  const matches = content.matchAll(refPattern);

  let refs = [];

  let lastRef = 0; // Ensure monotonically increasing references
  let maxRef = 0; // Ensure no references are skipped
  for (let match of matches) {
    const ref = match[0];
    if (ref) {
      const digit = ref.match(digitPattern);
      if (digit && digit.length === 1) {
        const digNum = parseInt(digit[0]);
        if (digNum < 1 || digNum > 10) {
          continue;
        }

        if (lastRef + 1 !== digNum || digNum < maxRef) {
          continue;
        }
        if (lastRef + 1 === digNum) {
          lastRef = digNum;
        }
        maxRef = Math.max(maxRef, digNum);

        refs[digNum - 1] = ''; // Arrays starts at 0, but references starts at 1
      }
    }
  }

  return refs;
};

const mergeRefs = (
  newRefs: References.Reference[],
  oldRefs: References.Reference[]
) => {
  const newRefsIndexes = Object.keys(newRefs).map((si) => parseInt(si));

  let result = newRefs;

  for (let index of newRefsIndexes) {
    const oldR = oldRefs[index];
    if (oldR) {
      result[index] = oldR;
    }
  }

  return result;
};
