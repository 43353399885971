import React from 'react';

import { ErrorBoundary, Group } from 'components';

import { FormDataField, FormDataGroup } from './FormDataFields';
import {
  HistoryFormDataCommentThreadField,
  HistoryFormDataFieldValueRenderer,
  HistoryFormDataReviewField,
} from './HistoryFormDataFields';

import { useQAR, useQARSchemas } from 'domain/swr';
import {
  DiffDataCollectionEntry,
  followPath,
  getDiffDataCollection,
  getUiField,
  useConcreteRenderType,
} from './utils';

import './HistoryViewer.scss';
import { RjsfCustomField } from '../rjsf';

interface HistoryViewerProps {
  baseQarId: number;
  compareQarId: number;
}
export const HistoryViewer = (props: HistoryViewerProps) => {
  const baseQar = useQAR(props.baseQarId);
  const compareQar = useQAR(props.compareQarId);

  const baseQarSchema = useQARSchemas(props.baseQarId);
  const compareQarSchema = useQARSchemas(props.compareQarId);

  const [diffData, groups] = React.useMemo(
    () =>
      getDiffDataCollection({
        sourceFormData: baseQar.data,
        sourceSchema: baseQarSchema.schema,
        sourceUiSchema: baseQarSchema.ui_schema,
        newFormData: compareQar.data,
        newSchema: compareQarSchema.schema,
        newUiSchema: compareQarSchema.ui_schema,
      }),
    [baseQar, baseQarSchema, compareQar, compareQarSchema]
  );

  const getGroupLabel = React.useCallback(
    (group: string) => {
      const schema = followPath(`properties.${group}`, compareQarSchema.schema);
      return schema ? schema.title : '';
    },
    [props, compareQarSchema]
  );

  const getGroupChildren = React.useCallback(
    (group: string) => {
      return diffData.filter((dc) => dc.path.startsWith(group));
    },
    [diffData]
  );

  return (
    <Group
      className='form-data-viewer history-viewer'
      orientation='column'
      placement='start'
      spacing='5em'
    >
      {groups.map((group, j) => {
        const name = getGroupLabel(group);
        const children = getGroupChildren(group);

        return (
          <FormDataGroup title={name} key={`${name}-${j}`}>
            {children.map((dc, i) => (
              <ErrorBoundary fallback={<p>Unable to render {JSON.stringify(dc.uiSchema)}</p>}>
                <HistoryFormDataFieldProxy {...dc} key={`${dc.path}-${i}`} />
              </ErrorBoundary>
            ))}
          </FormDataGroup>
        );
      })}
    </Group>
  );
};

const HistoryFormDataFieldProxy = (props: DiffDataCollectionEntry) => {
  const parentField = React.useMemo(
    () => getUiField(props.uiSchema),
    [props.uiSchema]
  );
  switch (parentField) {
    case RjsfCustomField.FixReviewField:
    case RjsfCustomField.ReviewField:
    case RjsfCustomField.CatalogueProvided:
      return <HistoryFormDataReviewField {...props} />;
    case RjsfCustomField.CommentThread:
      return <HistoryFormDataCommentThreadField {...props} />;
    default:
      return <HistoryFormDataFieldRenderer {...props} />;
  }
};

export const HistoryFormDataFieldRenderer = (
  props: DiffDataCollectionEntry
) => {
  const type = useConcreteRenderType(props.schema, props.uiSchema);

  const coerceToString = (prop: any) => {
    if (typeof prop === 'string') {
      return prop;
    }
    return '-';
  };

  return (
    <FormDataField
      title={coerceToString(props.schema.title)}
      description={coerceToString(props.schema.description)}
      type={type}
    >
      <HistoryFormDataFieldValueRenderer {...props} />
    </FormDataField>
  );
};
