import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { format } from 'date-fns';

import { DateTimePicker } from 'components';

import './DatePickerControl.scss';

export const DatePickerControl: Field = (props: FieldProps) => {
  return (
    <DateTimePicker
      value={props.formData}
      disabled={props.uiSchema?.['ui:readOnly'] ?? props.disabled}
      onChange={(date) => props.onChange(format(date!, 'dd-MM-yyyy'))}
      name={props.name}
    />
  );
};
