import React, { PropsWithChildren } from 'react';

export const MultiLineRenderer = ({ children }: PropsWithChildren<{}>) => {

  // If children is not a string, return it as is
  if (typeof children !== 'string') {
    return <>{children}</>;
  }

  const lines = children.split('\n');
  
  return (
    <>
      {lines
        .map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
    </>
  );
};
