import React from 'react';

import { AlertTriangle, Check, X } from 'tabler-icons-react';

import { Column, ErrorBoundary, Group, Link, Row, Text } from 'components';

import './Tier2Index.scss';
import { QualityCheck, qualityCheckMap } from 'domain/models';

const ID_SEPARATOR = '|';

interface Tier2IndexProps {
  schema: any;
  formData: any;
}
export const Tier2Index = (props: Tier2IndexProps) => {
  const fields = usePropsForSchema(props.schema, props.formData);

  if (!fields || fields.length === 0) {
    return null;
  }
  
  return (
    <ErrorBoundary
      fallback={
        <div className='tier-2-index-wrapper'>
          <Column className='tier-2-index'>
            <Text className='tier-2-index-title'>Table of content</Text>
            <Text className='tier-2-index-title'>Loading...</Text>
          </Column>
        </div>
      }
    >
      <div className='tier-2-index-wrapper'>
        <Column className='tier-2-index'>
          <Text className='tier-2-index-title'>Table of content</Text>
          {fields.map((item) => (
            <Item key={item.property} item={item} />
          ))}
        </Column>
      </div>
    </ErrorBoundary>
  );
};

const Item = ({
  item,
  parentCode,
}: {
  item: IndexItem;
  parentCode?: string;
}) => {
  const hasChildren = Array.isArray(item.children);

  const linkRef = React.useMemo(() => {
    if (parentCode) {
      return `root${ID_SEPARATOR}${parentCode}${ID_SEPARATOR}${item.property}`;
    }
    return `root${ID_SEPARATOR}${item.property}`;
  }, [item, parentCode]);

  const StateIcon = React.useCallback(() => {
    if (item.filled) {
      switch (item.filled) {
        case QualityCheck.FullyMeet:
          return (
            <Row title={qualityCheckMap[QualityCheck.FullyMeet]}>
              <Check className='item-state-icon fully-meet' strokeWidth={1} />
            </Row>
          );
        case QualityCheck.NotMeet:
          return (
            <Row title={qualityCheckMap[QualityCheck.NotMeet]}>
              <AlertTriangle
                className='item-state-icon not-meet'
                strokeWidth={1}
              />
            </Row>
          );
        case QualityCheck.NA:
          return (
            <Row title='N/A'>
              <X className='item-state-icon check na' strokeWidth={1} />
            </Row>
          );
      }
    }
    return null;
  }, [item]);

  return (
    <Group
      orientation='column'
      placement='start'
      spacing='0.25em'
      className={`item ${hasChildren ? 'parent' : 'child'}`}
    >
      <Group className='item-label-row' placement='space-between'>
        <Link href={`#${linkRef}`} className='item-label'>
          {item.label}
        </Link>
        {item.filled && <div className='fill-decorator' />}
        <StateIcon />
      </Group>
      {hasChildren && (
        <Column className='item-children'>
          {item.children!.map((i) => (
            <Item key={i.property} item={i} parentCode={item.property} />
          ))}
        </Column>
      )}
    </Group>
  );
};

type IndexItem = {
  property: string;
  label: string;
  filled?: QualityCheck;
  children?: IndexItem[];
};
const usePropsForSchema = (schema: any, formData: any) => {
  return React.useMemo(() => {
    let items: IndexItem[] = [];

    if (!schema || !schema.properties) {
      return items;
    }

    for (let [propCode, prop] of Object.entries(schema.properties) as any) {
      let children: IndexItem[] = [];

      if (!prop.properties) {
        continue;
      }

      for (let [childCode, child] of Object.entries(prop.properties) as any) {
        if (child.title === undefined) {
          continue;
        }

        const childItem: IndexItem = {
          property: childCode,
          label: child.title,
          filled: getItemState([propCode, childCode], formData),
        };
        children.push(childItem);
      }

      const parentItem: IndexItem = {
        property: propCode,
        label: prop.title,
        filled: getItemState([propCode], formData),
        children: children,
      };

      items.push(parentItem);
    }

    return items;
  }, [schema, formData]);
};

const getItemState = (
  propPath: string[],
  formData: any
): QualityCheck | undefined => {
  try {
    if (propPath.includes('key_4.1')) {
      // debugger;
    }
    let value = formData;
    for (let path of propPath) {
      value = value?.[path];
    }

    let filled;

    if (propPath.length === 1) {
      filled = Object.entries(value)
        .filter(([k]) => k !== 'comment_thread')
        .every(([_, v]: any) =>
          Object.entries(v)
            .filter(([k, vv]) => k !== 'comment_thread')
            .every(([__, vv]: any) => Object.keys(vv).length > 0)
        );
    } else {
      filled = Object.entries(value)
        .filter(([k]) => k !== 'comment_thread')
        .every(([key, v]: [string, any]) => {
          return Object.keys(v).length > 0;
        });
    }

    if (!filled) {
      return undefined;
    }

    let key;
    if (propPath.length === 1) {
      key = `${propPath[0]}_qc`;
    } else {
      key = `${propPath[1]}_qc`;
    }

    const qc = value[key];

    if (qc) {
      return qc.quality_check as QualityCheck;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
};
