import React from 'react';

import {
  ArrayFieldTemplateProps,
  FieldProps,
  FieldTemplateProps,
  ObjectFieldTemplateProps,
} from '@rjsf/utils';
import {
  Trash,
  Plus,
  ExclamationMark,
  AlertTriangle,
} from 'tabler-icons-react';

import {
  Row,
  FormControl,
  Column,
  ButtonWithIcon,
  RawText,
  Group,
  Checkbox,
  Text,
  LimitedInput,
} from 'components';

import { readOnlyFromProps } from './utils';
import './BasicFields.scss';
import { DescriptionRenderer } from '../DescriptionRenderer';

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  // Show the JSON
  return (
    <Row className='object-field-wrapper'>
      {props.properties.map((p) => p.content)}
    </Row>
  );
};

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const isDisabled = readOnlyFromProps(props);

  return (
    <FormControl label={''} description={''}>
      <Column style={{ width: '100%' }}>
        {props.items.map((item) => {
          return (
            <Column key={item.key} style={{ width: '100%' }}>
              {item.children}
              {!isDisabled && (
                <Group placement='end'>
                  <ButtonWithIcon
                    icon={<Trash strokeWidth={1} />}
                    onClick={item.onDropIndexClick(item.index)}
                  >
                    Delete
                  </ButtonWithIcon>
                </Group>
              )}
            </Column>
          );
        })}
        {!isDisabled && (
          <Group placement='start'>
            <ButtonWithIcon
              onClick={props.onAddClick}
              icon={<Plus strokeWidth={1} />}
            >
              Add new item
            </ButtonWithIcon>
          </Group>
        )}
      </Column>
    </FormControl>
  );
};

export const FieldTemplate = (props: FieldTemplateProps) => {
  const isObject = Boolean(props.schema.properties);

  return (
    <FormControl
      label={props.schema.title ?? ''}
      description={props.description}
      id={props.id}
      required={props.required}
      className={`rjsf-field-template ${
        !props.schema.title ? 'no-title' : ''
      } ${isObject ? 'is-title' : ''}`}
    >
      {props.children}
      <FieldErrors errors={props.rawErrors} />
    </FormControl>
  );
};

export const DescriptionField = (props: FieldProps) => {

  return <DescriptionRenderer>
    {props.description ?? ``}
  </DescriptionRenderer>;
};

export const TitleField = (props: FieldProps) => {
  return <RawText>{props.title}</RawText>;
};

export const BooleanField = (props: FieldProps<boolean>) => {
  return (
    <Checkbox
      name={props.name}
      checked={props.formData ?? false}
      onChange={() => props.onChange(!props.formData)}
    />
  );
};

export const StringField = (props: FieldProps) => {
  return (
    <LimitedInput
      name={props.title}
      className='rjsf-input'
      value={props.formData}
      type={props?.schema?.format}
      disabled={readOnlyFromProps(props)}
      onChange={(evt) => props.onChange(evt.target.value)}
    />
  );
};

export const FieldErrors = ({
  errors = [],
}: {
  errors: string[] | undefined;
}) => {
  return errors.length > 0 ? (
    <Group
      orientation='column'
      placement='start'
      spacing='0.5em'
      className='field-errors'
    >
      {errors.map((error, i) => (
        <Group
          orientation='row'
          placement='start'
          key={i}
          className='field-error'
        >
          <AlertTriangle
            strokeWidth={1}
            size={18}
            className='field-error-icon'
          />
          <Text className='field-error-text'>{error}</Text>
        </Group>
      ))}
    </Group>
  ) : null;
};
