import React, { useEffect, useMemo } from 'react';

import { FieldProps, UiSchema } from '@rjsf/utils';
import {
  ImageFormDataI,
  ImageFromSchemaI,
} from 'domain/models/rjsf-fields.model';
import { rawFetcher } from 'domain/swr/fetcher';
import { readOnlyFromProps } from './utils';

import './ImageField.scss';

export const ImageField: React.FC<FieldProps> = ({
  onChange,
  formData,
  uiSchema,
  schema,
  ...props
}) => {
  const array = useMemo(() => {
    if (schema.items) {
      const asMap = schema.items as { [key: string]: any };
      if (uiSchema?.['ui:multi']) {
        return asMap['anyOf'] || [];
      } else {
        return asMap['oneOf'] || [];
      }
    }
    return [];
  }, [schema, uiSchema]);

  return (
    <div className={'images'}>
      {array.map((entry: ImageFromSchemaI) => (
        <ImageComponent
          key={entry.const as string}
          data={{
            id: entry.const as string,
            url: entry.url,
            title: entry.title!,
          }}
          onChange={onChange}
          selected={formData}
          uiSchema={uiSchema}
          {...props}
        />
      ))}
    </div>
  );
};
interface ImageComponentI {
  data: ImageFormDataI;
  onChange: (formData: string | string[] | undefined) => void;
  selected: string[] | string;
  uiSchema?: UiSchema;
  disabled?: boolean;
  readOnly?: boolean;
}
const ImageComponent = ({
  data,
  onChange,
  selected,
  uiSchema,
  disabled,
  readOnly,
}: ImageComponentI) => {
  const img = React.useRef<HTMLImageElement>(null);

  const isDisabled = readOnlyFromProps({
    disabled,
    readonly: readOnly,
    uiSchema,
  });

  const isMulti = uiSchema?.['ui:multi'];
  const onAddImage = () => {
    if (isDisabled) {
      return;
    }
    if (isMulti) {
      if (!selected) {
        onChange([data.id]);
        return;
      }
      if (!Array.isArray(selected)) {
        return;
      }
      if (selected.includes(data.id)) {
        const newSelected = selected.filter((e: string) => e !== data.id);
        onChange(newSelected);
        return;
      }
      onChange([...selected, data.id]);
    } else {
      if (Array.isArray(selected)) {
        return;
      }
      onChange(data.id);
    }
  };

  const fetchImage = async () => {
    const url = await rawFetcher(`${window.location.origin}${data.url}`)
      .then((res: Response) => res.blob())
      .then((blob) => {
        return URL.createObjectURL(blob);
      });

    if (img.current) {
      img.current.src = url;
    }
  };

  React.useEffect(() => {
    fetchImage();
  }, []);

  return (
    <label className={'image-container'}>
      <img
        ref={img}
        className={
          `image-selector ` +
          `${
            Array.isArray(selected) && selected.includes(data.id)
              ? 'active'
              : ''
          } ` +
          `${
            selected && !Array.isArray(selected) && selected === data.id
              ? 'active'
              : ''
          } ` +
          `${disabled ? 'disabled cursor-not-allowed' : ''}`
        }
        onClick={() => {
          if (disabled) {
            return;
          }
          onAddImage();
        }}
        title={data.id}
        key={data.id}
        alt={`Image of ${data.id}`}
      />
      <span>{data.title}</span>
    </label>
  );
};
