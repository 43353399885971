import React from 'react';

import { useSelector } from 'react-redux';

import { AppStore } from 'domain/models';

export const ModalController = () => {
  const { modals } = useSelector((store: AppStore) => store.root);

  return (
    <React.Fragment>
      {modals.map((M, i) => (
        <React.Fragment key={i}>{M}</React.Fragment>
      ))}
    </React.Fragment>
  );
};
