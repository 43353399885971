import React, { useMemo } from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { FormControl, Group, LimitedTextarea, Text } from 'components';
import {
  QualityCheckSelect,
  useQualityCheckChange,
} from './CatalogProvidedControl';
import { getRawErrors, readOnlyFromProps } from './utils';
import { FieldErrors } from './BasicFields';
import { DescriptionRenderer } from '../DescriptionRenderer';

import './QualityCheckField.scss';

export const QualityCheckField: Field = (props: FieldProps) => {
  const errors = getRawErrors(props.errorSchema, props.schema);

  const [onQualityCheckChange] =
    useQualityCheckChange(props);

  const inputValue = useMemo(() => {
    return props.formData?.data_provider_input ?? '';
  }, [props.formData?.data_provider_input]);

  const qualityCheckValue = useMemo(() => {
    return props.formData?.quality_check ?? '';
  }, [props.formData?.quality_check]);

  const readonly = readOnlyFromProps(props);

  const qualityCheckProperties = useMemo(() => {
    if (props.schema.properties?.data_provider_input) {
      return props.schema.properties?.data_provider_input as {
        [key: string]: any;
      };
    }
    return undefined;
  }, [props.schema]);

  return (
    <Group placement='start' className='quality-check-field'>
      <FormControl
        label={qualityCheckProperties?.title ?? ''}
        description={
          <DescriptionRenderer>
            {qualityCheckProperties?.description ?? ''}
          </DescriptionRenderer>
        }
      >
        <QualityCheckSelect
          qualityCheck={qualityCheckValue}
          editable={!readonly}
          onQualityCheckChange={onQualityCheckChange}
        />
        {readonly ? (
          <div className='quality-check-input-reason-wrapper'>
            <span className='quality-check-input-reason-label'>
              Quality check reason:
            </span>
            <Text className='quality-check-input-reason'> {inputValue}</Text>
          </div>
        ) : (
          <LimitedTextarea
            placeholder='Quality check reason'
            className='quality-check-input'
            disabled={readonly}
            value={inputValue}
            onChange={({ target }) =>
              props.onChange({
                ...props.formData,
                data_provider_input: target.value,
              })
            }
          />
        )}
        {errors.data_provider_input && (
          <FieldErrors errors={errors.data_provider_input} />
        )}
      </FormControl>
    </Group>
  );
};
