import { Api } from 'domain/core';
import { ExternalUserIssue } from 'domain/models/userAccess.models';
import { fetcher } from 'domain/swr/fetcher';
import React from 'react';

export const useGeneratePublicUrl = (): [
  string | null,
  boolean,
  (data: ExternalUserIssue) => void
] => {
  const url = `${Api.baseUrl}/user-access`;

  const timeoutRef = React.useRef<any | null>(null);
  const [magicLink, setMagicLink] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const doRequest = (data: ExternalUserIssue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setLoading(false);
    }
    const baseUrl = window.location.origin;
    setLoading(true);
    timeoutRef.current = setTimeout(() => {
      return fetcher(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          setMagicLink(baseUrl + "/magic-link?" + res.url);
        })
        .catch((err) => {
          console.error(err);
          setMagicLink('Error generating public URL');
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  };

  return [magicLink, loading, doRequest];
};
