import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { Group, Row, Text } from 'components';

import './ForwardControl.scss';

export const ForwardControl: Field = (props: FieldProps) => {

  return (
    <Group className='rjsf-forward-control' orientation='column'>
      <Group className='forward-control-title-row' placement='start'>
        <Text className='forward-control-title'>Send this task to:</Text>
      </Group>
      <Group className='forward-control-options' placement='start'>
        {props.schema.enum?.map((en: any, i) => {
          const label = (props.schema as any).enumNames?.at(i);

          return (
            <Row
              tabIndex={0}
              role='button'
              className={`forward-control-option ${
                props.formData === en ? 'selected' : ''
              }`}
              key={en}
              onClick={() => props.onChange(en)}
            >
              {label}
            </Row>
          );
        })}
      </Group>
    </Group>
  );
};
