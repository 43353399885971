import React from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw'

import { Link, Paragraph } from 'components';

import './MarkdownRenderer.scss';

interface MarkdownRendererProps {
  children: string;
  components?: Record<string, React.Component>;
  linkTarget?: string;
  className?: string;
}
export const MarkdownRenderer = ({
  children,
  components,
  className = '',
  linkTarget = '__blank',
}: MarkdownRendererProps) => {
  const renderers = {
    //This custom renderer changes how images are rendered
    //we use it to constrain the max width of an image to its container
    image: ({
      alt,
      src,
      title,
    }: {
      alt?: string;
      src?: string;
      title?: string;
    }) => {

      // While react-markdown does not support width and height attributes, we use 
      // query parameters to set the width and height of the image.
      // Check if the image has a width and height query parameter
      const width = src?.match(/width=(\d+)/)?.[1];
      const height = src?.match(/height=(\d+)/)?.[1];

      // If the image has a width and height query parameter, we use it to set the max width of the image
      let settedWidth = width ? width + 'px' : undefined;
      let settedHeight = width ? height + 'px' : undefined;
      return (
        <img
          alt={alt}
          src={src}
          title={title}
          style={{ width: settedWidth, height: settedHeight, maxWidth: '100%'}} />
      )
    },
  };

  return (
    <ReactMarkdown
      className={`markdown-renderer ${className}`}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      linkTarget={linkTarget}
      components={{
        img: renderers.image,
        a({ href, children, target }) {
          return (
            <Link href={href} target={target}>
              {children}
            </Link>
          );
        },
        p({ className, ...props }) {
          return (
            <Paragraph {...props} className={`md-paragraph ${className}`} />
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
