import React from 'react';

import CreatableSelect from 'react-select/creatable';
import { Field, FieldProps } from '@rjsf/utils';

import {
  readOnlyFromProps,
  useSelectOnChange,
  useSelectSchemaOptions,
} from './utils';
import { inOptions } from 'domain/utils';

import './CreatableSelectControl.scss';

export const CreatableSelectControl: Field = (props: FieldProps) => {
  const options = useSelectSchemaOptions(props);
  const onChange = useSelectOnChange(props);
  const selectedOptions = inOptions(
    options,
    props.formData,
    undefined,
    props.uiSchema!.multiple
  );

  return (
    <CreatableSelect
      className='select rjsf-select'
      classNamePrefix={'cop-select'}
      getNewOptionData={(value: string, label: any) => ({ value, label })}
      isMulti={props.uiSchema!.multiple}
      isValidNewOption={(val) => true}
      isDisabled={readOnlyFromProps(props)}
      name={props.title}
      value={selectedOptions}
      options={options}
      onChange={onChange}
    />
  );
};
