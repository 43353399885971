import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { Group, Row, Text } from 'components';
import { range } from 'domain/utils';
import { CatalogProvidedInfoProxy, FormDataFieldRenderer } from '../qar';

import { Misc } from 'domain/core/constants';

import { getRawErrors } from './utils';
import { FieldErrors } from './BasicFields';

import './ReviewFieldControl.scss';

export const ReviewFieldControl: Field = (props: FieldProps) => {
  const errors = getRawErrors(props.errorSchema, props.schema);

  return (
    <Group className='rjsf-review-field' orientation='column' placement='start'>
      <Group className='' placement='start'>
        <Group
          orientation='column'
          placement='start'
          className='review-field-info'
        >
          <FormDataFieldRenderer
            formData={props.formData?.data_provider_input}
            schema={(props.schema as any).properties.data_provider_input}
            path=''
            uiSchema={props.uiSchema}
            schemaPath=''
          />
          <CatalogProvidedInfoProxy
            catalogInfo={props.formData?.catalogue_provided_input}
            dataProviderInput={props.formData?.data_provider_input}
            qualityCheck={props.formData?.quality_check}
          />
        </Group>
        {/* <ReviewScoreControl {...props} /> */}
      </Group>
      {errors.color_score && <FieldErrors errors={errors.color_score} />}
    </Group>
  );
};

const ReviewScoreControl = (props: FieldProps) => {
  const score = props.formData.color_score;

  return (
    <Group placement='start' orientation='row' className='rjsf-review-score'>
      <Group
        placement='end'
        orientation='column'
        spacing='0px'
        className='rjsf-review-sectors'
      >
        {range(3, 1)
          .reverse()
          .map((i) => (
            <Row
              className={`score-sector ${score === i ? 'selected' : ''}`}
              key={i}
              style={{ backgroundColor: Misc.ReviewColors[i - 1].color }}
              title={Misc.ReviewColors[i - 1].text}
              onClick={() =>
                props.onChange({ ...props.formData, color_score: i })
              }
            />
          ))}
      </Group>
    </Group>
  );
};
