import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { Select } from 'components';

import {
  readOnlyFromProps,
  useSelectOnChange,
  useSelectSchemaOptions,
} from './utils';
import { inOptions } from 'domain/utils';

import './SelectControl.scss';

export const SelectControl: Field = (props: FieldProps) => {
  const options = useSelectSchemaOptions(props);
  const onChange = useSelectOnChange(props);
  const selectedOptions = inOptions(
    options,
    props.formData,
    undefined,
    props.uiSchema?.multiple
  );

  return (
    <Select
      className='select rjsf-select'
      classNamePrefix={'cop-select'}
      isDisabled={readOnlyFromProps(props)}
      name={props.title}
      isMulti={props.uiSchema?.multiple}
      value={selectedOptions}
      options={options}
      onChange={onChange}
    />
  );
};
