import React, { useMemo } from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { FormControl, Group, Row, Select, Text } from 'components';

import { FieldErrors, StringField } from './BasicFields';

import { getRawErrors } from './utils';

import './CatalogProvidedControl.scss';
import { inOptions, SelectOption } from 'domain/utils';
import {
  QualityCheck,
  qualityCheckMap,
  qualityCheckOptions,
} from 'domain/models';
import { ButtonsSelect } from 'components/ButtonsSelect';

export const useQualityCheckChange = (
  props: FieldProps
): [(qc: QualityCheck) => void, QualityCheck | undefined] => {
  const QC_NO_INPUT_TEXT = qualityCheckMap[QualityCheck.NA];
  const QC_FMR_INPUT_TEXT = qualityCheckMap[QualityCheck.FullyMeet];

  const [qualityCheck, setQualityCheck] = React.useState<
    QualityCheck | undefined
  >(props.formData?.quality_check);

  const fn = React.useCallback(
    (qc: QualityCheck) => {
      if (qc === QualityCheck.NA) {
        props.onChange({
          ...props.formData,
          data_provider_input: props.formData?.data_provider_input,
          quality_check: qc,
        });
      } else if (qc === QualityCheck.FullyMeet) {
        props.onChange({
          ...props.formData,
          data_provider_input: props.formData?.data_provider_input,
          quality_check: qc,
        });
      } else {
        props.onChange({
          ...props.formData,
          data_provider_input: props.formData?.data_provider_input,
          quality_check: qc,
        });
      }
      setQualityCheck(qc);
    },
    [props]
  );

  return [fn, qualityCheck];
};

export const CatalogProvidedControl: Field = (props: FieldProps) => {
  const errors = getRawErrors(props.errorSchema, props.schema);

  const fieldSchema: any = props.schema.properties?.data_provider_input;

  const uiReviewField = props.uiSchema!['ui:reviewField'];
  const RegistryComponent = props.registry.fields[uiReviewField] ?? StringField;

  const fieldUi = props.uiSchema?.data_provider_input ?? {};
  const uiFieldSchema = {
    ...fieldUi,
    'ui:field': uiReviewField,
  };
  const p = {
    ...props,
    formData: props.formData?.data_provider_input,
    schema: fieldSchema,
    uiSchema: uiFieldSchema,
    onChange: (val: any) =>
      props.onChange({ ...props.formData, data_provider_input: val }),
  } as FieldProps;

  const [onQualityCheckChange, qualityCheck] =
    useQualityCheckChange(props);

  return (
    <Group
      className='rjsf-catalog-provided-field'
      orientation='column'
      placement='start'
    >
      <FormControl
        label={fieldSchema?.title ?? ''}
        description={fieldSchema?.description}
      >
        <CatalogProvidedInfo
          qualityCheck={qualityCheck}
          editable
          onQualityCheckChange={onQualityCheckChange}
          input={props.formData.catalogue_provided_input ?? '-'}
        />
        <RegistryComponent {...p} />
        {errors.data_provider_input && (
          <FieldErrors errors={errors.data_provider_input} />
        )}
      </FormControl>
    </Group>
  );
};

interface CatalogProvidedInfoProps {
  input: string;
  qualityCheck?: QualityCheck;
  editable?: boolean;
  onQualityCheckChange(qc: QualityCheck): void;
}
export const CatalogProvidedInfo = (props: CatalogProvidedInfoProps) => {
  const formatted = React.useMemo(() => {
    // Insert a <br> when a \n is found
    if (!props.input) {
      return <>-</>;
    }

    const lines = props.input.split('\n');
    return (
      <>
        {lines.map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  }, [props.input]);

  return (
    <Group
      className='catalog-provided-info'
      orientation='column'
      placement='start'
    >
      <Group
        className='catalog-provided-info-wrapper'
        orientation='column'
        placement='start'
        spacing='0.5em'
      >
        <Text className='title'>Catalogue provided information</Text>
        <Text className='catalog-provided-info-value'>{formatted}</Text>
      </Group>
      <QualityCheckSelect
        qualityCheck={props.qualityCheck}
        editable={props.editable}
        onQualityCheckChange={props.onQualityCheckChange}
      />
    </Group>
  );
};

export const QualityCheckSelect = (props: {
  qualityCheck?: QualityCheck;
  editable?: boolean;
  onQualityCheckChange(qc: QualityCheck): void;
}) => {
  return (
    <Group
      placement='start'
      className='catalog-provided-info-edit-row break-before-auto	'
    >
      <label className='label'>
        <div>Quality check:</div>
        <div>
          {props.editable ? (
            <>
              {qualityCheckOptions.length < 4 ? (
                <ButtonsSelect
                  options={qualityCheckOptions}
                  value={inOptions(qualityCheckOptions, props.qualityCheck)}
                  isDisabled={!props.editable}
                  onChange={({ value }: any) => {
                    props.onQualityCheckChange(value);
                  }}
                />
              ) : (
                <Select
                  options={qualityCheckOptions}
                  value={inOptions(qualityCheckOptions, props.qualityCheck)}
                  isDisabled={!props.editable}
                  onChange={({ value }: any) => {
                    props.onQualityCheckChange(value);
                  }}
                />
              )}
            </>
          ) : (
            <Row className={`quality-check-value ${props.qualityCheck}`}>
              <Text>
                {!props.qualityCheck && (
                  <Text className='quality-check-value'>Waiting for input</Text>
                )}
                {
                  (
                    inOptions(
                      qualityCheckOptions,
                      props.qualityCheck
                    ) as SelectOption<string>
                  )?.label
                }
              </Text>
            </Row>
          )}
        </div>
      </label>
    </Group>
  );
};
