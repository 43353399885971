import React from 'react';

import { Check, Clock, ClockOff } from 'tabler-icons-react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';

import { ErrorBoundary, Group, Row, Text } from 'components';

import { Task } from 'domain/models';
import { useHistoryTasks } from 'domain/swr';
import { range } from 'domain/utils';

import './TaskHistoryTimeline.scss';
import { useTranslation } from 'react-i18next';

interface TaskHistoryTimelineProps {
  tasks: Task[];
  fillable?: boolean;
  limit?: number;
}
export const TaskHistoryTimeline = (props: TaskHistoryTimelineProps) => {
  const { t } = useTranslation(['users']);
  const [sortedTasks, showMore] = React.useMemo(() => {
    const sorted = [...props.tasks].sort((t1, t2) => {
      const tt1 = new Date(t1.created_date).valueOf();
      const tt2 = new Date(t2.created_date).valueOf();

      return tt2 - tt1;
    });

    if (props.limit && sorted.length > props.limit) {
      return [sorted.slice(0, props.limit), true];
    } else {
      return [sorted, false];
    }
  }, [props.tasks, props.limit]);

  const processId = React.useMemo(() => {
    return props.tasks.find((t) => t.process_id)?.process_id;
  }, [props.tasks]);

  return (
    <Group
      className='task-history-timeline'
      orientation='column'
      placement='start'
      spacing='0px'
    >
      {sortedTasks.length === 0 ? (
        <Text className='no-tasks-message'>
          There is no history for this task yet
        </Text>
      ) : null}

      {sortedTasks.map((task, i, self) => {
        const {
          assignee,
          name,
          assigned_group,
          description,
          id,
          created_date,
          finished_date,
          task_variables,
        } = task;

        const groupName = assigned_group?.substring("ROLE_".length);

        const assigned_group_label = t(`users:roles.${groupName}.label`.toLowerCase(), {
          defaultValue: groupName,
        });

        const nextTask = self.at(i + 1);

        const compareQar = nextTask?.task_variables?.qar;

        const last = i === self.length - 1;
        const completed = !!finished_date;

        const parsedCreatedDate = format(
          new Date(created_date),
          'dd-MM-yyyy HH:mm'
        );
        const parsedFinishedDate = finished_date
          ? format(new Date(finished_date), 'dd-MM-yyyy HH:mm')
          : undefined;

        return (
          <Group
            className={cn(`task-history-entry`, { last, completed })}
            orientation='row'
            placement='start'
            key={id}
          >
            <Group
              className='timeline-entry-left-wrapper'
              orientation='column'
              placement='space-between'
              spacing='0px'
            >
              <Row className='icon-wrapper'>
                <StatusIcon 
                  task={task} />
              </Row>
              <div className='timeline-line' />
            </Group>

            <Group
              className='timeline-entry-data'
              orientation='column'
              spacing='0px'
            >
              <Text ellipsis className='timeline-entry-title'>
                {(completed || !props.fillable) ? (
                  <Text className='completed-task-name'>{name}</Text>
                ) : (
                  <Link className='link' to={`/app/task/${id}`}>
                    {name}
                  </Link>
                )}
              </Text>
              <Text ellipsis className='timeline-entry-description'>
                {task_variables.qar && (
                  <Link className='link' to={`/app/qar/${task_variables.qar}`}>
                    View QAR
                  </Link>
                )}
                {compareQar && task_variables.qar && (
                  <Link
                    className='link'
                    to={`/app/qar/${task_variables.qar}/history/${compareQar}`}
                  >
                    Compare with previous version
                  </Link>
                )}
                {!task_variables.qar && '-'}
              </Text>
              <Text ellipsis className='timeline-entry-assignee'>
                { assignee ?? `Unassigned` } ({ assigned_group ? assigned_group_label : '-' })
              </Text>
              <Text ellipsis className='timeline-entry-dates'>
                Created at {parsedCreatedDate}
                {completed && ` - Completed at ${parsedFinishedDate}`}
              </Text>
            </Group>
          </Group>
        );
      })}
      {showMore && processId && (
        <Text className='show-more-history'>
          There are {props.tasks.length - sortedTasks.length} more history
          entries.{' '}
          <Link className='link' to={`/app/process/${processId}/history`}>
            Show full history
          </Link>
        </Text>
      )}
    </Group>
  );
};

const StatusIcon = (props: { task: Task }) => {
  const { task } = props;
  if (!task.state || task.state === 'PENDING') {
    return <Clock className='timeline-icon' />;
  }
  if (task.state === 'TIMED_OUT') {
    return <ClockOff className='timeline-icon timed-out' />;
  }
  
  return <Check className='timeline-icon' />
};

interface TaskHistoryRendererProps {
  processId: string;
  fillable?: boolean;
  limit?: number;
}
export const TaskHistoryRenderer = (props: TaskHistoryRendererProps) => {
  const tasks = useHistoryTasks(props.processId);

  return <TaskHistoryTimeline tasks={tasks.content} limit={props.limit} fillable={props.fillable} />;
};

export const TaskHistoryRendererSkeleton = ({
  entries = 4,
}: {
  entries?: number;
}) => {
  return (
    <Group
      orientation='column'
      placement='start'
      className='task-history-timeline skeleton'
    >
      {range(entries).map((i) => (
        <Group
          orientation='row'
          className='task-history-entry skeleton'
          key={i}
        >
          <Row
            className='timeline-entry-left-wrapper'
            style={{ width: '40px', height: '100px' }}
          />

          <Group
            className='timeline-entry-data'
            orientation='column'
            spacing='0px'
          >
            <Skeleton width={450} height={20} />
            <Skeleton width={300} height={16} />
            <Skeleton width={100} height={16} />
            <Skeleton width={150} height={16} />
          </Group>
        </Group>
      ))}
    </Group>
  );
};

export const TaskHistoryRendererError = () => {
  return (
    <Group>
      <Text>An error has occurred loading task history</Text>
    </Group>
  );
};

export const TaskHistoryTimelineLoader = (
  props: TaskHistoryRendererProps & { skeletonCount?: number }
) => {
  return (
    <ErrorBoundary fallback={<TaskHistoryRendererError />}>
      <React.Suspense
        fallback={<TaskHistoryRendererSkeleton entries={props.skeletonCount} />}
      >
        <TaskHistoryRenderer processId={props.processId} limit={props.limit} fillable={props.fillable}/>
      </React.Suspense>
    </ErrorBoundary>
  );
};
