import { CommentThreadControl, CommentThread } from './CommentThreadControl';

import { ApproveRejectField } from './ApproveRejectField';
import {
  BooleanField,
  DescriptionField,
  StringField,
  TitleField,
  ArrayFieldTemplate,
  FieldErrors,
  FieldTemplate,
  ObjectFieldTemplate,
} from './BasicFields';
import { CatalogueEntryControl } from './CatalogueEntryControl';
import {
  CatalogProvidedControl,
  CatalogProvidedInfo,
} from './CatalogProvidedControl';
import { CreatableSelectControl } from './CreatableSelectControl';
import { DatePickerControl } from './DatePickerControl';
import { FixReviewControl } from './FixReviewControl';
import { ForwardControl } from './ForwardControl';
import { ImageField } from './ImageField';
import {
  MarkdownEditorControl,
  MarkdownEditorWithReferencesControl,
} from './MarkdownEditorControl';
import { JupyterEditorControl } from './JupyterEditorControl';
import { ReviewFieldControl } from './ReviewFieldControl';
import { SelectControl } from './SelectControl';
import {
  AssessmentImageField,
  AssessmentImageModal,
  ImageCaption,
  ImageMetadata,
  ImageSummary,
  AssessmentImageFilter,
} from './AssessmentImageField';
import { ReviewComment } from './ReviewComment';
import { QAUseCasesField } from './QAUseCasesField';
import { QualityCheckField } from './QualityCheckField';
import { JupyterNotebookUrl } from './JupyterNotebookUrl';
import { AutoQualityCheckField, HiddenAutoQualityCheckField } from './AutoQualityCheckField';
import { PublicUrlProvider } from './PublicUrlProvider';
import { MarkdownDataPreview } from './MarkdownDataPreview';


export enum RjsfCustomField {
  CatalogueEntry = 'catalogueEntry',
  Select = 'select',
  CreatableSelect = 'creatableSelect',
  DatePicker = 'datePicker',
  MDEditor = 'mdEditor',
  MDEditorWithReferences = 'mdEditorWithReferences',
  JupyterEditor = 'jupyterEditor',
  ReviewField = 'reviewField',
  FixReviewField = 'fixReviewField',
  ApproveRejectField = 'approveRejectField',
  CommentThread = 'commentThread',
  StringField = 'StringField',
  DescriptionField = 'DescriptionField',
  TitleField = 'TitleField',
  BooleanField = 'BooleanField',
  ForwardField = 'forwardField',
  PublicUrlProvider = 'publicUrlProvider',
  CatalogueProvided = 'catalogProvidedField',
  ImageField = 'imageField',
  AssessmentImageField = 'assessmentImage',
  AssessmentUseCasesField = 'qaUseCasesField',
  QualityCheck = 'qualityCheck',
  AutoQualityCheck = 'autoQualityCheck',
  HiddenAutoQualityCheck = 'hiddenAutoQualityCheck',
  JupyterNotebookUrl = "jupyterNotebookUrl",
  MarkdownDataPreview = 'markdownDataPreview',
}

export const RJSF_FIELDS = {
  [RjsfCustomField.CommentThread]: CommentThreadControl,
  [RjsfCustomField.CatalogueEntry]: CatalogueEntryControl,
  [RjsfCustomField.Select]: SelectControl,
  [RjsfCustomField.CreatableSelect]: CreatableSelectControl,
  [RjsfCustomField.DatePicker]: DatePickerControl,
  [RjsfCustomField.MDEditor]: MarkdownEditorControl,
  [RjsfCustomField.MDEditorWithReferences]: MarkdownEditorWithReferencesControl,
  [RjsfCustomField.JupyterEditor]: JupyterEditorControl,
  [RjsfCustomField.ReviewField]: ReviewFieldControl,
  [RjsfCustomField.FixReviewField]: FixReviewControl,
  [RjsfCustomField.ApproveRejectField]: ApproveRejectField,
  [RjsfCustomField.ForwardField]: ForwardControl,
  [RjsfCustomField.PublicUrlProvider]: PublicUrlProvider,
  [RjsfCustomField.CatalogueProvided]: CatalogProvidedControl,
  [RjsfCustomField.StringField]: StringField,
  [RjsfCustomField.DescriptionField]: DescriptionField,
  [RjsfCustomField.TitleField]: TitleField,
  [RjsfCustomField.BooleanField]: BooleanField,
  [RjsfCustomField.ImageField]: ImageField,
  [RjsfCustomField.AssessmentImageField]: AssessmentImageField,
  [RjsfCustomField.AssessmentUseCasesField]: QAUseCasesField,
  [RjsfCustomField.QualityCheck]: QualityCheckField,
  [RjsfCustomField.AutoQualityCheck]: AutoQualityCheckField,
  [RjsfCustomField.HiddenAutoQualityCheck]: HiddenAutoQualityCheckField,
  [RjsfCustomField.JupyterNotebookUrl]: JupyterNotebookUrl,
  [RjsfCustomField.MarkdownDataPreview]: MarkdownDataPreview,
};

export {
  CommentThreadControl,
  CatalogueEntryControl,
  SelectControl,
  CreatableSelectControl,
  DatePickerControl,
  MarkdownEditorControl,
  ReviewFieldControl,
  FixReviewControl,
  CatalogProvidedInfo,
  ApproveRejectField,
  ForwardControl,
  CatalogProvidedControl,
  StringField,
  DescriptionField,
  TitleField,
  BooleanField,
  ImageField,
  AssessmentImageField,
  CommentThread,
  ArrayFieldTemplate,
  FieldErrors,
  FieldTemplate,
  ObjectFieldTemplate,
  AssessmentImageModal,
  ImageCaption,
  ImageMetadata,
  ImageSummary,
  ReviewComment,
  AssessmentImageFilter,
  QualityCheckField,
  AutoQualityCheckField,
  HiddenAutoQualityCheckField,
  JupyterNotebookUrl,
};
