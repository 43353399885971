import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from 'remark-gfm';

const components: Components = {
  a: ({node, ...props}) => <a {...props} target="_blank" className="text-blue-700 underline" />
};

export const DescriptionRenderer = ({ children }: 
  React.PropsWithChildren<{}>) => {

    // Replace the following code:
    // Split 

  if (!children) {
    return null;
  }

  // Check if children is a string
  if (typeof children === 'string') {

    const processed = children.replaceAll('\n','\n\n');

    // Split Internal description, for showing in blue color
    const splitedDescription = processed.split('Internal description:');
    let internalDescriptionComponent = null;
    if (splitedDescription.length > 1) {
      internalDescriptionComponent =  (
        <div className="description">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={components}
            className="text-md leading-6 text-blue-600 pb-2"
          >{`**Internal description**: ${splitedDescription[1]}`}</ReactMarkdown>
        </div>
      );
    }
    
    return (
      <div className="description how-to-check mb-2">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          // Link in blue color
          components={components}
          className="text-md leading-6 text-gray-700"
        >{`**How to check?**: ${splitedDescription[0]}`}</ReactMarkdown>
        {internalDescriptionComponent}
      </div>
    );
  }
  return (
    <div className="description">
      {children}
    </div>
  );
};
