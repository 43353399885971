import { SelectOption } from 'domain/utils';
import { JSONSchema7 } from 'json-schema';

export interface ImageFromSchemaI extends JSONSchema7 {
  url: string;
}

export interface ImageFormDataI {
  id: string;
  url: string;
  title: string;
}

export type AssessmentImage = {
  imageId: string;
  key: string;
  metadata: Record<string, any>;
  caption: string;
  comment_thread?: string;
};

export enum QualityCheck {
  FullyMeet = 'fully_meet_requirements',
  NotMeet = 'not_meet_requirements',
  NA = 'na',
}

export type QAUseCase = {
  uc_key: string;
  uc_title: string;
  uc_comment: string;
  uc_attachments: Array<QAUseCaseAttachment | string>;
  comment_thread: string;
};

export type QAUseCaseAttachment = {
  attachmentId: string;
  url: string;
  name: string;
  qar: number;
};

export const isUseCaseAttachment = (obj: any): obj is QAUseCaseAttachment => {
  return 'attachmentId' in obj && 'url' in obj;
};

export const qualityCheckOptions: SelectOption<string>[] = [
  {
    label: 'Criterion fully met',
    value: QualityCheck.FullyMeet,
  },
  {
    label: 'Criterion not fully met',
    value: QualityCheck.NotMeet,
  },
  {
    label: 'N/A',
    value: QualityCheck.NA,
  },
];

// Convert to map
export const qualityCheckMap = qualityCheckOptions.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {} as Record<string, string>
);
