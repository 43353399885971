import React from 'react';

import { InfoCircle } from 'tabler-icons-react';

import { Group, Modal, Text } from 'components';

import { useCatalogueEntriesByIds } from 'domain/swr';

import './CatalogEntryViewer.scss';
import { useDispatch } from 'react-redux';
import { popModal, pushModal } from 'domain/store/root/root.actions';
import { CatalogueEntry } from 'domain/models';
import { MarkdownRenderer } from 'pages/shared/MarkdownRenderer';

interface CatalogEntryViewerProps {
  entries: string[];
}
export const CatalogEntryViewer = (props: CatalogEntryViewerProps) => {
  const dispatch = useDispatch();

  const catalogEntries = useCatalogueEntriesByIds(props.entries);

  const showDescription = (entry: CatalogueEntry) => {
    dispatch(
      pushModal(
        <Modal
          header={entry.title}
          onClose={() => dispatch(popModal())}
          dismissable
        >
          <MarkdownRenderer children={entry.description} />
        </Modal>
      )
    );
  };

  return (
    <Group
      className='catalog-entry-viewer'
      orientation='column'
      placement='start'
      spacing='0.5em'
    >
      <Text className='catalog-entry-viewer-title'>Catalog entries: </Text>
      {catalogEntries.map((entry) => {
        return (
          <Group
            className={`catalog-entry`}
            orientation='column'
            placement='start'
            key={entry.id}
          >
            <Group
              onClick={() => showDescription(entry)}
              placement='start'
              spacing='0.5em'
            >
              <Text className='catalog-entry-name'>{entry.title} </Text>
              <InfoCircle className='catalog-entry-icon' size={16} />
            </Group>
          </Group>
        );
      })}
    </Group>
  );
};
