import React, { useMemo } from 'react';

import { AlertTriangle, X } from 'tabler-icons-react';
import { RJSFValidationError } from '@rjsf/utils';

import { Button, Group, Link, Modal } from 'components';

import './FormErrorsModal.scss';

interface FormErrorsModalProps {
  errors: RJSFValidationError[];
  schema: any;
  onClose: VoidFunction;
  tier2?: boolean;
}
export const FormErrorsModal = (props: FormErrorsModalProps) => {
  const grouped = React.useMemo(() => {
    if (!props.tier2) {
      return [];
    }
    return props.errors.reduce(
      (
        acc: Record<string, RJSFValidationError[]>,
        curr: RJSFValidationError
      ) => {
        const schemaPath = (curr as any).schemaPath as string;
        let splittedKey = schemaPath.split('/');
        splittedKey = splittedKey.slice(1, splittedKey.length - 1);
        const field = splittedKey[splittedKey.length - 1];
        splittedKey = splittedKey.slice(0, splittedKey.length - 1);
        const key = `${splittedKey.join('|')}|${field}`;
        if (acc[key]) {
          return {
            ...acc,
            [key]: acc[key].concat(curr),
          };
        } else {
          return {
            ...acc,
            [key]: [curr],
          };
        }
      },
      {}
    );
  }, [props.errors, props.tier2]);

  const listOfErrors = useMemo(() => {
    if (props.tier2) {
      return [];
    }

    // We do not need to group errors for tier 1 or 3

    return props.errors.map((e) => {
      let schemaPath = (e as any).schemaPath as string;
      schemaPath = schemaPath.substring(2, schemaPath.length - 1); // Remove leading #/
      return {
        key: schemaPath,
        error: e,
      };
    });
  }, [props.errors, props.tier2]);

  console.log('listOfErrors', listOfErrors);

  return (
    <Modal
      header='Form errors'
      onClose={props.onClose}
      className='tier-2-error-modal'
      footer={
        <Group placement='end'>
          <Button onClick={props.onClose}>
            Close <X />
          </Button>
        </Group>
      }
    >
      <Group
        orientation='column'
        placement='start'
        className='tier-2-error-modal-content'
      >
        <SimpleErrorRow />
        {Object.entries(grouped).map(([key, errors]) => (
          <ErrorRow
            path={key}
            errors={errors}
            key={key}
            schema={props.schema}
            close={props.onClose}
          />
        ))}
      </Group>
    </Modal>
  );
};

interface ErrorRowProps {
  path: string;
  errors: RJSFValidationError[];
  schema: any;
  close: VoidFunction;
}
const ErrorRow = (props: ErrorRowProps) => {
  const [title, id, errorLabels] = React.useMemo(() => {
    const splitted = props.path.split('|');

    let obj: any = props.schema;
    for (let prop of splitted) {
      obj = obj?.[prop] ?? {};
    }

    let title;
    if (obj?.properties?.data_provider_input) {
      title = obj.properties.data_provider_input.title;
    } else {
      return [];
    }

    if (obj?.properties?.quality_check) {
      if (!title) {
        title = obj.properties.quality_check.title;
      }
    }

    const id = 'root|' + splitted.filter((s) => s.includes('key_')).join('|');

    const errorLabels = props.errors
      .map((e) => {
        const prop = e.params.missingProperty;
        const message = e.message;

        if (prop) {
          let label = prop;
          if (prop === 'data_provider_input') {
            label = 'Data provider input';
          } else if (prop === 'quality_check') {
            label = 'Quality check';
          }
          return `${label} ${message}`;
        }
        return '';
      })
      .filter((r) => !!r);

    return [title, id, errorLabels];
  }, [props.errors, props.schema, props.path]);

  return errorLabels?.length && errorLabels.length > 0 && title && id ? (
    <Group orientation='column' placement='start' className='error-item'>
      <Link
        href={`#${id}`}
        style={{ color: 'black' }}
        onClick={props.close}
        className='error-item-field'
      >
        {title}
      </Link>
      <Group
        className='error-item-errors'
        orientation='column'
        placement='start'
      >
        {errorLabels.map((e) => (
          <Group className='error-item-error' key={e}>
            <AlertTriangle width={20} height={20} strokeWidth={1} />
            <span>{e}</span>
          </Group>
        ))}
      </Group>
    </Group>
  ) : null;
};

const SimpleErrorRow = () => {
  return (
    <Group orientation='column' placement='start' className='error-item'>
      <Group className='error-item-error'>
        <AlertTriangle width={20} height={20} strokeWidth={1} />
        <span>Please, fill the required fields</span>
      </Group>
    </Group>
  );
};
