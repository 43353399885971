export type IWidget = {
  code: string;
  title: string;
  cols: number;
  type: WidgetType;
  hidden?: boolean;
  className?: string;
};

export enum WidgetType {
  List = 'list',
  BarChart = 'barchart',
  TimeSerie = 'timeserie',
  Count = 'count',
  Calendar = 'calendar',
}
