import React, { useEffect, useMemo } from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import { FormControl, Group } from 'components';
import { QualityCheckSelect } from './CatalogProvidedControl';

import { DescriptionRenderer } from '../DescriptionRenderer';
import { useRJSFormContext } from './RJSForm';
import { QualityCheck } from 'domain/models';

import './QualityCheckField.scss';

export const AutoQualityCheckField: Field = (
  props: FieldProps & {
    isHidden?: boolean;
  }
) => {
  // Get a map of the nested requirements for faster access
  const allProperties = useMemo(() => {
    // Convert all keys to a map of key and path:
    // key: ['path', 'to', 'key']
    const kvProperties: {
      [key: string]: {
        path: string[];
        parent: string[];
      };
    } = {};

    const recursiveProperties = (schema: any, path: string[] = []) => {
      if (schema.properties) {
        for (const key in schema.properties) {
          const newPath = [...path, key];
          kvProperties[key] = {
            path: newPath,
            parent: newPath.slice(0, -1),
          };
          recursiveProperties(schema.properties[key], newPath);
        }
      }
    };
    recursiveProperties(props.registry.rootSchema);

    return kvProperties;
  }, [props.registry.rootSchema]);

  const findValueInPath = (values: any, parentPath: string[]): any => {
    if (!values) {
      return {};
    }
    for (const path of parentPath) {
      if (!values[path] && values?.properties) {
        values = values.properties;
      }
      if (!values[path]) {
        return {};
      }
      values = values[path];
    }
    return values;
  };

  /**
   * Recursive function to find all the nested values of a specific key
   */
  type NestedKV = {
    [key: string]: NestedKV | any;
  };
  const findAllValuesNested = (
    values: NestedKV,
    key: string,
    path?: string
  ): any => {
    let result: {
      [key: string]: any;
    } = {};

    if (!path) {
      path = '';
    }
    for (const value of Object.entries(values)) {
      if (value[0] === key) {
        result[`${path}.${key}`] = value[1];
      } else {
        // The key is missing, we can check if the current value is an object
        // in order to find the nested values
        if (typeof value[1] === 'object') {
          result = {
            ...result,
            ...findAllValuesNested(
              value[1],
              key,
              `${path ? path + '.' : ''}${value[0]}`
            ),
          };
        }
      }
    }
    return result;
  };

  const { formData } = useRJSFormContext();

  // Find the nested requirements
  const myKey = useMemo(() => props.name, [props.name]);

  const valueRequirements = useMemo(() => {
    if (!formData) {
      return {};
    }

    let myProperties = allProperties[myKey];

    // Find the nested requirements, in this case, are the siblings
    const parentPath = myProperties.parent;

    return findValueInPath(formData, parentPath);
  }, [formData, allProperties]);

  const childrenRequirements = useMemo(() => {
    if (!valueRequirements) {
      return [];
    }

    // Remove myself from the list
    const children = Object.entries(valueRequirements).filter(
      (value) => value[0] !== myKey
    );

    // Get the nested requirements values
    const allNestedRequirements = Object.values(
      findAllValuesNested(children, 'quality_check')
    );

    return allNestedRequirements ?? [];
  }, [valueRequirements]);

  useEffect(() => {
    // Here we have the actual intelligence to check the quality of the data
    // based on the nested requirements
    // If everything is QualityCheck.FullyMeet, then the quality check is QualityCheck.FullyMeet, ignoring QualityCheck.NA
    // If there is at least one QualityCheck.NotMeet, then the quality check is QualityCheck.NotMeet
    // If all is QualityCheck.NA, then the quality check is QualityCheck.NA

    const noNA = childrenRequirements?.filter((v) => v !== QualityCheck.NA);
    const isAllFullyMeet = noNA?.every((v) => v === QualityCheck.FullyMeet);
    const isAllNA = childrenRequirements?.every((v) => v === QualityCheck.NA);
    const isNotMeet = childrenRequirements?.some(
      (v) => v === QualityCheck.NotMeet
    );
    if (childrenRequirements.length > 0) {
      if (isAllFullyMeet) {
        props.onChange({
          quality_check: QualityCheck.FullyMeet,
        });
        return;
      }

      if (isNotMeet) {
        props.onChange({
          quality_check: QualityCheck.NotMeet,
        });
        return;
      }

      if (isAllNA) {
        props.onChange({
          quality_check: QualityCheck.NA,
        });
        return;
      }
    }
  }, [JSON.stringify(childrenRequirements)]);

  const qualityCheckValue = useMemo(() => {
    return props.formData?.quality_check ?? '';
  }, [props.formData?.quality_check]);

  const qualityCheckProperties = useMemo(() => {
    if (props.schema.properties?.data_provider_input) {
      return props.schema.properties?.data_provider_input as {
        [key: string]: any;
      };
    }
    return undefined;
  }, [props.schema]);

  if (props.isHidden) {
    return null;
  }

  return (
    <Group placement='start' className='quality-check-field'>
      <FormControl
        label={qualityCheckProperties?.title ?? ''}
        description={
          <DescriptionRenderer>
            {qualityCheckProperties?.description ?? ``}
          </DescriptionRenderer>
        }
      >
        <QualityCheckSelect
          qualityCheck={qualityCheckValue}
          editable={false}
          onQualityCheckChange={() => {}}
        />
      </FormControl>
    </Group>
  );
};

export const HiddenAutoQualityCheckField: Field = (props: FieldProps) => {
  return <AutoQualityCheckField {...props} isHidden={true} />;
};
