import React from 'react';

import { SortInfo } from 'components';
import { SearchTokenOperatorMap, SearchTokenResult, tokensToApiFilter, } from 'components/token-search';
import { PageQuery } from 'domain/models';
import { useInputState } from './useInputState';

const sortToObject = (sort?: string): SortInfo => {
  if (sort) {
    const [field, dir] = sort.split('|');
    if (field && dir) {
      return {
        sortField: field,
        sortDir: dir as any,
      };
    }
  }

  return {
    sortField: '',
    sortDir: 'ASC',
  };
};

type UsePaginationRT = {
  page: PageQuery;
  sortInfo: SortInfo;
  q: string;
  filters: SearchTokenResult[];
  queryFilters: string[];
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setSort: (sort: SortInfo) => void;
  setQ: (value: any) => void;
  setFilters: (filters: SearchTokenResult[]) => void;
};

const mapFilter = (filter: SearchTokenResult): string | undefined => {
  if (!filter.operator || !filter.code || !filter.value) {
    return undefined;
  }
  const op = filter.operator ? SearchTokenOperatorMap[filter.operator] : '';
  return `[${op}][${filter.code}]${filter.value?.value}`;
};
export const useFilteredPage = (
  page: PageQuery
): [PageQuery, any, VoidFunction] => {
  const [localFilter, setLocalFilter] = React.useState<SearchTokenResult[]>([]);
  const [localPage, setLocalPage] = React.useState<
    PageQuery & { filters?: string[] }
  >({ ...page });
  React.useEffect(() => {
    // Add filter=[operator][code]value.value
    const as_filters: string[] = localFilter
      .map((f) => mapFilter(f))
      .filter((f) => f !== undefined) as string[];
    if (as_filters.length > 0) {
      setLocalPage({
        ...page,
        ...localPage,
        filters: as_filters,
      });
    } else {
      setLocalPage({
        ...page,
        ...localPage,
        filters: undefined,
      });
    }
  }, [page, localFilter]);

  const downloadExcel = () => { };

  return [localPage, setLocalFilter, downloadExcel];
};

export const usePagination = (
  initialPage: PageQuery = { page: 0, size: 10 },
  initialQ: string = '',
  defaultSeparator = '|'
): UsePaginationRT => {
  const [page, _setPage] = React.useState<PageQuery>(initialPage);
  const [sortInfo, setSortInfo] = React.useState<SortInfo>(
    sortToObject(initialPage.sort)
  );
  const [filters, setFilters] = React.useState<SearchTokenResult[]>([]);

  const [q, setQ] = useInputState(initialQ);

  const setPage = React.useCallback(
    (page: number) => _setPage((p) => ({ ...p, page })),
    [_setPage]
  );
  const setSize = React.useCallback(
    (size: number) => _setPage((p) => ({ ...p, size })),
    [_setPage]
  );
  const setSort = React.useCallback(
    (sort: SortInfo) => {
      setSortInfo(sort);
      _setPage((p) => ({ ...p, sort: `${sort.sortField}${defaultSeparator}${sort.sortDir}` }));
    },
    [_setPage, setSortInfo]
  );

  const queryFilters = React.useMemo(
    () => tokensToApiFilter(filters),
    [filters]
  );

  return {
    page,
    sortInfo,
    q,
    filters,
    queryFilters,
    setPage,
    setSize,
    setSort,
    setQ,
    setFilters,
  };
};
