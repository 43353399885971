import { useTask } from "domain/swr";
import { useEffect, useState } from "react";

/**
 * Show task information for user troubleshooting
 */
export const TaskDebugInfo = () => {
  const taskId = useTaskId();

  const { task } = useTask(taskId!);

  // We need the id and the execution id
  if (!task) {
    return null;
  }

  return (
    <div className="task-debug-info w-full text-xs py-4">
      <p className="pb-2">
        <span className="font-bold">
          Having trouble with this task? Share this information with the support
          team.
        </span>
      </p>
      <div className="select-all w-full">
        <p>
          <span className="font-bold">Task:</span>{" "}
          <span className="font-mono">{task.id}</span>
        </p>
        <p>
          <span className="font-bold">Workflow:</span>{" "}
          <span className="font-mono">{task.process_id}</span>
        </p>
      </div>
    </div>
  );
};

const useTaskId = () => {
  const [taskId, setTaskId] = useState<string | null>(null);

  useEffect(() => {
    // Find task id from the URL
    // May start by ../task/{id} and can end with another /schema or no,
    // so we need to remove the last part of the URL
    const url = window.location.href;
    // Sanity check
    if (!url.includes("task/")) {
      return;
    }

    const urlParts = url.split("/");
    const taskIdIndex = urlParts.indexOf("task") + 1;
    const taskId = urlParts[taskIdIndex] || null;
    if (!taskId) {
      return;
    }

    setTaskId(taskId);
  }, []);

  return taskId;
};
