import React, { useEffect } from 'react';

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertCodeBlock,
  InsertImage,
  InsertTable,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  Separator,
  UndoRedo,
  codeBlockPlugin,
  codeMirrorPlugin,
  diffSourcePlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  toolbarPlugin,
  maxLengthPlugin,
  DirectiveDescriptor,
  directivesPlugin,
  useCellValue,
  markdown$,
  Button,
  setMarkdown$,
  usePublisher,
  rootEditor$,
  thematicBreakPlugin,
} from '@mdxeditor/editor';
import _debounce from 'lodash/debounce';

import { Tooltip } from 'components/Tooltip';
import { Group } from 'components/Flex';

import { useSpellChecker } from './useSpellCheck';
import { useTaskSchemaContext } from 'pages/app/task/schema/TaskSchema';
import { useCaseAttachmentUploader } from 'domain/swr';
import { QAUseCaseAttachment } from 'domain/models';
import { useDispatch } from 'react-redux';
import { pushNotification } from 'domain/store/root';
import { TextUtils } from 'domain/utils/TextUtils.utils';

import './MDEditor2.scss';
import '@mdxeditor/editor/style.css';
import cn from 'classnames';

const createDirectiveText = (word: string, suggestions: string[]) =>
  `:spellError[${word}]{suggestions="${suggestions.concat(word).join(',')}"}`;

/**
 * For updating the directives, we have to check if there is already a directive in the markdown,
 * because duplicating it will render the editor unusable.
 * @param markdown
 * @param error
 */
const updateDirectives = (markdown: string, error: any) => {
  const directive = createDirectiveText(error.word, error.suggestions);

  // First, remove the directives
  markdown = markdown.replaceAll(directive, error.word);
  return markdown.replaceAll(error.word, directive);
};

const SpellErrorDescriptor: DirectiveDescriptor = {
  name: 'spellError',
  testNode(node) {
    return node.name === 'spellError';
  },
  attributes: ['suggestions', 'word'],
  hasChildren: true,
  Editor: (props) => {
    const suggestions = (props.mdastNode.attributes?.suggestions ?? '').split(
      ','
    );
    const suggs = suggestions?.slice(0, suggestions.length - 1);
    const word = suggestions[suggestions?.length - 1];

    const markdown = useCellValue(markdown$);
    const setMarkdown = usePublisher(setMarkdown$);

    const suggestionSelect = (suggestion: string) => {
      const toReplaceText = createDirectiveText(word, suggs);
      const newMarkdown = markdown.replaceAll(toReplaceText, suggestion);
      setMarkdown(newMarkdown);
    };

    return (
      <Tooltip
        interactive
        appendTo={document.body}
        content={
          <Group className='suggestions-tooltip-body'>
            {suggs.map((s) => (
              <span
                className='suggestions-tooltip-suggestion'
                key={s}
                onClick={() => suggestionSelect(s)}
              >
                {s}
              </span>
            ))}
          </Group>
        }
        placement='bottom'
      >
        <span className='spell-error'>{word}</span>
      </Tooltip>
    );
  },
  type: 'textDirective',
};

const useSpellCheck = () => {
  const spellChecker = useSpellChecker();

  const doSpellCheck = (
    markdown: string,
    setMarkdown: (markdown: string) => void
  ) => {
    const rawText = TextUtils.onlyText(markdown);
    const errors = spellChecker.checkFullText(rawText);
    let newMarkdown = markdown;
    for (const error of errors) {
      newMarkdown = updateDirectives(newMarkdown, error);
    }
    setMarkdown(newMarkdown);
  };

  return doSpellCheck;
};

const SpellCheckTool = () => {
  const doSpellCheck = useSpellCheck();

  const markdown = useCellValue(markdown$);
  const setMarkdown = usePublisher(setMarkdown$);
  const spellChecking = React.useRef(false);

  const internalSpellCheck = React.useCallback(
    (content: string) => {
      if (spellChecking.current) {
        return;
      }

      spellChecking.current = true;
      doSpellCheck(content, setMarkdown);

      // If the selection is not null, we have to restore it
      setTimeout(() => {
        spellChecking.current = false;
      }, 2000);
    },
    [doSpellCheck, setMarkdown]
  );

  const detachedSpellCheck = React.useCallback(
    _debounce(internalSpellCheck, 1000),
    []
  );

  // Use effect on markdown
  /*
  React.useEffect(() => {
    detachedSpellCheck(markdown);
  }, [markdown]);
  */
  return (
    <Button onClick={() => internalSpellCheck(markdown)}>Spell check</Button>
  );
};

interface MDEditor2Props {
  className?: string;
  data: string;
  maxLength?: number;
  onChange(content: string): void;
  readOnly?: boolean;
  spellCheck?: boolean;
}

const getTextMaxLength = (maxLength?: number) => {
  return maxLength && maxLength != -1 ? maxLength : undefined;
};

export const MDEditor2 = React.forwardRef(
  (
    {
      data,
      onChange,
      className = '',
      maxLength,
      readOnly,
      spellCheck = true,
    }: MDEditor2Props,
    ref: any
  ) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    const editor = React.useRef<MDXEditorMethods>(null);
    const { task } = useTaskSchemaContext();

    const qar: number = React.useMemo(() => {
      return task?.variables.qar || 0;
    }, [task]);

    const attachmentUploader = useCaseAttachmentUploader(qar);

    const uploadAttachment = React.useMemo(() => {
      return async (files: File) => {
        try {
          setLoading(true);
          const newAttachments: QAUseCaseAttachment = await attachmentUploader(
            files
          );
          // Add attachment to the editor, in markdown format
          // const newText = `${sanitized} ![${newAttachments[0].name}](${newAttachments[0].url})`;

          // onChange(newText);
          return newAttachments.url ?? files.webkitRelativePath;
        } catch (err) {
          dispatch(
            pushNotification({
              variant: 'error',
              title: 'Error',
              message: 'An error occurred while trying to load the file.',
            })
          );
        } finally {
          setLoading(false);
          return files.webkitRelativePath;
        }
      };
    }, []);

    const showErrorMessage = (message: string) => {
      dispatch(
        pushNotification({
          variant: 'error',
          title: 'Error',
          message,
        })
      );
    };

    const [forceResetKey, setForceResetKey] = React.useState(0);
    const filteredOnChange = React.useCallback(
      (data: string) => {
        // Only latin characters
        console.log('data', data);
        const filtered = TextUtils.onlyLatin(data);

        if (filtered !== data) {
          //setForceResetKey((prev) => prev + 1);
          showErrorMessage('You have pasted forbidden characters');
          //return;
        }
        onChange(filtered);
      },
      [onChange]
    );

    return (
      <div className='md-editor-wrapper min-h-[100px] bg-white border border-gray-300 rounded-md shadow-sm relative'>
        <MDXEditor
          key={forceResetKey}
          markdown={data}
          onChange={filteredOnChange}
          className={`${className} mdx-editor-element`}
          contentEditableClassName='editable-content'
          ref={editor}
          readOnly={readOnly || loading}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <DiffSourceToggleWrapper>
                  <BlockTypeSelect />
                  <Separator />
                  <BoldItalicUnderlineToggles />
                  <Separator />
                  <ListsToggle />
                  <Separator />
                  <CreateLink />
                  <Separator />
                  <UndoRedo />
                </DiffSourceToggleWrapper>
              ),
            }),
            headingsPlugin({ allowedHeadingLevels: [2] }),
            thematicBreakPlugin(),
            listsPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            imagePlugin({ imageUploadHandler: uploadAttachment }),
            tablePlugin(),
            codeBlockPlugin({ defaultCodeBlockLanguage: 'python' }),
            codeMirrorPlugin({ codeBlockLanguages: { python: 'Python' } }),
            diffSourcePlugin({ viewMode: 'rich-text' }),
            markdownShortcutPlugin(),
            maxLengthPlugin(getTextMaxLength(maxLength)),
            directivesPlugin({ directiveDescriptors: [SpellErrorDescriptor] }),
          ]}
        />
        <RemainingCharacters
          currLength={data.length}
          maxLength={getTextMaxLength(maxLength)}
        />
      </div>
    );
  }
);

const RemainingCharacters = ({
  currLength,
  maxLength,
}: {
  currLength: number;
  maxLength: number | undefined;
}) => {
  const colorText = React.useMemo(() => {
    return !maxLength || currLength <= maxLength
      ? 'text-[#4d4f58]'
      : 'text-[#941333FF]';
  }, [currLength]);

  return (
    <div className={'absolute bottom-0 right-0 p-2'}>
      <div className={cn('p-2 bg-white/80 rounded-3xl text-xs', colorText)}>
        <span>{currLength}</span>/<span>{maxLength ?? <>&infin;</>}</span>
      </div>
    </div>
  );
};
