export namespace References {
  export type Reference = {
    Identifier: string;
    Creator: Creator[];
    Title: Title[];
    Publisher: string;
    PublicationYear: string;
    ResourceType: ResourceType;
    Formatted?: string;
  };

  export type Creator = {
    creatorName: string;
    nameType?: CreatorNameType;
    givenName: string;
    familyName: string;
    nameIdentifier?: CreatorNameIdentifier[];
    affiliation: CreatorAffiliation;
  };

  export type Title = {
    value: string;
    titleType: TitleType | undefined | null;
  };

  export type ResourceType = {
    value: string;
    resourceTypeGeneral: ResourceTypeGeneral;
  };

  export type CreatorNameIdentifier = {
    nameIdentifierScheme: CreatorNameIdentifierScheme;
    schemeURI: string;
  };

  export type CreatorAffiliation = {
    affiliationIdentifier: string;
    schemeURI: string;
  };

  export enum CreatorNameType {
    Organization = 'Organization',
    Personal = 'Personal',
  }

  export enum CreatorNameIdentifierScheme {
    ORCID = 'ORCID',
    ISNI = 'ISNI',
    ROR = 'ROR',
    GRID = 'GRID',
  }

  export enum TitleType {
    AlternativeTitle = 'ALTERNATIVE_TITLE',
    Subtitle = 'SUBTITLE',
    TranslatedTitle = 'TRANSLATED_TITLE',
    Other = 'OTHER',
    None = "",
  }

  export enum ResourceTypeGeneral {
    Audiovisual = 'Audiovisual',
    Book = 'Book',
    BookChapter = 'BookChapter',
    Collection = 'Collection',
    ComputationalNotebook = 'ComputationalNotebook',
    ConferencePaper = 'ConferencePaper',
    ConferenceProceeding = 'ConferenceProceeding',
    DataPaper = 'DataPaper',
    Dataset = 'Dataset',
    Dissertation = 'Dissertation',
    Event = 'Event',
    Image = 'Image',
    InteractiveResource = 'InteractiveResource',
    Journal = 'Journal',
    JOURNALARTICLE = 'JOURNALARTICLE',
    Model = 'Model',
    OutputManagementPlan = 'OutputManagementPlan',
    PeerReview = 'PeerReview',
    PhysicalObject = 'PhysicalObject',
    Preprint = 'Preprint',
    Report = 'Report',
    Service = 'Service',
    Software = 'Software',
    Sound = 'Sound',
    Standard = 'Standard',
    Text = 'Text',
    Workflow = 'Workflow',
    Other = 'Other',
  }
}
