import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { Group } from 'components';

import { range } from 'domain/utils';

import './CommonSkeletons.scss';

export const DetailsRowSkeleton = ({ count = 1 }: { count?: number }) => {
  return (
    <Group orientation='column' placement='start' spacing='1em'>
      {range(count).map((i) => (
        <Group
          key={i}
          orientation='column'
          placement='start'
          className='details-row-skeleton'
        >
          <Skeleton width={150} height={25} />
          <Skeleton
            width='100%'
            height={25}
            containerClassName='full-width-skeleton'
          />
        </Group>
      ))}
    </Group>
  );
};
