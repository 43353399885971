import React, { useMemo, useState } from 'react';

import { FormControl, LimitedInput, Row } from 'components';
import { FieldProps } from '@rjsf/utils';
import { useNotebookData } from 'domain/swr/useNotebook';

import './JupyterNotebookUrl.scss';
import { FieldErrors } from './BasicFields';
import { JupyterCommentRenderer } from './JupyterEditorControl';
import { Config } from 'domain/core/constants';
import { readOnlyFromProps } from './utils';
import cn from 'classnames';

export const JupyterNotebookUrl = (props: FieldProps) => {
  const key = 'jupyter_notebook_url';
  const value = useMemo(() => props?.formData?.[key] ?? '', [props?.formData]);

  const [invalidUrl, setInvalidUrl] = useState(false);

  // Detach
  const { notebook } = useNotebookData(value, 0, 100);

  const doChange = (evt: any) => {
    // Check the URL is valid, that is:
    // - Ends with .ipynb
    // - Contains /blob/
    // - What comes after /blob/ is a valid SHA-1 hash, not a branch name (we want a permalink to a specific version)
    // - The URL is a valid URL
    // First check the URL is valid
    // Ej: https://github.com/blob/da39a3ee5e6b4b0d3255bfef95601890afd80709/test.ipynb
    const url = evt.target.value;

    const isValidGitHubUrl = (url: string) => {
      try {
        const parsedUrl = new URL(url);
        return (
          parsedUrl.hostname === 'github.com' &&
          parsedUrl.pathname.includes('/blob/') &&
          parsedUrl.pathname.endsWith('.ipynb') &&
          /^[a-f0-9]{40}$/i.test(getHashFromUrl(parsedUrl.pathname))
        );
      } catch (error) {
        return false;
      }
    };

    const getHashFromUrl = (pathname: string) => {
      const urlParts = pathname.split('/');
      const blobIndex = urlParts.findIndex((part) => part === 'blob');
      return urlParts[blobIndex + 1];
    };

    const isValid = isValidGitHubUrl(url);

    setInvalidUrl(!isValid);

    props.onChange({
      ...props.formData,
      // [key]: {
      jupyter_notebook_url: evt.target.value,
      //},
    });
  };

  const isNotebookAvailable = useMemo(() => {
    return notebook && Object.keys(notebook as any).length > 0;
  }, [notebook]);

  const disabled = readOnlyFromProps(props);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Row className='jupyter-notebook-url-field'>
      <FormControl
        label={`Jupyter Notebook URL`}
        description='Enter a valid permalink to an Jupyter Notebook.'
      >
        <LimitedInput
          maxLength={Config.MAX_WORDS_TEXTAREA}
          name={props.title}
          className='rjsf-input'
          value={value}
          type={props?.schema?.format}
          disabled={disabled}
          onChange={doChange}
        />
        <FieldErrors
          errors={
            invalidUrl
              ? [
                  'Invalid GitHub URL. The URL must be a permalink to a Jupyter Notebook.',
                ]
              : []
          }
        />
      </FormControl>

      {isNotebookAvailable && (
        <FormControl
          label={`Jupyter Notebook Review`}
          description='Here is where you can review the Jupyter Notebook.'
        >
          <div className={cn(isExpanded ? '' : 'max-h-[100px] overflow-hidden', 'max-w-[100%] w-full overflow-hidden')}>
            {/* Expand / collapse */}
            <a
              href='#'
              className='text-sm text-blue-500'
              onClick={e => {
                e.preventDefault();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </a>
            <JupyterCommentRenderer data={notebook || {}} comments={[]} />
          </div>
        </FormControl>
      )}
    </Row>
  );
};
