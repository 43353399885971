import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';

import { FormControl, Group, Text } from 'components';

import { StringField } from './BasicFields';
import { ReviewComment } from './ReviewComment';

import './FixReviewControl.scss';
import { CatalogProvidedInfo } from './CatalogProvidedControl';
import { QualityCheck } from 'domain/models';

export const FixReviewControl: Field = (props: FieldProps) => {
  const [qualityCheck, setQualityCheck] = React.useState<
    QualityCheck | undefined
  >(props.formData?.quality_check);

  const uiReviewField = props.uiSchema!['ui:reviewField'];
  const Cmp = props.registry.fields[uiReviewField] ?? StringField;
  const fieldSchema: any = props.schema.properties?.data_provider_input;
  const fieldUi = props.uiSchema?.data_provider_input ?? {};
  const uiFieldSchema = {
    ...fieldUi,
    'ui:field': uiReviewField,
  };
  const p = {
    ...props,
    formData: props.formData?.data_provider_input,
    schema: fieldSchema,
    uiSchema: uiFieldSchema,
    onChange: (val: any) =>
      props.onChange({ ...props.formData, data_provider_input: val }),
  } as FieldProps;

  const onQualityCheckChange = (qc: QualityCheck) => {
    props.onChange({
      ...props.formData,
      quality_check: qc,
    });
    setQualityCheck(qc);
  };

  return (
    <Group
      className='rjsf-fix-review-field'
      orientation='column'
      placement='start'
    >
      <FormControl
        label={fieldSchema?.title ?? ''}
        description={fieldSchema?.description}
      >
        <CatalogProvidedInfo
          qualityCheck={qualityCheck}
          editable
          onQualityCheckChange={onQualityCheckChange}
          input={props.formData.catalogue_provided_input ?? '-'}
        />
        <Cmp {...p} />
        <ReviewComment score={props.formData.color_score} />
      </FormControl>
    </Group>
  );
};
