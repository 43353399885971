/**
 * Hook to find references
 */

import { Api } from 'domain/core';
import { References } from 'domain/models';
import React from 'react';
import { fetcher } from './fetcher';

export const useSearchReferences = () => {
  const [data, setData] = React.useState<References.Reference | undefined>();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchFn = React.useCallback((reference: string) => {
    if (reference) {
      const url = `${Api.baseUrl}${Api.references.find}?q=${reference}`;
      setLoading(true);
      fetcher(url)
        .then((data) => {
          setData(data);
          return data;
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const parseFn = React.useCallback((reference: References.Reference) => {
    if (reference) {
      const url = `${Api.baseUrl}${Api.references.find}`;
      
      return fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reference),
      })
        .then((data) => {
          return data as References.Reference | undefined;
        })
        .catch(() => {
          return undefined;
        });
    }
  }, []);

  return {
    data: data as References.Reference | undefined,
    parseFn,
    isLoading: loading,
    isError: error,
    fetchFn,
  };
};
