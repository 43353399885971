import React from 'react';

import { Field, FieldProps } from '@rjsf/utils';
import AsyncSelect from 'react-select/async';
import { X } from 'tabler-icons-react';

import { Group, Text } from 'components';

import { useSelectOnChange } from './utils';
import { inOptions, SelectOption } from 'domain/utils';
import { useCatalogueEntryFetcher } from 'domain/swr';

import './CatalogueEntryControl.scss';

export const CatalogueEntryControl: Field = (props: FieldProps) => {
  const [options, setOptions] = React.useState<SelectOption<any>[]>([]);

  const catalogueEntryFetcher = useCatalogueEntryFetcher();

  const mergeOptions = (
    currOptions: SelectOption<any>[],
    newOptions: SelectOption<any>[]
  ): SelectOption<any>[] => {
    let opts = currOptions.slice();
    for (let newOpt of newOptions) {
      if (!opts.find((o) => o.value === newOpt.value)) {
        opts.push(newOpt);
      }
    }

    return opts;
  };

  const disabled = React.useMemo(
    () => props.uiSchema!['ui:readOnly'] ?? props.disabled,
    [props]
  );

  const onChange = useSelectOnChange(props);

  const selectedOptions = inOptions(
    options,
    props.formData,
    undefined,
    props.uiSchema!.multiple
  ) as SelectOption<any>[];

  const loadOptions = (inputValue: string) => {
    return catalogueEntryFetcher(inputValue).then((opts) => {
      setOptions(mergeOptions(options, opts));
      return opts;
    });
  };

  const deleteOption = (opt: SelectOption<any>) => {
    onChange(opt, {
      action: 'deselect-option',
      option: opt,
    });
  };

  return (
    <Group orientation='column' placement='start'>
      <AsyncSelect
        className='select rjsf-select catalogue-entry-control'
        classNamePrefix={'cop-select'}
        cacheOptions
        defaultOptions
        isDisabled={disabled}
        isMulti={props.uiSchema!.multiple}
        loadOptions={loadOptions}
        value={selectedOptions}
        onChange={onChange}
      />
      <Group
        orientation='column'
        placement='start'
        spacing='8px'
        className='selected-catalogue-entry-list'
      >
        {selectedOptions?.map(({ value, label }) => {
          return (
            <Group
              placement='start'
              key={value}
              spacing={'8px'}
              className='selected-catalogue-entry'
            >
              {!disabled && (
                <X
                  className='delete-icon'
                  size={14}
                  onClick={() => deleteOption({ value, label })}
                />
              )}
              <Text className='catalogue-entry-label'>{label}</Text>
            </Group>
          );
        })}
      </Group>
    </Group>
  );
};
