import { merge } from 'domain/utils/json.utils';
import { diff, flattenChangeset, IFlatChange } from 'json-diff-ts';
import React from 'react';
import {
  ImageFormDataI,
  ImageFromSchemaI,
} from '../../../domain/models/rjsf-fields.model';
import { RjsfCustomField } from '../rjsf';

type GetCollectionArgs = {
  formData: any;
  schema: any;
  uiSchema: any;
};

type GetDiffCollectionArgs = {
  sourceFormData: any;
  sourceSchema: any;
  sourceUiSchema: any;
  newFormData: any;
  newSchema: any;
  newUiSchema: any;
};

export type DataCollectionEntry = {
  schemaPath: string;
  path: string;
  schema: any;
  uiSchema: any;
  formData: any;
};

export type DiffDataCollectionEntry = DataCollectionEntry & {
  changes?: IFlatChange[];
  newFormData: any;
};

export enum RenderType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Array = 'array',
  MD = 'markdown',
  Link = 'link',
  Reference = 'reference',
  Image = 'image',
  AssessmentImage = 'assessmentImage',
  UseCase = 'useCase',
}

export const getDataCollection = (
  props: GetCollectionArgs
): [DataCollectionEntry[], string[]] => {
  const [propsNames, groups] = traverseObject(props.formData);

  let coll: DataCollectionEntry[] = [];

  for (let path of propsNames) {
    const schemaPath = pathToSchemaPath(path);
    const schema = followPath(schemaPath, props.schema);
    const uiSchema = followPath(path, props.uiSchema);
    const formData = followPath(path, props.formData);
    coll.push({
      path,
      schemaPath,
      schema,
      uiSchema,
      formData,
    });
  }

  return [groupComplexSchemaTypes(coll), cleanGroups(groups)];
};

export const getDiffDataCollection = ({
  sourceFormData,
  sourceSchema,
  sourceUiSchema,
  newFormData,
  newSchema,
  newUiSchema,
}: GetDiffCollectionArgs): [DiffDataCollectionEntry[], string[]] => {
  const fullFormData = merge(sourceFormData, newFormData);

  const [propsNames, groups] = traverseObject(fullFormData);
  const diffResult = formDataDiff(sourceFormData, newFormData);

  let result: DiffDataCollectionEntry[] = [];

  for (let propPath of propsNames) {
    const schemaPath = pathToSchemaPath(propPath);
    const schema = followPath(schemaPath, sourceSchema);
    const uiSchema = followPath(propPath, sourceUiSchema);
    const formData = followPath(propPath, sourceFormData, false);
    const targetFormData = followPath(propPath, newFormData, false);
    const changes = getDiffEntriesForPath(propPath, diffResult);

    result.push({
      path: propPath,
      schemaPath,
      schema,
      uiSchema,
      formData,
      newFormData: targetFormData,
      changes,
    });
  }

  return [groupComplexDiffSchemaTypes(result), cleanGroups(groups)];
};

const traverseObject = (obj: object) => {
  let props: any[] = [];
  let objs: any[] = [];

  let traverse = (_obj: object, stack?: string) => {
    for (let [key, value] of Object.entries(_obj)) {
      if ('object' === typeof value && !Array.isArray(value)) {
        let newStack = stack ? `${stack}.${key}` : key;
        objs.push(newStack);
        traverse(value, newStack);
      } else {
        let k = stack ? `${stack}.${key}` : key;
        props.push(k);
      }
    }
  };

  traverse(obj);

  return [props, objs];
};

const pathToSchemaPath = (path: string) => {
  let splitted = path.split('.');
  if (splitted.length === 1) {
    return path;
  }
  let newPath: string[] = ['properties'];

  for (let i = 0; i < splitted.length; i++) {
    const p = splitted[i];

    if (i !== 0) {
      newPath.push('properties');
    }
    newPath.push(p);
  }

  return newPath.join('.');
};

const diffPathToRawPath = (diffEntry: IFlatChange) => {
  const { path, key } = diffEntry;
  let p = path.replace('$.', '');
  if (!isNaN(Number(key))) {
    p = p.replace(`[${key}]`, '');
  }
  return p;
};

export const followPath = (
  path: string,
  obj: object,
  returnPreviousIfNotFound: boolean = true
) => {
  const splitted = path.split('.');

  let value: any = obj;

  for (let prop of splitted) {
    if (value && prop in value) {
      value = value[prop];
    } else if (!returnPreviousIfNotFound) {
      return undefined;
    }
  }

  return value;
};

const getDiffEntriesForPath = (path: string, diffResult: IFlatChange[]) => {
  return diffResult.filter((r) => diffPathToRawPath(r) === path);
};

export const getLabelsForArrayField = (formData: any, schema: any) => {
  if (schema.items?.enum && schema.items?.enumNames) {
    let labels: string[] = [];

    let _formData = Array.isArray(formData) ? formData : [formData];

    for (let fd of _formData) {
      const enumIdx = schema.items.enum.findIndex((e: any) => e === fd);
      const label =
        enumIdx !== -1 ? schema.items.enumNames[enumIdx] : undefined;

      if (label) {
        labels.push(label);
      } else {
        labels.push(fd);
      }
    }

    return labels;
  }

  return formData;
};

export const getImagesWithUrl = (
  formData: any,
  schema: any
): ImageFormDataI[] => {
  if (typeof formData === 'string') {
    const item: ImageFormDataI = schema.items['oneOf'].find(
      (image: ImageFromSchemaI) => formData === image.const
    );
    return [
      {
        id: formData,
        title: item.title,
        url: item.url,
      },
    ];
  }
  return formData.map((e: string) => {
    const item: ImageFormDataI = schema.items['anyOf'].find(
      (image: ImageFromSchemaI) => e === image.const
    );
    return {
      id: e,
      title: item.title,
      url: item.url,
    };
  });
};

const formDataDiff = (formData1: any, formData2: any) => {
  return flattenChangeset(diff(formData1, formData2));
};

export const mergeArrayFormData = (formDataA: any, formDataB: any) => {
  let concated = formDataA.concat(formDataB);

  let uniq: any[] = [];

  for (let entry of concated) {
    if (!uniq.includes(entry)) {
      uniq.push(entry);
    }
  }

  return uniq;
};

const cleanGroups = (groups: string[]): string[] => {
  return groups.filter((g) => {
    const coincidence = groups.find((gg) => gg !== g && g.startsWith(gg));
    if (coincidence) {
      return false;
    }
    return true;
  });
};

const groupComplexSchemaTypes = (fields: DataCollectionEntry[]) => {
  let result: DataCollectionEntry[] = [];
  let ignorePaths: string[] = [];

  for (let field of fields) {
    if (ignorePaths.includes(field.path)) {
      continue;
    }
    const uiField = getUiField(field.uiSchema);

    switch (uiField) {
      case RjsfCustomField.ReviewField:
      case RjsfCustomField.FixReviewField:
      case RjsfCustomField.CatalogueProvided: {
        const [_field, _ignorePaths] = getFixReviewFields(field, fields);
        result.push(_field);
        ignorePaths = ignorePaths.concat(_ignorePaths);
        break;
      }
      default:
        result.push(field);
    }
  }

  return result;
};

const groupComplexDiffSchemaTypes = (fields: DiffDataCollectionEntry[]) => {
  let result: DiffDataCollectionEntry[] = [];
  let ignorePaths: string[] = [];

  for (let field of fields) {
    if (ignorePaths.includes(field.path)) {
      continue;
    }

    const uiField = getUiField(field.uiSchema);

    switch (uiField) {
      case RjsfCustomField.ReviewField:
      case RjsfCustomField.FixReviewField:
      case RjsfCustomField.CatalogueProvided:
        const [_field, _ignorePaths] = getDiffFixReviewFields(field, fields);
        result.push(_field);
        ignorePaths = ignorePaths.concat(_ignorePaths);
        break;
      default:
        result.push(field);
    }
  }

  return result;
};

const getFixReviewFields = (
  field: DataCollectionEntry,
  fields: DataCollectionEntry[]
): [DataCollectionEntry, string[]] => {
  const currPath = field.schemaPath.split('.').at(-1) as string;
  const restPath = field.schemaPath.replace(currPath, '');

  let newFixReviewField: DataCollectionEntry = {
    formData: {
      [currPath]: field.formData,
    },
    schema: {
      [currPath]: field.schema,
    },
    uiSchema: field.uiSchema,
    path: field.path.replace(currPath, ''),
    schemaPath: restPath,
  };

  let ignorePaths: string[] = [];

  const setData = (
    f: DataCollectionEntry,
    path: string,
    target: DataCollectionEntry
  ) => {
    return {
      ...target,
      formData: {
        ...target.formData,
        [path]: f.formData,
      },
      schema: {
        ...target.schema,
        [path]: f.schema,
      },
    };
  };

  for (const f of fields) {
    if (f.schemaPath.includes(restPath)) {
      const fCurrPath = f.schemaPath.split('.').at(-1) as string;
      newFixReviewField = setData(f, fCurrPath, newFixReviewField);

      ignorePaths.push(f.path);
    }
  }

  return [newFixReviewField, ignorePaths];
};

const getDiffFixReviewFields = (
  field: DiffDataCollectionEntry,
  fields: DiffDataCollectionEntry[]
): [DiffDataCollectionEntry, string[]] => {
  const currPath = field.schemaPath.split('.').at(-1) as string;
  const restPath = field.schemaPath.replace(currPath, '');

  let newFixReviewField: DiffDataCollectionEntry = {
    formData: {
      [currPath]: field.formData,
    },
    schema: {
      [currPath]: field.schema,
    },
    uiSchema: field.uiSchema,
    path: field.path.replace(currPath, ''),
    schemaPath: restPath,
    changes: field.changes ?? [],
    newFormData: {
      [currPath]: field.newFormData,
    },
  };

  let ignorePaths: string[] = [];

  const setData = (
    f: DiffDataCollectionEntry,
    path: string,
    target: DiffDataCollectionEntry
  ) => {
    return {
      ...target,
      formData: {
        ...target.formData,
        [path]: f.formData,
      },
      schema: {
        ...target.schema,
        [path]: f.schema,
      },
      changes: target.changes?.concat(f.changes ?? []),
      newFormData: {
        ...target.newFormData,
        [path]: f.newFormData,
      },
    };
  };

  for (const f of fields) {
    if (f.schemaPath.includes(restPath)) {
      const fCurrPath = f.schemaPath.split('.').at(-1) as string;
      newFixReviewField = setData(f, fCurrPath, newFixReviewField);

      ignorePaths.push(f.path);
    }
  }

  return [newFixReviewField, ignorePaths];
};

export const getConcreteRenderType = (schema: any, uiSchema: any) => {
  const schemaType = schema.type;
  const subType = schema.items?.type;
  const uiSchemaField = (uiSchema ?? {})['ui:field'];
  const uiSchemaReviewField = (uiSchema ?? {})['ui:reviewField'];

  let schemaRenderType, uiSchemaRenderType, uiSchemaReviewRenderType;

  if (schemaType === 'string' && !subType) {
    schemaRenderType = RenderType.String;
  } else if (schemaType === 'number') {
    schemaRenderType = RenderType.Number;
  } else if (schemaType === 'boolean') {
    schemaRenderType = RenderType.Boolean;
  } else if (schemaType === 'array' || schemaType === 'string') {
    if (subType === 'string' || subType === 'number') {
      schemaRenderType = RenderType.Array;
    }
  }

  if (uiSchemaField) {
    if (
      uiSchemaField === RjsfCustomField.Select ||
      uiSchemaField === RjsfCustomField.CreatableSelect
    ) {
      uiSchemaRenderType = RenderType.Array;
    } else if (uiSchemaField === RjsfCustomField.CatalogueEntry) {
      uiSchemaRenderType = RenderType.Array;
    } else if (uiSchemaField === RjsfCustomField.DatePicker) {
      uiSchemaRenderType = RenderType.String;
    } else if (uiSchemaField === RjsfCustomField.MDEditor) {
      uiSchemaRenderType = RenderType.MD;
    } else if (uiSchemaField === RjsfCustomField.ImageField) {
      uiSchemaRenderType = RenderType.Image;
    } else if (uiSchemaField === RjsfCustomField.AssessmentImageField) {
      uiSchemaRenderType = RenderType.AssessmentImage;
    } else if (uiSchemaField === RjsfCustomField.AssessmentUseCasesField) {
      uiSchemaRenderType = RenderType.UseCase;
    }

    if (
      uiSchemaField === RjsfCustomField.ReviewField ||
      uiSchemaField === RjsfCustomField.FixReviewField
    ) {
      if (uiSchemaReviewField === RjsfCustomField.MDEditor) {
        uiSchemaReviewRenderType = RenderType.MD;
      } else if (uiSchemaReviewField === RjsfCustomField.Select) {
        uiSchemaReviewRenderType = RenderType.Array;
      }
    }
  }

  return (
    uiSchemaReviewRenderType ??
    uiSchemaRenderType ??
    schemaRenderType ??
    RenderType.String
  );
};

export const useConcreteRenderType = (
  schema: any,
  uiSchema: any
): RenderType => {
  return React.useMemo(
    () => getConcreteRenderType(schema, uiSchema),
    [schema, uiSchema]
  );
};

export const getUiField = (uiSchema?: any): string | undefined => {
  return (uiSchema ?? {})['ui:field'];
};
